// libraries
import styled from "styled-components";
import { flexSmart } from "@caps-mobile/styled-lib";
import { whenXLargeMax, whenLargeMax, whenMediumMax, whenSmallMax, whenXSmallMax } from "~/theme";
import { algo_green_text } from "~/constants";

export const StyledApiObjectDetails = styled.div<{showChevron?: boolean}>`
    height: 100%;
    width: calc(100% - ${props => props.showChevron ? 100 : 0}px);

    ${flexSmart("flex-start space-between")}
    flex-direction: column;

    & > :not(:last-child){
        padding-bottom: 10px;
        box-sizing: border-box;
    }
`;

export const Header = styled.div<{extraStyling?: boolean, lawEnforcement?: boolean, isSmall?: boolean}>`
    width: 100%;

    ${flexSmart("flex-start center")}

    font-weight: 600;
    font-size: 26px;
    line-height: 36px;

    ${props => props.lawEnforcement && !props.isSmall ? 
        `
        padding-left: 148px;
        width: calc(100% - 148px);
        padding-bottom: 10px;
        `
        : props.isSmall ?
        `
        text-align: center;
        padding-bottom: 10px;
        `
        :
        ``
    }

    ${props => !props.extraStyling ? 
        `${whenLargeMax(`
            font-size: 20px;
            line-height: 30px;
        `)}` 
        :
        `${whenXLargeMax(`
            height: 72px;
        `)}

        ${whenLargeMax(`
            font-size: 20px;
            line-height: 30px;
        `)}

        ${whenMediumMax(`
            height: revert;
        `)}`
    }
`;

export const TrafficEvent = styled.div`
    background-color: #FCD116;
    border-radius: 10px;
    padding: 10px;
`;

export const TrafficEventText = styled.div`
    font-weight: 600;
    font-size: 17px;
`;

export const TravelTime = styled.div`
    width: 100%;
    ${flexSmart("flex-start center")}
`;

export const TravelTimeDetails = styled.div`
    float: left;
    width: inherit;
`;

export const TravelIconSection = styled.div`
    width: 100%;
`;

export const TravelIcon = styled.div`
    float: right;
`;

export const Detail = styled.div<{extraStyling?: boolean, travel?: boolean, color?: string}>`
    width: 100%;

    ${flexSmart("flex-start center")}

    font-weight: ${props => props.travel ? 700 : 400};
    font-size: 15px;
    line-height: 20px;

    ${props => props.travel ? `padding-top: 10px;` : ``};

    ${props => props.color ? `color: ${props.color}` : ``}
    
    ${props => !props.extraStyling ? '' :
        `${whenLargeMax(`
            height: 60px;
        `)}

        ${whenMediumMax(`
            height: revert;
        `)}`
    }
`;

export const Footer = styled.div<{justifyContent?: string}>`

    width: 100%;

    display: flex;
    justify-content: ${props => props.justifyContent ? props.justifyContent : "flex-end"};
    align-items: center;

    font-weight: 400;
    font-size: 12px;
    line-height: 133%;

    padding-top: 10px;
`;