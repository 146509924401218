// libraries
import * as React from "react";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { getById } from "~/store/algo-api/slices/weather-alerts";
// styles
import * as SC from "./Styled";
// types & models
import { ICWAlertDto, ICWAreaDto } from "@algo/network-manager/models/v3/cw";
// components
import EventProgress from "../../../event-progress/EventProgress";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
// constants

export type IProps = {
    object: any;
};

export const WeatherAlertDetails: React.FC<IProps> = (props) => {

    const {object} = props;

    const dispatch = useDispatch();

    const isFeature: boolean = object.properties 
        ? true 
        : false; 

    const ids: string[] = isFeature
        ? object.properties?.alerts || []
        : [];

    const alertsStore: any = useSelector( (store: any) => store["weather-alerts"]);
    const alerts: ICWAlertDto[] = isFeature 
        ?   ids.map( (id: string) => {
                if (alertsStore[id]) return alertsStore[id].alert;
                else return null;
            })
        : [object];

    React.useEffect(
        () => {

            // if it is a feature, get all related alerts
            if (isFeature && ids && ids.length > 0){

                for(let i = 0; i < ids.length; i++){
                    let nextId: string = ids[i];
                    dispatch(getById(nextId) as any);                  
                }
            }

            // else get the alert in question
            else {
                dispatch(getById(object.id) as any);
            }

        }, []
    );

    return (
        <SC.StyledWeatherAlertDetails>
            <SC.ScrollFadeWrap>
                {   alerts.map(
                        (alert: ICWAlertDto, mapIndex: number) => {

                            if (!alert) return null;

                            return (
                                <SC.StyledAlert key={Math.random()}>

                                    <SC.AlertTitleRow key={alert.id}>
                                        <FontAwesomeIcon icon={solid("exclamation-triangle")} color={`#${alert.fillColor?.hex}`}/>
                                        <SC.AlertName>{alert.name}</SC.AlertName>
                                    </SC.AlertTitleRow>

                                    <EventProgress 
                                        key={`${alert.id}${mapIndex}`} color={`#${alert.fillColor?.hex}` || "rgba(196, 18, 46, 1)"} 
                                        start={alert.effective} end={alert.expiration} 
                                    />

                                    <SC.AreasHeader>{`Affected Areas: `}</SC.AreasHeader>

                                    {alert.affectedAreas?.map(
                                        (area: ICWAreaDto) => {
                                            return <SC.AreasText key={`${area.id}`}>{`${area.description}; `}</SC.AreasText>
                                        })
                                    }

                                    <SC.Description>{alert.description}</SC.Description>

                                </SC.StyledAlert>
                            )
                        }
                    )
                }
            </SC.ScrollFadeWrap>
        </SC.StyledWeatherAlertDetails>
    )
};

export default WeatherAlertDetails;