// libraries
import * as React from "react";
// styles
import * as SC from "./Styled";

export type IProps = {
    progress?: string;
    color?: string;
    bgColor?: string;
    height?: string;
};

export const ProgressBar: React.FC<IProps> = (props) => {

    return (
        <SC.StyledProgressBar height={props.height} progress={"100"} color={props.bgColor || "rgba(211, 211, 211, 1)"}>
            <SC.StyledProgressBar height={props.height} progress={props.progress} color={props.color} />
        </SC.StyledProgressBar>
    )
};

export default ProgressBar;