// libraries
import * as React from "react";
// hooks & context
import DetailModalContext from "~/contexts/DetailModalContext";
import { SiteNavContext } from "~/navigation/SiteNavContext";
import VideoboardModalContext from "~/contexts/VideoboardModalContext";
// styles
import * as SC from "./Styled";
// components
import FullPageModal from "../views/modal/FullPageModal";
import NavPanel from "~/navigation/nav-panel/NavPanel";
import RoutesWrapper from "~/navigation/routes-wrapper/RoutesWrapper";
import NavBar from "~/navigation/nav-bar/NavBar";
import { useBreakpoint } from "../../library/useBreakpoint";
import { useAuth } from "react-oidc-context";


export type IProps = {
    //
};

export const AppContent: React.FC<IProps> = (props) => {

    const detailModal: any = React.useContext(DetailModalContext);
    const videoboardModal: any = React.useContext(VideoboardModalContext);
    const navContext: any = React.useContext(SiteNavContext);

    //check if there is an access token and if so send message to service worker
    const serviceWorker = navigator?.serviceWorker?.controller;
    const auth = useAuth();
    if(auth.user?.access_token){
        serviceWorker?.postMessage({
            accessToken: auth.user?.access_token
        });
    }
    else{
        serviceWorker?.postMessage({
            accessToken: ''
        });
    }

    const { sm: isSmallMax } = useBreakpoint();

    return (

        <SC.StyledApp>
                                
            <SC.StyledNavPlusContent isSmallMax={isSmallMax}>

                {   detailModal.showModal &&
                    <FullPageModal 
                        closeCallback={() => detailModal.setShowModal(false)}
                    >
                        {detailModal.modalContent}
                    </FullPageModal>
                }

                {   videoboardModal.showModal &&
                    <FullPageModal
                        closeCallback={() => videoboardModal.setShowModal(false)}
                    >
                        {videoboardModal.modalContent}
                    </FullPageModal>
                }

                {   !isSmallMax && <NavPanel /> }

                <SC.StyledContent panelOpen={navContext.panelOpen} isSmallMax={isSmallMax}>
                    <RoutesWrapper />
                </SC.StyledContent>

                {   isSmallMax && <NavBar /> }

            </SC.StyledNavPlusContent>                    

        </SC.StyledApp>
        
    );
};

export default AppContent;
