// libraries
import { useEffect } from "react";
// hooks & contexts
import { useDispatch, useSelector } from "react-redux";
// store
import { initializeSettings, resetRefreshTimeoutId } from "./store/settings/settings";
import { initializeMapLayers  } from "./store/map-layers/map-layers";
import { getAll as getAllAldotMessages } from "~/store/algo-api/slices/aldot-messages";
import { getAll as getAllAleaAlerts } from "~/store/algo-api/slices/alea-alerts";
import { getAll as getAllCameras } from "~/store/algo-api/slices/cameras";
import { getAllGrouped as getAllCamerasGrouped } from "~/store/algo-api/slices/camera-group";
import { getAll as getAllCrashes } from "~/store/algo-api/slices/crashes";
import { getAll as getAllFerries } from "~/store/algo-api/slices/ferries";
import { getAll as getAllIncidents } from "~/store/algo-api/slices/incidents";
import { getAll as getAllMessageSigns } from "~/store/algo-api/slices/message-signs";
import { getAll as getAllRegionalEvents } from "~/store/algo-api/slices/regional-events";
import { getAll as getAllRoadConditions } from "~/store/algo-api/slices/road-conditions";
import { getAll as getAllRoadwork } from "~/store/algo-api/slices/roadwork";
import { getAll as getAllStateFacilities } from "~/store/algo-api/slices/state-facilities";
import { getAll as getAllTravelTimes } from "~/store/algo-api/slices/travel-times";
import { getAll as getAllTravelerInformationSystems } from "~/store/algo-api/slices/traveler-information-systems";
import { getAll as getAllWeatherAlertsLayer } from "~/store/algo-api/slices/weather-alerts-layer";
import { getAll as getAllWeatherAlertsActive } from "~/store/algo-api/slices/active-weather-alerts";

import { milliToMinute } from "@caps-mobile/date-time";

export const useInitializeUserSettings = () => {

    const dispatch = useDispatch();

    useEffect(
        () => {
            dispatch(initializeSettings() as any);
        }, []
    )
};

export const useInitializeMapLayers = () => {

    const dispatch = useDispatch();

    useEffect(
        () => {
            dispatch(initializeMapLayers() as any);
        }, []
    )
};

export const refreshData = (
    dispatch: any
) => {
    dispatch(getAllAldotMessages());
    dispatch(getAllAleaAlerts());
    dispatch(getAllCameras());
    dispatch(getAllCamerasGrouped());
    dispatch(getAllCrashes());
    dispatch(getAllFerries());
    dispatch(getAllIncidents());
    dispatch(getAllMessageSigns());
    dispatch(getAllRegionalEvents());
    dispatch(getAllRoadConditions());
    dispatch(getAllRoadwork());
    dispatch(getAllStateFacilities());
    dispatch(getAllTravelTimes());
    dispatch(getAllTravelerInformationSystems());
    dispatch(getAllWeatherAlertsLayer());
    dispatch(getAllWeatherAlertsActive());
}

// get new data on the interval chosen by the user
export const useRefreshData = () => {

    const dispatch = useDispatch();
    const settingsStore: any = useSelector( (state: any) => state.settings);
    
    const repeatRefresh = (interval: number = milliToMinute(5), isInit: boolean) => {

        // if this is the first time calling this funciton, we skip the refresh
        // because data initialization should already be underway
        if (!isInit) refreshData(dispatch);

        let newTimeoutId: NodeJS.Timeout | undefined = setTimeout(
            () => repeatRefresh(interval, false),
            interval
        );

        dispatch(resetRefreshTimeoutId(newTimeoutId) as any);
    }

    useEffect(
        () => {

            // first, reset the refresh timeout to cancel current refresh loop
            dispatch(resetRefreshTimeoutId() as any);
            // then, start the refresh loop with the new interval value found in store
            repeatRefresh(settingsStore.refreshInterval, true);

        }, [settingsStore.refreshInterval]
    )
};

export const useInitApiData = () => {

    const dispatch = useDispatch();

    useEffect(
        () => {
            dispatch(getAllAleaAlerts("init"));
            dispatch(getAllAldotMessages("init"));
            dispatch(getAllCameras("init"));
            dispatch(getAllCamerasGrouped("init"));
            dispatch(getAllCrashes("init"));
            dispatch(getAllFerries("init"));
            dispatch(getAllIncidents("init"));
            dispatch(getAllMessageSigns("init"));
            dispatch(getAllTravelerInformationSystems("init"));
            dispatch(getAllRegionalEvents("init"));
            dispatch(getAllRoadConditions("init"));
            dispatch(getAllRoadwork("init"));
            dispatch(getAllStateFacilities("init"));
            dispatch(getAllWeatherAlertsLayer("init"));
            dispatch(getAllWeatherAlertsActive("init"));
        }, []
    )

};