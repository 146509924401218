// libraries
import * as React from "react";
import { nuonoe } from "@caps-mobile/common-lib";
import { useBreakpoint } from "~/library/useBreakpoint";
// hooks & context
import { useSelector } from "react-redux";
import { useCitiesAndPlaces, useFilterContent, useLoadTravelTimes } from "./hooks";
// types & models
import { ATCongestionLevel, EAlgoApiObjectType } from "~/interfaces";
import { IATCity, IATPlaceDto } from "@algo/network-manager/models/v3";
// styles
import * as PageStyles from "~/components/views/page-template/Styled";
import * as SC from "~/components/views/data-search-overlay/data-type-sections/detail-section/section-types/Styled";
// components
import PageTemplate from "~/components/views/page-template/PageTemplate";
import { AlgoDropdown } from "~/components/views/algo-dropdown/AlgoDropdown";
import ApiObjectView from "~/components/views/api-object-details/ApiObjectView";
import NoResultsTemplate from "~/components/views/page-template/NoResultsTemplate";
import { getTravelColor } from "~/theme";

export type IProps = {
    //
};

export const TravelTimesPage: React.FC<IProps> = (props) => {

    const {
        //
    } = props;
    
    const { md: isMediumMax } = useBreakpoint();
    const columnCount: number = isMediumMax ? 1 : 2;

    const travelTimeStore = useSelector( (state: any) => state["travel-time"]);
    const filteredTravelTimes = travelTimeStore.dataFilteredBySelections;

    const placeStore = useSelector( (state: any) => state.place);
    const cityStore = useSelector( (state: any) => state.city);

    const placeStrings = placeStore.data?.filter((place: IATPlaceDto) => place.type === "Interstate").map( (place: IATPlaceDto) => place.name || "??");
    const cityStrings = cityStore.data?.map( (city: IATCity) => city.name || "??");

    const [ cityIndices, setCityIndices ] = React.useState<number[]>([]);
    const [ roadwayIndices, setRoadwayIndices ] = React.useState<number[]>([]);
    const [ searchText, setSearchText ] = React.useState<string>("");

    // load travel times if necessary
    useLoadTravelTimes();

    // load dropdown options data if necessary
    useCitiesAndPlaces(placeStore, cityStore);

    // filter content section data when selections/search changes or when data updates
    useFilterContent(
        cityIndices, roadwayIndices, cityStrings, 
        searchText, placeStore, travelTimeStore
    );

    const isLoadingContent: boolean = travelTimeStore.loading;

    return (
        <PageTemplate 
            title={"Travel Times"}
            searchBar={{
                autoSubmit: true, 
                submitCallback: (newText: string) => 
                    setSearchText(newText)
            }}
        >

            <PageStyles.FilterRow>

                <AlgoDropdown
                    options={cityStrings}
                    placeholder={"All Cities"}
                    showPlaceholder={cityIndices.length > 0 ? false : true}
                    scrollThreshold={6}
                    multiSelect={true}
                    callback={(selectedIndex: number) => {
                        if(selectedIndex === -1){
                            setCityIndices([]);
                        }
                        else{
                            let curIndex: number = cityIndices.indexOf(selectedIndex);
                            let newIndices: number[] = cityIndices;
                            if (curIndex === -1) {
                                newIndices.push(selectedIndex);
                            }
                            else {
                                newIndices.splice(curIndex, 1);
                            }
                            setCityIndices([...newIndices]);
                        }
                    }}
                />

                <AlgoDropdown
                    options={placeStrings}
                    placeholder={"All Roadways"}
                    showPlaceholder={roadwayIndices.length > 0 ? false : true}
                    scrollThreshold={6}
                    multiSelect={true}
                    callback={(selectedIndex: number) => {
                        if(selectedIndex === -1){
                            setRoadwayIndices([]);
                        }
                        else{
                            let curIndex: number = roadwayIndices.indexOf(selectedIndex);
                            let newIndices: number[] = roadwayIndices;
                            if (curIndex === -1) {
                                newIndices.push(selectedIndex);
                            }
                            else {
                                newIndices.splice(curIndex, 1);
                            }
                            setRoadwayIndices([...newIndices]);
                        }
                    }}
                />

            </PageStyles.FilterRow>

            <PageStyles.FilteredContentSection twoColumns={true}>

                {   nuonoe(filteredTravelTimes) && filteredTravelTimes.map(
                        (dataObject: any, mapIndex: number) => {
                            const color = getTravelColor(dataObject);

                            return (
                                <SC.DetailsCard key={dataObject.id || mapIndex} columnSize={100/columnCount} travel={true} color={color}>
                                    <ApiObjectView
                                        object={dataObject} type={EAlgoApiObjectType["travel-time"]} showChevron={false}/>
                                </SC.DetailsCard>
                            )
                        }
                    )
                }

            </PageStyles.FilteredContentSection>

            { (!filteredTravelTimes || !filteredTravelTimes?.length || filteredTravelTimes?.length <= 0) &&
                <NoResultsTemplate loading={isLoadingContent} pageType="Travel Times" />
            }

        </PageTemplate>
    );
};

export default TravelTimesPage;