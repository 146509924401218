// libraries
import { 
    commonGetAll_api, commonGetById_api, 
    commonFilterBySearch, createAlgoSlice 
} from "./common";
// types & models
import { PlacesNetworkManager, IProcessedResponse } from "@algo/network-manager/managers/v3";
import { EAlgoApiObjectType, ICoord } from "~/interfaces";
// constants
import { CUR_API_VERSION, CUR_API_ENDPOINTS } from "../../api-endpoint-strings";
import { isTesting } from "~/constants"
// test data
import T_DATA from "../test-data/places/all-places-11-10-22.json";
import { buildLastResponse } from "~/store/library";
import { IATPlaceDto } from "@algo/network-manager/models/v3";
import { nuonoe } from "@caps-mobile/common-lib";

declare var __API_URL__: string;
const apiUrl: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).places}`;

// create list slice
export const placeSlice = 
    createAlgoSlice(EAlgoApiObjectType.place);

// get handles for the slice's actions
const { 
    begin, success, failure, filterDataBySearch
} = placeSlice.actions;

// handles dispatching a data get by id from either api or test source based on args
export const getById = (id: number, test: boolean = isTesting, testMode?: string) => {

    return (
        dispatch: any,
        getState: any
    ) => {
        if (test)
            dispatch(getById_test(id, testMode));
        else 
            dispatch(getById_api(id));
    }
}

// retrieves data from api for this data type
export const getById_api = (id: number) => {

    return commonGetById_api(
        new PlacesNetworkManager(apiUrl), 
        id, 
        placeSlice
    );
    
};

// retrieves test data for this data type
export const getById_test = (id: number, mode?: string) => {
    alert("place: getById_test is not yet implemented.")
};

// handles dispatching a data get all from either api or test source based on args
export const getAll = (
    params?: any,
    test: boolean = isTesting, 
    testMode?: string
): any => {

    return (
        dispatch: any,
        getState: any
    ) => {

        if (getState()[EAlgoApiObjectType.place].loading) return;

        if (test)
            dispatch(getAll_test(testMode));
        else 
            dispatch(getAll_api(params));
    }
}

// retrieves all data from api for this data type
export const getAll_api = (params: any) => {

    return commonGetAll_api(
        new PlacesNetworkManager(apiUrl), 
        placeSlice,
        params
    );
    
};

// retrieves test data for this data type
export const getAll_test = (mode?: string) => {

    return (
        dispatch: any,
        getState: any
    ) => {

        dispatch(begin());
        
        setTimeout(
            () => {
                dispatch(success({data: T_DATA, errorMessage: null, status: 200}))
            }, 2500
        );
    }
    
};

// filters data list based on given search term
export const filterBySearch = (searchTerm: string = "") => {

    return commonFilterBySearch(placeSlice, searchTerm, searchMatch);
    
};

const searchMatch = (
    place: IATPlaceDto, 
    searchTerm: string = "",
): boolean => {

    if (!searchTerm) return true;

    if (
        place.name?.toLowerCase().includes(searchTerm.toLowerCase())
    )   return true;

    return false;
}

// exports the slice's reducer ( used in store file to build up master reducer )
export const placeReducer = placeSlice.reducer;