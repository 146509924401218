// libraries 
import * as React from "react";
// styles
import * as SC from "./Styled";
// types & models
import { EAlgoApiObjectType } from "~/interfaces";
// components
import ApiObjectView from "../../../../api-object-details/ApiObjectView";

export type IProps = {
    objectsList?: any[];
    dataType: EAlgoApiObjectType;
    showChevron?: boolean;
    isCollapseable?: boolean;
};

export const DetailCardsSection: React.FC<IProps> = (props) => {

    const { 
        objectsList, dataType, showChevron = true, isCollapseable 
    } = props;

    return (
        <SC.StyledDetailCardsSection>
            {   objectsList &&
                objectsList.map(
                    (dataObject: any, index: number) => {
                        return (
                            <SC.DetailsCard key={dataObject.id || index} extraStyling={true}
                                showPointer={showChevron || isCollapseable}
                            >
                                <ApiObjectView
                                    object={dataObject} type={dataType} showChevron={showChevron} />

                            </SC.DetailsCard>
                        );
                    }
                )
            }
        </SC.StyledDetailCardsSection>
    )
}

export default DetailCardsSection;