// libraries
import styled from "styled-components";
import { flexSmart } from "@caps-mobile/styled-lib";
// constants
import { BORDER_RADIUS } from "~/constants";

export const UpperUIOverlay = styled.div<{fullScreenAvailable?: boolean}>`
    height: ${props => props.fullScreenAvailable ? '150px' : '100px'};
    width: 60px;
    z-index: 10000;

    ${flexSmart("space-evenly center")}
    flex-direction: column;

    background-color: rgba(30, 30, 30, 0.75);
    margin: 30px;

    font-size: 26px;
    color: rgba(235, 235, 245, 0.6);
    --fa-primary-color: black;
    --fa-secondary-color: rgba(235, 235, 245, 1); 

    border-radius: ${BORDER_RADIUS}px;
`;

export const UpperUIButton = styled.div`

    :hover{
        cursor: pointer;
    }
`;