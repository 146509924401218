// libraries
import styled from "styled-components";
import { flexSmart } from "@caps-mobile/styled-lib";
import { BORDER_RADIUS } from "~/constants";

export const BannerOptionSection = styled.div`
    width: 100%;
    padding-bottom: 20px;
    box-sizing: border-box;
`;

export const StyledBannerOption = styled.div<{styleOverwrite?: string}>`
    width: 100%;
    
    padding: 10px 20px;
    box-sizing: border-box;

    ${flexSmart("space-between center")}

    :first-child{
        border-top-right-radius: ${BORDER_RADIUS}px;
        border-top-left-radius: ${BORDER_RADIUS}px;
    }

    :last-child{
        border-bottom-right-radius: ${BORDER_RADIUS}px;
        border-bottom-left-radius: ${BORDER_RADIUS}px;
    }

    background-color: rgba(117, 117, 117, 0.1);

    :hover{
        cursor: pointer;
    }

    ${props => props.styleOverwrite ? props.styleOverwrite : ""}
`;

export const IconAndLabelWrap = styled.div`
    ${flexSmart("flex-start center")}
    flex-direction: row;

`;

export const OptionIcon = styled.div`
    margin-right: 10px;
`;

export const OptionLabel = styled.div<{color?: string, styleOverwrite?: string}>`
    font-size: 13px;
    line-height: 160%;
    font-weight: 600;

    color: ${props => props.color ? props.color : "#000"};

    ${props => props.styleOverwrite ? props.styleOverwrite : ""}
`;

export const OptionValue = styled.div<{color?: string, styleOverwrite?: string}>`
    font-size: 14px;
    line-height: 140%;
    font-weight: 400;

    color: ${props => props.color ? props.color : "#000"};

    ${props => props.styleOverwrite ? props.styleOverwrite : ""}
`;