// libraries
import * as React from "react";
// styles
import * as SC from "./Styled";
import { mapPageTypeToIcon } from "~/resources";
// components

export type IProps = {
    loading?: boolean;
    pageType: string;
};

export const NoResultsTemplate: React.FC<IProps> = (props) => {

    const { loading, pageType } = props;
    
    const icon = mapPageTypeToIcon(pageType);

    return (
        <SC.NoResultsPage>
            <SC.Icon>{icon}</SC.Icon>
            { loading && <SC.NoResultsText>Loading...</SC.NoResultsText> }
            { !loading && <SC.NoResultsText>No {pageType}</SC.NoResultsText> }
        </SC.NoResultsPage>
    );
};

export default NoResultsTemplate;