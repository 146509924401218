// libraries
import { 
    commonGetAll_api, commonGetById_api, 
    createAlgoSlice, commonInit_api
 } from "./common";
// types & models
import { AldotMessageNetworkManager } from "@algo/network-manager/managers/v3";
import { EAlgoApiObjectType } from "~/interfaces";
// constants
import { CUR_API_VERSION, CUR_API_ENDPOINTS } from "../../api-endpoint-strings";
import { isTesting } from "~/constants"
// test data
import T_DATA from "~/store/algo-api/test-data/aldot-messages/all-aldot-messages-10-5-22.json";

declare var __API_URL__: string;
const apiUrl: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).aldotMessages}`;

// create list slice
export const aldotMessageSlice = 
    createAlgoSlice(EAlgoApiObjectType["aldot-message"]);

// get handles for the slice's actions
const { begin, success, failure, beginInit, successInit, failureInit } = aldotMessageSlice.actions;

// handles dispatching a data get by id from either api or test source based on args
export const getById = (id: number, test: boolean = isTesting, testMode?: string) => {

    return (
        dispatch: any,
        getState: any
    ) => {
        if (test)
            dispatch(getById_test(id, testMode));
        else 
            dispatch(getById_api(id));
    }
}

// retrieves data from api for this data type
export const getById_api = (id: number) => {

    return commonGetById_api(
        new AldotMessageNetworkManager(apiUrl), 
        id, 
        aldotMessageSlice
    );
    
};

// retrieves test data for this data type
export const getById_test = (id: number, mode?: string) => {
    
    alert("aldot-message: getById_test is not yet implemented.")
};

// handles dispatching a data get all from either api or test source based on args
export const getAll = (mode?: string, test: boolean = isTesting): any => {

    return (
        dispatch: any,
        getState: any
    ) => {

        if (getState()[EAlgoApiObjectType["aldot-message"]].loading) return;

        if (test)
            dispatch(getAll_test(mode));
        else 
            dispatch(getAll_api(mode));
    }
}

// retrieves all data from api for this data type
export const getAll_api = (mode?: string) => {

    if (mode === "init")
        return commonInit_api(
            new AldotMessageNetworkManager(apiUrl), 
            aldotMessageSlice,
        )
    else 
        return commonGetAll_api(
            new AldotMessageNetworkManager(apiUrl), 
            aldotMessageSlice,
        );
    
};

// retrieves test data for this data type
export const getAll_test = (mode?: string) => {

    return (
        dispatch: any,
        getState: any
    ) => {

        let beginFunction = begin;
        let successFunction = success;
        if (mode === "init") {
            beginFunction = beginInit;
            successFunction = successInit;
        }

        dispatch(beginFunction());
        
        setTimeout(
            () => {
                dispatch(successFunction({data: T_DATA, errorMessage: null, status: 200}))
            }, 2500
        );
    }
    
};

// exports the slice's reducer ( used in store file to build up master reducer )
export const aldotMessageReducer = aldotMessageSlice.reducer;