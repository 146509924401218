// libraries
import { useEffect } from "react";
// types & models
import { IMarkerTapObject } from "~/interfaces";
import { usePolylineGroup } from "./usePolylineGroup";

export const useAllPolylineGroups = (
    map: H.Map | undefined, 
    selectedIndex: number,
    tapCallback?: (index: number) => void
) => {

    // routes
    const routeGroup = usePolylineGroup(map, selectedIndex, tapCallback);
    routeGroup?.setVisibility(true);

    useEffect(
        () => {

            if (map){
                // event-type markers
                routeGroup && map.addObject(routeGroup);
            }

            return () => {
                //
            }

        }, [
            map, routeGroup
        ]
    );

    return routeGroup;
};