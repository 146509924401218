// libraries
import * as React from "react";
// styles
import * as SC from "./Styled";
import * as PageStyles from "~/components/views/page-template/Styled";
// types & models
import { EAlgoApiObjectType } from "~/interfaces";
// hooks & context
import { useSelector } from "react-redux";
import RouteCamerasOverlayContext from "~/contexts/RouteCamerasOverlayContext";
// components
import BackButton from "../back-button/BackButton";
// resources
import 
    DetailsSection, { EDetailDisplayType } 
from "~/components/views/data-search-overlay/data-type-sections/detail-section/DetailsSection";
import NoResultsTemplate from "../page-template/NoResultsTemplate";
import VideoboardModalContext from "~/contexts/VideoboardModalContext";
import VideoboardManager from "../videoboard/VideoboardManager";
import VideoboardModal from "../modal/videoboard-modal/VideoboardModal";

export type IProps = { };

export const RouteCamerasOverlay: React.FC<IProps> = (props) => {

    const overlayContext = React.useContext(RouteCamerasOverlayContext);

    // get the respective data
    const routeStore: any = useSelector( (store: any) => store.route );
    const data: any[] = routeStore.cameras ?? [];

    const videoboardModalContext: any = React.useContext(VideoboardModalContext);
    const VM: VideoboardManager = new VideoboardManager(data.filter(x => x.hlsUrl));
    
    const playAllClickHandler = () => {
        videoboardModalContext.setModalContent(
            <VideoboardModal 
                videoboard={VM.videoboard}
                doneCallback={() => videoboardModalContext.setShowModal(false)} />
        );
        videoboardModalContext.setShowModal(true);
    };

    return (
        <SC.StyledDataSearchOverlay id={"route-cameras-overlay"}
            showOverlay={overlayContext.showOverlay}>

            <SC.OverlayContent>

                <PageStyles.StyledPageTemplate>

                    <SC.BackAndTitleWrap>

                        <BackButton callback={() => overlayContext.setShowOverlay(false)} />

                        <SC.Title>
                            <span>Route {overlayContext.routeIndex} Cameras</span>
                        </SC.Title>

                    </SC.BackAndTitleWrap>

                    { data && data.length > 0 &&
                        <SC.PlayAllSection>
                            <SC.PlayAllText onClick={() => {if(data && data.length > 0) playAllClickHandler();}}>
                                {`Play All`}
                            </SC.PlayAllText>
                        </SC.PlayAllSection>
                    }

                    <PageStyles.FilteredContentSection>

                        { data && data.length > 0 &&
                            <div>
                                <DetailsSection dataObjects={data} showAll={true}
                                    dataType={EAlgoApiObjectType.camera} displayType={EDetailDisplayType.snapshot} />

                                <SC.cameraDisclaimer>
                                    ALGO Traffic cameras are a public safety tool for use in real time and not intended to be recorded or 
                                    otherwise used for any commercial purpose. All unauthorized photography, recording, storing, or 
                                    transmitting of visual material, data, or information gathered from ALGO traffic cameras without the 
                                    permission of ALDOT is expressly prohibited.
                                </SC.cameraDisclaimer>
                            </div>
                        }

                        { (!data || !data?.length || data?.length <= 0) &&
                            <NoResultsTemplate loading={false} pageType="Cameras" />
                        }

                    </PageStyles.FilteredContentSection>

                </PageStyles.StyledPageTemplate>

            </SC.OverlayContent>

        </SC.StyledDataSearchOverlay>
    );

};

export default RouteCamerasOverlay;