// libraries
import * as React from "react";
import { nuonoe } from "@caps-mobile/common-lib";
// hooks & contexts
import DetailModalContext, { IDetailModalContext } from "~/contexts/DetailModalContext";
// styles
import * as SC from "./Styled";
// types & models
import { ATCamera, IATCameraDto } from "@algo/network-manager/models/v3";
// components
import Snapshot from "~/components/views/snapshot/Snapshot";
import ObjectDetailModal from "~/components/views/modal/object-detail-modal/ObjectDetailModal";
import { EAlgoApiObjectType } from "~/interfaces";
import VideoboardModal from "~/components/views/modal/videoboard-modal/VideoboardModal";
import VideoboardManager from "~/components/views/videoboard/VideoboardManager";
import VideoboardModalContext from "~/contexts/VideoboardModalContext";

export type IProps = {
    objectList?: any[]
};

export const SnapshotSection: React.FC<IProps> = (props) => {

    const detailModalContext: IDetailModalContext = 
        React.useContext(DetailModalContext);

        
    const videoboardModalContext: any = React.useContext(VideoboardModalContext);

    const playOneClickHandler = (camera: IATCameraDto) => {
        const SingleVM: VideoboardManager = new VideoboardManager([camera]);

        videoboardModalContext.setModalContent(
            <VideoboardModal 
                videoboard={SingleVM.videoboard}
                doneCallback={() => videoboardModalContext.setShowModal(false)} />
        );
        videoboardModalContext.setShowModal(true);
    }

    if (!nuonoe(props.objectList)) return null;

    return (
        <SC.SnapshotRow>
            {
                props.objectList!.map(
                    (camera: IATCameraDto) => {
                        return (
                            <SC.SnapshotWrap key={camera.id}>
                                <Snapshot 
                                    camera={ new ATCamera(camera)} showPlayButton={true} 
                                    clickCallback={ () => { 
                                        playOneClickHandler(camera);
                                    }}
                                />
                            </SC.SnapshotWrap>
                        );
                    }
                )
            }
        </SC.SnapshotRow>
    );
};

export default SnapshotSection;