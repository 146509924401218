import * as BP from "./breakpoints";

export const Globals = {

    globals: {
        app: `
            height: 100%;

            font-family: "SF Pro Text", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Noto sans", Helvetica Neue, sans-serif;
        `,
        "page-level-wrapper": `
            width: auto;
        
            padding: 0 144px;
        
            ${BP.whenMediumMax(`
                padding: 0 48px;
            `)}
        
            ${BP.whenSmallMax(`
                padding: 0 36px;
            `)}
        
            ${BP.whenXSmallMax(`
                padding: 0 18px;
            `)}
        `,
    },
};

export default Globals;