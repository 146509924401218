// libraries
import * as React from "react";
// styles
import * as BannerStyles from "~/components/views/banner-option/Styled";
// types & models
import { IDocObject } from "~/interfaces";
// constants
import { algo_green_text } from "~/constants";
import PageTemplate from "../../views/page-template/PageTemplate";
import { BannerOptionSection } from "~/components/views/banner-option/Styled";
import BannerOption, { EDefaultValue } from "~/components/views/banner-option/BannerOption";

const DOC_STRINGS: IDocObject[] = [
    {
        title: "Alabama Department of Transportation",
        url: `https://www.dot.state.al.us/`
    },
    {
        title: "Alabama Law Enforcement Agency",
        url: `https://www.alea.gov/`
    },
    {
        title: "Alabama Emergency Management Agency",
        url: `https://ema.alabama.gov/`
    },
    {
        title: "Federal Motor Carrier Safety Administration",
        url: `https://www.fmcsa.dot.gov/`
    },
    {
        title: "Glance TravelSafely for iOS",
        url: `https://apps.apple.com/us/app/glance-travelsafely/id1194972007`
    },
    {
        title: "Glance TravelSafely for Android",
        url: `https://play.google.com/store/apps/details?id=com.appinfo.travelsafely`
    }
];

export type IProps = {
    //
};

export const ExternalLinksPage: React.FC<IProps> = (props) => {

    const {
        //
    } = props;

    return (
        <PageTemplate title={"External Links"}>

            <BannerOptionSection>

                {DOC_STRINGS.map(
                    (docObject: IDocObject) => {
                        return (
                            <BannerOption key={docObject.title}
                                clickCallback={() => { window.open(docObject.url)}}
                                label={
                                    <React.Fragment>
                                        <BannerStyles.OptionLabel color={algo_green_text}>{docObject.title}</BannerStyles.OptionLabel>
                                    </React.Fragment>
                                } 
                                defaultValue={EDefaultValue["external-link"]}
                            />
                        );
                    }
                )}
                
            </BannerOptionSection>

        </PageTemplate>
    );
};

export default ExternalLinksPage;