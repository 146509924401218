// libraries
import * as React from "react";
// components
import OverlaySection from "../../../../views/map-overlay-views/OverlaySection";
import AlertAndMessageTicker from "~/components/views/tickers/AlertAndMessageTicker";
import WeatherTicker from "~/components/views/tickers/WeatherTicker";

export type IProps = {
    hasWeather?: boolean;
    hasAldotOrAlea?: boolean;
};

export const TickerSection: React.FC<IProps> = (props) => {

    return (
        <OverlaySection top={0} left={0}>
            { props.hasWeather && <WeatherTicker /> }
            { props.hasAldotOrAlea && <AlertAndMessageTicker /> }
        </OverlaySection>
    );
};

export default TickerSection;