export const Typography = {
    "heading-1": `font-weight: 600; font-size: 48px; line-height: 52.02px;`,
    "heading-2": `font-weight: 600; font-size: 32px; line-height: 36px; font-variant: small-caps;`,
    "heading-2.5": `font-weight: 600; font-size: 28px; line-height: 31.5px; font-variant: small-caps;`,
    "heading-3": `font-weight: 600; font-size: 24px; line-height: 28px;`,
    "heading-4": `font-weight: 600; font-size: 21px; line-height: 25px;`,
    "heading-5": `font-weight: 600; font-size: 19px; line-height: 23px;`,
    "body-1": `font-weight: 400; font-size: 17px; line-height: 25px;`,
};

export default Typography;