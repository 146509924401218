// libraries
import { IATMessageSignDto } from "@algo/network-manager/models/v3";
import * as React from "react";
import MessageSign from "../../../message-sign/MessageSign";
// styles
import * as SC from "./Styled";
// components

export type IProps = {
    object: IATMessageSignDto;
};

export const MessageSignDetails: React.FC<IProps> = (props) => {

    const {
        object
    } = props;

    return (
        <SC.StyledMessageSignDetails>

            <MessageSign sign={object} />

        </SC.StyledMessageSignDetails>
    )
};

export default MessageSignDetails;