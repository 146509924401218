// libraries
import React, { Fragment } from "react";
// hooks & context
import { ThemeContext } from "../../../theme";
// styles
import * as SC from "./Styled";
// components
import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export type IProps = {
    callback?: () => void;
    children?: React.ReactNode;
};

export const BackButton: React.FC<IProps> = (props) => {

    const theme: any = React.useContext(ThemeContext);

    return (
        <SC.StyledBackButton onClick={props.callback}>
            {
                !props.children &&
                    <Fragment>
                        <FontAwesomeIcon
                            className="fa-fw"
                            icon={faChevronLeft}
                            size={"1x"}
                            color={theme.colors["primary"].rgba}
                        />
                        {"Back"}
                    </Fragment>
            }
            {
                props.children &&
                props.children
            }
        </SC.StyledBackButton>
    );
};

export default BackButton;