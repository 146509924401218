// libraries
import styled from "styled-components";
import { flexSmart } from "@caps-mobile/styled-lib";

export const StyledCamerasPage = styled.div`
    height: 100%;
    width: 100%;

    ${flexSmart("center center")}

    background-color: ${props => props.theme.colors["primary"].rgba};

    font-size: 64px;
    color: ${props => props.theme.colors["text-primary"].rgba};
`;

export const cameraDisclaimer = styled.div`
    margin-bottom: 10px;
    font-size: .7em;
`;
