// libraries
import { useEffect } from "react";
// hooks & context
import { useFeatureToggle } from "./useFeatureToggle";
// types & models
import { EAlgoLayerType, IMarkerTapObject } from "~/interfaces";
import { usePolygonGroup } from "./usePolygonGroup";

export const useAllPolygonGroups = (
    map: H.Map | undefined, 
    tapCallback?: (markerData: IMarkerTapObject) => void
) => {

    // weather polygons
    const weatherGroup = usePolygonGroup(map, tapCallback);
    useFeatureToggle(EAlgoLayerType["weather-alert"], weatherGroup);

    useEffect(
        () => {

            if (map){
                // event-type markers
                weatherGroup && map.addObject(weatherGroup);
            }

            return () => {
                //
            }

        }, [
            map, weatherGroup
        ]
    );
};