// libraries
import * as React from "react";
// styles
import * as SC from "./Styled";
// types & models
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faCircleCheck } from "@fortawesome/pro-regular-svg-icons";

export type IProps = {
    title?: string;
    optionLabels: string[],
    currentSelection: number,
    selectCallback: (selectedIndex: number) => void,
    cancelCallback?: () => void,
    styleOverwrite?: string;
};

export const RadioOptionsCard: React.FC<IProps> = (props) => {

    const {
        title, optionLabels, currentSelection, 
        selectCallback, cancelCallback, styleOverwrite 
    } = props;

    return (
        <SC.StyledRadioOptionsCard styleOverwrite={styleOverwrite}>
            
            

            <SC.HeaderContainer>
            { title && 
                <SC.Header>
                    {title}              
                </SC.Header>
            }
            <SC.Cancel onClick={cancelCallback}>
                {`Close`}
            </SC.Cancel>
            </SC.HeaderContainer>
            

            
            {optionLabels.map(
                (label: string, mapIndex: number) => {

                    let isSelected: boolean = 
                        currentSelection === mapIndex;

                    return ( 
                        <SC.OptionRow 
                            key={label+mapIndex}
                            onClick={() => selectCallback(mapIndex)}
                        >

                            <SC.RadioLabel>{label}</SC.RadioLabel>

                            <FontAwesomeIcon 
                                icon={
                                    (isSelected)
                                        ?   faCircleCheck
                                        :   faCircle
                                }
                                color={
                                    (isSelected)
                                        ?   "green"
                                        :   "black"
                                }
                            />

                        </SC.OptionRow>
                    );
                }
            )}

            

        </SC.StyledRadioOptionsCard>
    )
};

export default RadioOptionsCard;