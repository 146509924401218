// libraries
import styled from "styled-components";
// resources
import CameraGlyph from "./icons/map-layer-icons/react/CameraGlyph";
import CrashGlyph from "./icons/map-layer-icons/react/CrashGlyph";
import FerryGlyph from "./icons/map-layer-icons/react/FerryGlyph";
import IncidentGlyph from "./icons/map-layer-icons/react/IncidentGlyph";
import MessageSignGlyph from "./icons/map-layer-icons/react/MessageSignGlyph";
import RegionalEventGlyph from "./icons/map-layer-icons/react/RegionalEventGlyph";
import RoadworkGlyph from "./icons/map-layer-icons/react/RoadworkGlyph";
import RoadConditionGlyph from "./icons/map-layer-icons/react/RoadConditionGlyph";
import StateFacilityGlyph from "./icons/map-layer-icons/react/StateFacilityGlyph";
import WeatherAlertGlyph from "./icons/map-layer-icons/react/WeatherAlertGlyph";
import TrafficLayerGlyph from "./icons/map-layer-icons/react/TrafficLayerGlyph";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
    faXmark, faRefresh, faCar, faEllipsisVertical, faLocationDot, faVideo, faGauge, faGaugeHigh, faUserPolice 
} from "@fortawesome/pro-regular-svg-icons";

export const iconWidth: number = 36;
export const iconHeight: number = 36;

export const activeColor: string = "#fff";
export const defaultColor: string = "rgba(0,0,0,0.65)";

export const eventTypeList: string[] = [
    "crash", "crashes", 
    "incident", "incidents",
    "roadwork",
    "roadcondition", "roadconditions", "road-condition", "road-conditions",
    "regional event", "regional events", "regional-event", "regional-events"
];

export const nonEventList: string[] = [
    "all",
    "weather alert", "weather alerts", "weather-alert", "weather-alerts",
    "message sign", "message signs", "message-sign", "message-signs",
    "camera", "cameras", "camera-group", "camera-groups",
    "other511", "other 511",
    "travel information system", "travel information systems",
    "traveler information system", "traveler information systems",
    "traveler-information-system", "traveler-information-system",
    "state facility", "state facilities", "state-facility", "state-facilities",
    "traffic", "traffic layer", "traffic-layer", 
    "traffic speed", "traffic speeds", "traffic-speed", "traffic-speeds",
    "ferry", "ferries"
];

// maps icons to those options which represent one of the traffic event types
export const mapEventTypeToComp = (name: string, isActive?: boolean) => {
    switch(name.toLowerCase()){
        case "crash":
            return <CrashGlyph width={iconWidth} height={iconHeight} color={ isActive ? activeColor : defaultColor } />
        case "incident":
            return <IncidentGlyph width={iconWidth} height={iconHeight} color={ isActive ? activeColor : defaultColor } />
        case "road-condition":
            return <RoadConditionGlyph width={iconWidth} height={iconHeight} color={ isActive ? activeColor : defaultColor } />
        case "roadwork":
            return <RoadworkGlyph width={iconWidth} height={iconHeight} color={ isActive ? activeColor : defaultColor } />
        case "regional-event":
            return <RegionalEventGlyph width={iconWidth} height={iconHeight} color={ isActive ? activeColor : defaultColor } />
        default: 
            return null;
    }
};

// maps icons to those options which represent one of the page types
export const mapPageTypeToIcon = (name: string) => {
    switch(name.toLowerCase()){
        case "cameras":
            return <FontAwesomeIcon icon={faVideo} color={"#007556"} size={"4x"} />
        case "traffic reports":
            return <FontAwesomeIcon icon={faCar} color={"#007556"} size={"4x"} />
        case "travel times":
            return <FontAwesomeIcon icon={faGaugeHigh} color={"#007556"} size={"4x"} />
        case "law enforcement alerts":
            return <FontAwesomeIcon icon={faUserPolice} color={"#007556"} size={"4x"} />
        default: 
            return null;
    }
};

// maps icons to those options which do not represent traffic event types
export const mapNonEventTypeToComp = (name: string, isActive?: boolean) => {
    switch(name.toLowerCase()){
        case "all":
            return <ThickText isActive={isActive}>{"ALL"}</ThickText>;
        case "camera":
        case "camera-group":
            return <CameraGlyph width={iconWidth} height={iconHeight} color={ isActive ? activeColor : defaultColor } />
        case "ferry":
            return <FerryGlyph width={iconWidth} height={iconHeight} color={ isActive ? activeColor : defaultColor } />
        case "message-sign":
            return <MessageSignGlyph width={28} height={28} color={ isActive ? activeColor : defaultColor } />
        case "other511":
            return <ThickText isActive={isActive}>{"511"}</ThickText>
        case "road-condition":
            return <RoadConditionGlyph width={iconWidth} height={iconHeight} color={ isActive ? activeColor : defaultColor } />
        case "state-facility":
            return <StateFacilityGlyph width={iconWidth} height={iconHeight} color={ isActive ? activeColor : defaultColor } />
        case "traffic-layer":
            return <TrafficLayerGlyph width={32} height={32} color={ isActive ? activeColor : defaultColor } />
        case "weather-alert":
            return <WeatherAlertGlyph width={96} height={96} color={ isActive ? activeColor : defaultColor } />
        default:
            return null;
    }
};

// takes the name associated with the icon/option and returns the appropriate view component
export const mapIconNameToComp = (name: string, isActive?: boolean) => {
    if (eventTypeList.includes(name))
        return mapEventTypeToComp(name, isActive);
    else if (nonEventList.includes(name))
        return mapNonEventTypeToComp(name, isActive);
};

// the style wrapper for options that are Text-Only
export const ThickText = styled.div<{isActive?: boolean}>`
    font-weight: 700;
    color: ${props => props.isActive ? activeColor : defaultColor};
`;