// libraries
import * as React from "react";
// styles
import * as SC from "./Styled";

export type IProps = {
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;

    styleOverwrite?: string;
    childWrapperStyleOverwrite?: string;

    children?: React.ReactNode;
};

export const OverlaySection: React.FC<IProps> = (props) => {

    const { 
        top, right, bottom, left, 
        styleOverwrite, childWrapperStyleOverwrite 
    } = props;

    return (
        <SC.StyledOverlaySection 
            top={top} right={right} bottom={bottom} left={left}
            styleOverwrite={styleOverwrite}
        >
            <SC.ChildrenWrapper styleOverwrite={childWrapperStyleOverwrite}>
                {props.children}
            </SC.ChildrenWrapper>
        </SC.StyledOverlaySection>
    )
}

export default OverlaySection;