// libraries
import { usePrevious } from "@algo/hooks";
import { RouteRequestIdentifier } from "@algo/network-manager/managers/v3";
import { IATRouteRequest } from "@algo/network-manager/models/v3";
import { minuteToMilli } from "@caps-mobile/date-time";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generateRoutes, getTrafficEvents, resetRoutes } from "~/store/algo-api/slices/routes";

// causes the map view to resize after the nav panel animation
export const useResizeMapOnNavToggle = (map: H.Map | undefined, panelOpen: boolean) => {

    useEffect( 
        () => { 
            if (map)
                setTimeout( () => map.getViewPort().resize(), 500)
        }, [panelOpen]
    );

};

// causes the map view to resize and sets zoom after window resize
export const useWindowResize = (map: H.Map | undefined, width: number, height: number, boundBox: H.geo.Rect | undefined, isMediumMax?: boolean) => {
    useEffect(
        () => {
            if (map) {
                setTimeout(() => {
                    map.getViewPort().resize()
                    if (boundBox) {
                        map.getViewModel().setLookAtData({
                            bounds: boundBox
                        });
                    }
                }, 0)
            }
        }, [width, height]
    );

}

// adds listener to the map that changes cursor style to pointer when
// hovering over a marker

function mapCursorListener(event: any, map: H.Map) {
    if (event.target instanceof H.map.Marker || event.target instanceof H.map.Polygon || event.target instanceof H.map.Polyline) {
        (map.getViewPort().element as any).style.cursor = 'pointer';
    } else {
        (map.getViewPort().element as any).style.cursor = 'auto';
    }
}

export const useCursorOnFeature = (map: H.Map | undefined) => {

    useEffect(
        () => {

            if (map){

                map.addEventListener('pointermove', (evt) => mapCursorListener(evt, map), false);
    
                return () => {
                    map.removeEventListener('pointermove', (evt) => mapCursorListener(evt, map), false);
                }
            }

        }, [map]
    );
};


export const TrafficEventDataRefresh = (index: number, routeRequest: IATRouteRequest | undefined) => {

    const dispatch = useDispatch();
    
    const routeStore: any = useSelector( (store: any) => store.route );
    const routeData: any[] = routeStore.data?.slice(0, 3)
    ? [...routeStore.data?.slice(0, 3)] : [];

    const checksum: number = routeStore.trafficEventsLastChecksum;
    const prevChecksum = usePrevious(checksum);
    const routeId = routeData[index-1]?.properties?.data?.id;
    const status = routeStore.trafficEventsStatus;
    const prevRouteId = usePrevious(routeId);
    
    useEffect(() => {
        //if traffic events call fails get routes again
        if((status === 400 || status === 404) && routeRequest){
            dispatch(generateRoutes(routeRequest) as any);
        }
        //if checksum or index has changed get traffic events again
        else if(((checksum !== prevChecksum) || (routeId !== prevRouteId)) && routeId){
            dispatch(getTrafficEvents(routeId) as any);
        }

        const interval = setInterval(() => {
            if(routeId)
                dispatch(getTrafficEvents(routeId, true) as any);
        }, minuteToMilli(1));
    
        return () => clearInterval(interval); 
        
      }, [status, checksum, prevChecksum, routeId, prevRouteId]);
};

export const RefreshRouteData = () => {

    const dispatch = useDispatch();

    useEffect(()=>{
        return ()=> dispatch(resetRoutes())
    },[])
};