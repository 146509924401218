import _accidentPin from "./ic_accident_pin.png";
import _cameraPin from "./ic_camera_pin.png";
import _constructionPin from "./ic_construction_pin.png";
import _eventPin from "./ic_event_pin.png";
import _ferryPin from "./ic_ferry_pin.png";
import _incidentPin from "./ic_incident_pin.png";
import _signPin from "./ic_sign_pin.png";
import _signOffPin from "./ic_sign_off_pin.png";
import _weatherPin from "./ic_weather_pin.png";
import _welcomeCenterOrigin from "./ic_welcomeCenter_origin_pin.png";
import _welcomeCenterOriginClosed from "./ic_welcomeCenter_origin_closed_pin.png";
import _restAreaOrigin from "./ic_restArea_origin_pin.png";
import _restAreaOriginClosed from "./ic_restArea_origin_closed_pin.png";
import _currentLocation from "./ic_current_location_pin.png";

// states
import _arkansas from "../state/StatePinArkansas.png";
import _florida from "../state/StatePinFlorida.png";
import _georgia from "../state/StatePinGeorgia.png";
import _kentucky from "../state/StatePinKentucky.png";
import _louisiana from "../state/StatePinLouisiana.png";
import _mississippi from "../state/StatePinMississippi.png";
import _northcarolina from "../state/StatePinNorthCarolina.png";
import _southcarolina from "../state/StatePinSouthCarolina.png";
import _tennessee from "../state/StatePinTennessee.png";
import _texas from "../state/StatePinTexas.png";
import _unknown from "../state/StatePinUnknown.png";

import { EATEventType } from "@algo/network-manager/models/v3"; 
import { EAlgoLayerType } from "~/interfaces";

export const accidentPin = _accidentPin;
export const cameraPin = _cameraPin;
export const constructionPin = _constructionPin;
export const eventPin = _eventPin;
export const ferryPin = _ferryPin;
export const incidentPin = _incidentPin;
export const restAreaPin = _restAreaOrigin;
export const restAreaClosedPin = _restAreaOriginClosed;
export const signPin = _signPin;
export const signOffPin = _signOffPin;
export const weatherPin = _weatherPin;
export const welcomeCenterPin = _welcomeCenterOrigin;
export const welcomeCenterClosedPin = _welcomeCenterOriginClosed;
export const welcomeCenterOrigin = _welcomeCenterOrigin;
export const restAreaOrigin = _restAreaOrigin;
export const currentLocation = _currentLocation;

export const mapEventTypeToPin = (type: EATEventType) => {

    switch(type){
        case EATEventType.Roadwork:
            return constructionPin;
        case EATEventType.Crash:
            return accidentPin;
        case EATEventType.Incident:
            return incidentPin;
        case EATEventType.RegionalEvent:
            return eventPin;
        case EATEventType.RoadCondition:
            return weatherPin;
        case EATEventType.Unknown:
            return incidentPin;
        default:
            return incidentPin;
    }

}

export const mapVersionedMapLayerToPin = (
    layerName: EAlgoLayerType, 
    version: number = 0
) => {

    switch(layerName){
        case EAlgoLayerType["state-facility"]:
            switch(version){
                case 0: 
                    return restAreaClosedPin;
                case 1: 
                    return restAreaPin;
                case 2: 
                    return welcomeCenterClosedPin;
                case 3: 
                default: 
                    return welcomeCenterPin;
            }
        case EAlgoLayerType["message-sign"]:
        default:
            return version === 1 ? signPin : signOffPin;
    }
};

export const mapLayerToPin = (
    layerName: EAlgoLayerType, 
    version: number = 0
) => {

    switch(layerName){
        case EAlgoLayerType.roadwork:
            return constructionPin;
        case EAlgoLayerType.crash:
            return accidentPin;
        case EAlgoLayerType.incident:
            return incidentPin;
        case EAlgoLayerType["regional-event"]:
            return eventPin;
        case EAlgoLayerType["weather-alert"]:
            return weatherPin;
        case EAlgoLayerType["camera-group"]:
            return cameraPin;
        case EAlgoLayerType.ferry:
            return ferryPin;
        case EAlgoLayerType["message-sign"]:
            return mapVersionedMapLayerToPin(layerName, version);
        case EAlgoLayerType["regional-event"]:
            return eventPin;
        case EAlgoLayerType["road-condition"]:
            return weatherPin;
        case EAlgoLayerType["state-facility"]:
            return mapVersionedMapLayerToPin(layerName, version);
        default:
            return incidentPin;
    }
};

export const mapStateToPin = (
    state: string
) => {

    switch(state.replaceAll(" ", "").toLowerCase()){
        case "arkansas":
            return _arkansas;
        case "florida":
            return _florida;
        case "georgia":
            return _georgia;
        case "kentucky":
            return _kentucky;
        case "louisiana":
            return _louisiana;
        case "mississippi":
            return _mississippi;
        case "northcarolina":
            return _northcarolina;
        case "southcarolina":
            return _southcarolina;
        case "tennessee":
            return _tennessee;
        case "texas": 
            return _texas;
        default:
            return _unknown;
    }
};