// libraries
import styled from "styled-components";
import { flexSmart } from "@caps-mobile/styled-lib";
import { whenLargeMax, whenMediumMax, whenSmallMax } from "~/theme";

export type IProps = {
    progress?: string;
    bgColor?: string;
}

export const StyledEventProgress = styled.div<IProps>`
    
    ${whenSmallMax(`
        > :first-child{
            margin: 2px 0;
        }
    `)}
`;

export const CaptionRow = styled.div`
    width: 100%;
    
    ${flexSmart("space-between center")}
`;

export const StartCaption = styled.div`
    ${flexSmart("flex-start flex-start")}
    flex-direction: column;

    padding-top: 2px;
    box-sizing: border-box;
`;

export const EndCaption = styled.div`
    ${flexSmart("flex-start flex-end")}
    flex-direction: column;

    padding-top: 2px;
    box-sizing: border-box;
`;