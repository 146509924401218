import { useEffect } from "react";
import { IZoomToValue } from "~/interfaces";

export const useSetZoomAndCenter = (
    mapAndPlatform: [H.Map | undefined, H.service.Platform | undefined], 
    zoomTarget: IZoomToValue, 
    zoomToggled: boolean = false,
    isMediumMax?: boolean
) => {
    
    useEffect(
        () => {
            let map: H.Map | undefined = mapAndPlatform[0];
            let platform: H.service.Platform | undefined = mapAndPlatform[1];

            if (map && platform){
                //If entire state
                if(zoomTarget.bounds){
                    var bbox = new H.geo.Rect(
                        isMediumMax ? zoomTarget.bounds?.top + 1 : zoomTarget.bounds?.top, 
                        zoomTarget.bounds?.left, 
                        zoomTarget.bounds?.bottom, 
                        zoomTarget.bounds?.right);
                    map.getViewModel().setLookAtData({
                        bounds: bbox
                    });
                }
                else{
                    map.setZoom(zoomTarget.zoom);
                    map.setCenter(zoomTarget.center);
                }
            }

        }, [mapAndPlatform[0], mapAndPlatform[1], zoomTarget, zoomToggled]
    );
};