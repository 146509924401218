import { minuteToMilli } from "@caps-mobile/date-time";

export enum EMapTarget {
    "alabama" = "alabama",
    "auburn" = "auburn", 
    "birmingham" = "birmingham",
    "dothan" = "dothan",
    "florence" = "florence",
    "huntsville" = "huntsville",
    "mobile" = "mobile",
    "montgomery" = "montgomery",
    "tuscaloosa" = "tuscaloosa"
}

export type ICoord = {
    lat: number, 
    lng: number
};

export type ICoords = ICoord[];

export type IBonds = {
    top: number, 
    left: number,
    bottom: number,
    right: number
};

export type IZoomToValue = {
    center: ICoord, 
    zoom: number,
    bounds?: IBonds
};

export type IZoomToValues = {
    [key in EMapTarget]: IZoomToValue
}

export enum ERefreshInterval {
    //"1 second" = secondToMilli(1),        // just for testing
    //"10 seconds" = secondToMilli(10),     // just for testing
    "1 minute" = minuteToMilli(1),
    "5 minutes" = minuteToMilli(5),
    "10 minutes" = minuteToMilli(10),
    "15 minutes" = minuteToMilli(15),
    "30 minutes" = minuteToMilli(30),
}

// represents a decomposed css color value
export type IRgbObject = {
    red: number;
    green: number;
    blue: number;
};

// represents a decomposed css color value, plus alpha
export type IRgbaObject = {
    alpha?: number;
} & IRgbObject;


// ui/data types
export enum EAlgoEventType{

};

export enum EAlgoLayerType {
    "all" = "all",
    "camera-group" = "camera-group", 
    "crash" = "crash", 
    "ferry" = "ferry", 
    "incident" = "incident", 
    "message-sign" = "message-sign",
    "other511" = "other511",
    "regional-event" = "regional-event",
    "road-condition" = "road-condition",
    "roadwork" = "roadwork", 
    "state-facility" = "state-facility",
    "traffic-layer" = "traffic-layer",
    "weather-alert" = "weather-alert"
};

export enum EAlgoApiObjectType{
    "active-weather-alert" = "active-weather-alert",
    "aldot-message" = "aldot-message",
    "alea-alert" = "alea-alert",
    "camera" = "camera",
    "camera-group" = "camera-group",
    "city" = "city",
    "county" = "county",
    "crash" = "crash",
    "state-facility" = "state-facility",
    "ferry" = "ferry",
    "incident" = "incident",
    "message-sign" = "message-sign",
    "regional-event" = "regional-event",
    "road-condition" = "road-condition",
    "other511" = "other511",
    "place" = "place",
    "travel-time" = "travel-time",
    "roadwork" = "roadwork",
    "weather-alert" = "weather-alert",
    "location" = "location",
    "route" = "route"
};

export enum ATCongestionLevel {
    "Unaffected" = "Unaffected",
    "Minor" = "Minor",
    "Moderate" = "Moderate",
    "Major" = "Major"
};

export enum EHereMapScheme{
    "normal.day" = "normal.day",
    "normal.day.grey" = "normal.day.grey",
    "normal.day.transit" = "normal.day.transit",
    "normal.day.custom" = "normal.day.custom",
    "normal.night" = "normal.night",
    "normal.night.grey" = "normal.night.grey",
    "pedestrian.day" = "pedestrian.day",
    "pedestrian.night" = "pedestrian.night",
    "carnav.day.grey" = "carnav.day.grey",
    "normal.day.mobile" = "normal.day.mobile",
    "normal.day.grey.mobile" = "normal.day.grey.mobile",
    "normal.day.transit.mobile" = "normal.day.transit.mobile",
    "normal.night.mobile" = "normal.night.mobile",
    "normal.night.grey.mobile" = "normal.night.grey.mobile",
    "reduced.day" = "reduced.day",
    "terrain.day" = "terrain.day",
    "satellite.day" = "satellite.day",
    "hybrid.day" = "hybrid.day",
    "terrain.day.mobile" = "terrain.day.mobile",
    "hybrid.day.mobile" = "hybrid.day.mobile"
};

export enum EHereMapType{
    "normal" ="normal",
    "satellite" = "satellite", 
    "terrain" = "terrain"
}

export type IGeometry = {
    type: string;
    coordinates: number[];
}

export type IFeatureProperties = {
    featureType: string;
    mapLayer: string;
    data: any;
}

export type IFeature = {
    type: string;
    geometry: IGeometry;
    properties: IFeatureProperties;
}

export type IFeatureCollection = {
    type: string;
    features: IFeature[];
};

export type IMarkerTapObject = {
    type: any;
    data?: any;
};

export type IDocObject = {
    icon?: {
        width?: number; 
        height?: number; 
        src: string
    };
    title: string;
    subtitle?: string;
    url: string;
};

export enum LocationDestinationType{
    "start location" ="start location",
    "end location" = "end location"
}

export type IHistoryItem = {
    isRecentHistoryItem: boolean;
    locationDestinationType: LocationDestinationType;
    name: string;
    address: string;
    geocode: ICoord;
};