export class DateFormatter {

    date: Date;
    formatDelimiter: string;

    constructor(date?: Date){
        this.date = date || new Date();
        this.formatDelimiter = "/";
    }

    setDate(date?: Date){
        this.date = date || new Date();
    }

    getDate(){
        return this.date;
    }

    getTimeString(format?: string): string {
        if (!format)
            format = "H:MM|AM";

        let timeString: string = "";

        let delimiterCount: number = (format.split(":")).length - 1;

        let hours: number = this.date.getHours();
        let minutes: number = this.date.getMinutes();
        let seconds: number = this.date.getSeconds();
        let mili: number = this.date.getMilliseconds();

        let hourString: string = "";
        let minuteString: string = "";
        let secondString: string = "";
        let miliString: string = "";
        let amPmString: string = "";

        // check if AM/PM
        if (format.toLowerCase().includes("am") || format.toLowerCase().includes("pm")){
            amPmString = hours >= 12 ? "PM" : "AM";
            hours = hours % 12;
            hours = (hours !== 0) ? hours : 12;
        }

        hourString = `${hours}`;
        // check for prepended hour
        if (format.toLowerCase().includes("hh") || format.toLowerCase().includes("24")){
            hourString = this.prependZero(hourString, 2 - hourString.length);
        }

        minuteString = `${minutes}`;
        // check for prepended minutes
        if (format.toLowerCase().includes("mm")){
            minuteString = this.prependZero(minuteString, 2 - minuteString.length);
        }

        // check if seconds are desired
        if (delimiterCount > 1){
            secondString = `${seconds}`;
            // check for prepended seconds
            if (format.toLowerCase().includes("ss")){
                secondString = this.prependZero(secondString, 2 - secondString.length);
            }
        }

        // check if mili are desired
        if (delimiterCount > 2){
            miliString = `${mili}`;
            // check for prepended mili (can be up to 3 digits in length)
            let miliCount: number = (format.split("n")).length - 1;
            miliString = this.prependZero(miliString, miliCount - 1);
        }

        timeString =    `${hourString}` + 
                        `:${minuteString}` +
                        `${secondString ? `:${secondString}` : ""}` +
                        `${miliString ? `:${miliString}` : ""}` +
                        `${amPmString ? ` ${amPmString}` : ``}`;

        return timeString;
    }

    getDateString(format?: string): string{

        if (!format)
            format = "MM/DD/YYYY";

        switch(format){
            case "MM/DD/YYYY":
                return this.buildMonthDayYear(format);
            default: 
                return this.buildMonthDayYear(format);
        }
    }

    buildMonthDayYear(format: string): string{

        let fString: string = "";

        this.setFormatDelimiter(format);

        let month: string = `${this.date.getMonth() + 1}`;
        // check if month should be zero-prepended
        if (format.toLowerCase().includes("mm"))
            month = this.prependZero(month, 2 - month.length);

        let date: string = `${this.date.getDate()}`
        // check if date should be zero-prepended
        if (format.toLowerCase().includes("dd"))
            date = this.prependZero(date, 2 - date.length);

        let year: string = `${this.date.getFullYear()}`;
        // check if year should be full or partial
        if (!format.toLocaleLowerCase().includes("yyyy"))
            year = `${this.date.getFullYear()}`.substring(2, 4);

        fString =   `${month}${this.formatDelimiter}${date}${this.formatDelimiter}${year}`;

        return fString;
    }

    getFormatDelimiter(){
        return this.formatDelimiter;
    }

    setFormatDelimiter(format?: string): string{

        if (!format)
            return this.formatDelimiter;

        if (format?.includes("_"))
            this.formatDelimiter = "_";

        if (format?.includes("-"))
            this.formatDelimiter = "-";

        if (format?.includes("/")) {
            this.formatDelimiter = "/";
        }

        return this.formatDelimiter;
    }

    // create a new string with a prepended zero if the given string
    // or number is a value of length 1, otherwise return given value
    prependZero(value: string | number, count: number = 1): string{

        // force value to be a string
        let valueString: string = `${value}`;

        if (count <= 0) return valueString;

        return `0${this.prependZero(value, (count - 1))}`;
    }

    // create a formatted string to display a Date object in the format:
    // January, 1 2022 1:09 AM
    // This can be described as: 
    //  Date:
    //      Month: full-string
    //      DayOfMonth: raw-integer
    //      Year: full-integer
    //  Time: 
    //      Hour: clock-face
    //      Minute: prepend-zero
    //      AMPM: true

    defaultDateString (includeTime: boolean = true): string {
        let dateText = "";

        dateText += this.monthToText(this.date.getMonth());
        dateText += " ";
        dateText += this.date.getUTCDate();
        dateText += ", ";
        dateText += this.date.getFullYear();
        dateText += " ";

        if (includeTime){
            let [hours, AMPM] = this.hoursToAMPM(this.date.getHours());

            dateText += hours;
            dateText += ":";
            dateText += this.prependZero(this.date.getMinutes());
            dateText += " ";
            dateText += AMPM;
        }

        return dateText;
    };

    // convert javascript day integer value to corresponding day string value
    dayToText(day: number): string {
        var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        return days[day];
    };

    // convert javascript month integer value to corresponding month string value
    monthToText(month: number): string {
        var months = ['January', 'February', 'March',
            'April', 'May', 'June', 'July', 'August', 'September',
            'October', 'November', 'December'];
        return months[month];
    };

    // convert 24 hour to 12 hour. returns [hour, am/pm]
    hoursToAMPM(hour: number): [hour: number, AMPM: string] {

        if (hour === 0) return [12, "AM"];

        else if (hour < 12) return [hour, "AM"];

        else if (hour === 12) return [12, "PM"];

        else if (hour > 12) return [hour - 12, "PM"];

        else return [hour, "AM"];

    };

};

export default DateFormatter;