// libraries
import { commonGetAll_api, createAlgoSlice } from "./common";
// types & models
import { 
    UserNetworkManager, IUserNetworkManager,
    IProcessedResponse
 } from "@algo/network-manager/managers/v3";
 // constants
 import { CUR_API_VERSION, CUR_API_ENDPOINTS } from "../../api-endpoint-strings";
 import { isTesting } from "~/constants"
import { getAccessToken } from "~/authentication/oidcConfig";
// test data

declare var __API_URL__: string;
const apiUrl: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).users}`;

// create list slice
export const userSlice = 
    createAlgoSlice("user");

// get handles for the slice's actions
const { 
    begin, success, failure,
    beginProfile, successProfile, failureProfile
} = userSlice.actions;

// handles dispatching a data get by id from either api or test source based on args
export const getById = (sub: string, test: boolean = isTesting, testMode?: string) => {

    return (
        dispatch: any,
        getState: any
    ) => {
        if (test)
            dispatch(getById_test(sub, testMode));
        else 
            dispatch(getById_api(sub));
    }
}

export const getProfile = () => {

    return (
        dispatch: any,
        getState: any
    ) => { 
        
        if (getState().users.loadingProfile) return;

        dispatch(beginProfile());

        let manager: IUserNetworkManager = 
            new UserNetworkManager(apiUrl);

        getAccessToken().then(
            (token: string) => {

                manager.setAccessToken(token);
                manager.getProfile()
                    .then(
                        (response: IProcessedResponse) => {
                            if (response.error)
                                dispatch(failureProfile({ errorMessage: response.error.message}))
                            else 
                                dispatch(successProfile({ data: response.data }))
                        }
                    ).catch(
                        (error: Error) => dispatch(failureProfile({ errorMessage: error.message }))
                    )
            }
        ).catch(
            (err: any) => console.error("Error retrieving user access token.")
        )

    }
}

// retrieves data from api for this data type
export const getById_api = (sub: string) => {

    return (
        dispatch: any,
        getState: any
    ) => {

        dispatch(begin());

        let manager: IUserNetworkManager = 
            new UserNetworkManager(apiUrl);

        getAccessToken().then(
            (token: string) => {

                manager.setAccessToken(token);
                manager.getUserById({sub})
                    .then(
                        (response: IProcessedResponse) => {
                            if (response.error)
                                dispatch(failure({ errorMessage: response.error.message}))
                            else 
                                dispatch(success(response.getReduxObject()))
                        }
                    ).catch(
                        (error: Error) => dispatch(failure({ errorMessage: error.message }))
                    )
            }
        ).catch(
            (err: any) => console.error("Error retrieving user access token.")
        )
    }
    
};

// retrieves test data for this data type
export const getById_test = (sub: string, mode?: string) => {
    
    // return (
    //     dispatch: any,
    //     getState: any
    // ) => {
    //     dispatch(success({data: {
    //         "sub": "17c53274-14da-45bd-a3c2-c15b33eba14e",
    //         "givenName": "Benjimin",
    //         "middleName": "",
    //         "familyName": "Aaron",
    //         "userName": "ben_user_name",
    //         "email": "bkaaron@ua.edu",
    //         "lastLoginDate": "2022-10-13T15:19:16.3972209Z",
    //         "status": 0
    //       }}))
    // }

    return getById_api(sub);
};

// handles dispatching a data get all from either api or test source based on args
export const getAll = (test: boolean = isTesting, testMode?: string): any => {

    return (
        dispatch: any,
        getState: any
    ) => {

        if (getState().profile.loading) return;

        if (test)
            dispatch(getAll_test(testMode));
        else 
            dispatch(getAll_api());
    }
}

// retrieves all data from api for this data type
export const getAll_api = () => {

    return commonGetAll_api(
        new UserNetworkManager(apiUrl), 
        userSlice
    );
    
};

// retrieves test data for this data type
export const getAll_test = (mode?: string) => {

    return (
        dispatch: any,
        getState: any
    ) => {

        dispatch(begin());
        
        setTimeout(
            () => {
                dispatch(success({data: [], errorMessage: null, status: 200}))
            }, 2500
        );
    }
    
};

// exports the slice's reducer ( used in store file to build up master reducer )
export const userReducer = userSlice.reducer;