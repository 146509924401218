// libraries
import styled from "styled-components";
import { flexSmart } from "@caps-mobile/styled-lib";

export const StyledTripPlannerPage = styled.div`
    height: 100%;
    width: 100%;

    position: relative;

    ${flexSmart("center end")}

    background-color: ${props => props.theme.colors["bg-secondary"].rgba};
`;