// libraries
import * as React from "react";
// styles
import * as SC from "./Styled";
// components
import GeneralSection from "./general-section/GeneralSection";
import LoginSection from "./login-section/LoginSection";
import PageTemplate from "../../views/page-template/PageTemplate";
import PermissionsSection from "./permissions-section/PermissionsSection";
// constants
import { REQ_ACCESS_TEXT, URI_REQUEST_ACCESS } from "~/constants";

import SiteNavContext, { isNavLinkSelected } from "~/navigation/SiteNavContext";
import navPanelMap, { getNavMapValue } from "~/navigation/navigation-map";

export type IProps = {
    //
};

export const SettingsPage: React.FC<IProps> = (props) => {

    const {
        //
    } = props;


    const navContext: any = React.useContext(SiteNavContext);

    return (
        <PageTemplate title={"Settings"}>

            <GeneralSection />

            <PermissionsSection />

            <LoginSection />

            <SC.Header>

                <SC.RequestAccessText>
                    { REQ_ACCESS_TEXT }
                </SC.RequestAccessText>
                <SC.RequestAccessLink
                    onClick={ () => {
                        const test: number[] = getNavMapValue("/comments", navPanelMap);
                        navContext.setSelectedSiteLink(test);
                        navContext.setSelectedRoute("/comments");
                    }}
                >
                    {`here.`}
                </SC.RequestAccessLink>

            </SC.Header>

        </PageTemplate>
    );
};

export default SettingsPage;