export const Card = {
    card: `
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        background-color: #FFFFFF;

        border-radius: 4px;
        box-sizing: border-box;
        border: thin solid rgba(0, 0, 0, .15); 
        :hover{
            box-shadow: 0 1px 1px 0 rgb(66 66 66 / 8%), 0 1px 3px 1px rgb(66 66 66 / 16%)
        }`,
    header: {
        header: `
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
        
            padding: 16px 16px 0 16px;
        `,
        iconLeft: `
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-right: 16px;`,
        iconRight: `
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-right: 16px;`,
        text: {
            text: `
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;`,
            title: `
                display: flex;
                align-items: center;
                justify-content: flex-start;
            
                margin-bottom: 4px;
            
                font-size: 1.25rem;
                font-weight: 500;`,
            subtitle: `
                display: flex;
                align-items: center;
                justify-content: flex-start;
                font-size: 1rem;
                font-weight: 400;
                opacity: 0.6;
            `
        }
    },
    body: {
        body: `
            padding: 18px 16px 0px 16px;

            font-size: 1rem;
            font-weight: 400;
            opacity: 0.6;
        `,

    },
    ui: {
        "button-type": "text",
        ui: `
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
        
            padding: 8px 0;
        
            color: #6200ee;
        `,
        "text": `
            display: flex;
            justify-content: center;
            align-items: center;
        
            border-radius: 4px;
        
            height: 24px;
            margin: 0 8px;
            padding: 8px;
            font-size: 14px;
            font-weight: 500;
        
            text-transform: uppercase;
        
            :hover {
                background-color: rgba(98, 0, 238, 0.05);
                cursor: pointer;
            }
        `,
    }
}

export default Card;