// libraries
import * as React from "react";
// styles
import * as SC from "./Styled";
// types & models
import { IATCameraDto, IATVideoItemDto, IATVideoPageDto } from "@algo/network-manager/models/v3";
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from "video.js";
// components
import { VideoJs } from "../local-videoJS/VideoJs";
import CameraInfoOverlay from "../../camera-info-overlay/CameraInfoOverlay";
// constants
const STANDARD_VJS_CONFIG: VideoJsPlayerOptions = {
    autoplay: true,
    controls: false,
};

export type IProps = {
    page?: IATVideoPageDto | null;
    showRouteOverlay?: boolean;
    showCityOverlay?: boolean;
    lockOverlays?: boolean;
    fullscreen?: boolean;
    updatePlayerMap: (key: string, player: VideoJsPlayer) => void;
};

export const VideoGrid: React.FC<IProps> = (props) => {

    const {
        page, showCityOverlay, showRouteOverlay, lockOverlays, fullscreen
    } = props;

    const oneColumn: boolean = page?.items.length && page?.items.length > 1 ? false : true;
    const twoVideos: boolean = page?.items.length === 2 ? true : false;

    if (!page) return null;

    return (
        <SC.VideoGrid oneColumn={oneColumn} fullscreen={fullscreen} twoVideos={twoVideos}>
            { page && page.items.map(
                (item: IATVideoItemDto, mapIndex: number) => {

                    const config = { 
                        ...STANDARD_VJS_CONFIG,
                        poster: item?.camera.imageUrl,
                        sources: [
                            {
                                src: item?.camera.hlsUrl || "",
                                type: `application/x-mpegURL`
                            }
                        ]
                    };

                    const camera: IATCameraDto = item.camera;

                    return (
                        <SC.VideoItem key={Math.random()} fullscreen={fullscreen} twoVideos={twoVideos}>
                            <VideoJs
                                style={{height: "100%", width: "100%"}}
                                rounded={false} playButton={true}
                                config={config} videoClassNames=""
                                videoDimensions={{width: `100%`, height: `100%`}}
                                playerCallback={
                                    (player: VideoJsPlayer) => {
                                        props.updatePlayerMap(`${item.id}${mapIndex}`, player);
                                    }
                                }
                            />

                            <SC.TitleItem lockOverlays={lockOverlays}>
                                <CameraInfoOverlay camera={camera} 
                                    showRouteOverlay={showRouteOverlay} showCityOverlay={showCityOverlay} />
                            </SC.TitleItem>

                        </SC.VideoItem>
                    )
                    }
                )
            }
        </SC.VideoGrid>
    );
};

export default VideoGrid;