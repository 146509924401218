// libraries
import * as React from "react";
// styles
import * as SC from "./Styled";
// types & models
import { EAlgoApiObjectType, ICoord } from "~/interfaces";
// components
import ImgWithDefault from "../../image-with-default/ImgWithDefault";
// resources
import noLocationData from "~/resources/ui/graphics/no-location-data.png";
// constants
import { MAP_IMG_HEIGHT } from "~/constants";
import { useBreakpoint } from "~/library/useBreakpoint";
declare var __API_URL__: string;

export type IProps = {
    coords: ICoord;
    zoom?: number;
    width?: number;
    height?: number;
    id?: number | string;
    type?: EAlgoApiObjectType;
};

export const ExtendedObjectDetails: React.FC<IProps> = (props) => {

    const { 
        coords,
        id, type,
        zoom = 14.5, 
        height = MAP_IMG_HEIGHT, 
        width = 550
    } = props;

    const { lat, lng } = coords;
    const cameraWidth: number = 960;
    const { xs: isXSmallMax } = useBreakpoint();
    const { md: isMedMax } = useBreakpoint();

    // build the mapview api url for the static map image section
    let imgSrc: string = `${__API_URL__}/v3.0/${mapTypeToApiEndpoint(type)}/${id?.toString()}/map@1x.jpg?width=${
        (type === EAlgoApiObjectType.camera || type === EAlgoApiObjectType["camera-group"]) ? 
        cameraWidth : isMedMax ? 350 : width}&height=${height}&zoom=${zoom}`;

    return (
        <SC.StyledMapImageWrap isCamera={(type === EAlgoApiObjectType.camera || type === EAlgoApiObjectType["camera-group"])}>
            <ImgWithDefault src={imgSrc} height={MAP_IMG_HEIGHT} fallbackSrc={noLocationData}/>
        </SC.StyledMapImageWrap>
    );
};

export default ExtendedObjectDetails;

export const mapTypeToApiEndpoint = (type?: EAlgoApiObjectType) => {

    switch(type){
        case EAlgoApiObjectType.camera:
        case EAlgoApiObjectType["camera-group"]:
            return "Cameras";
        case EAlgoApiObjectType["message-sign"]:
            return "MessageSigns";
        case EAlgoApiObjectType["state-facility"]:
            return "Facilities";
        case EAlgoApiObjectType.ferry:
            return "Ferries";
        case EAlgoApiObjectType.crash:
        case EAlgoApiObjectType.incident:
        case EAlgoApiObjectType["regional-event"]:
        case EAlgoApiObjectType["road-condition"]:
        case EAlgoApiObjectType.roadwork:
        default: 
            return "TrafficEvents";
    }
}