// libraries
import styled from "styled-components";
import { flexSmart } from "@caps-mobile/styled-lib";

export const StyledApp = styled.div`
    ${props => props.theme.globals.globals.app }

    height: 100%;
    width: 100%;

    ${props => `
        background-color: ${props.theme.colors["bg-primary"]["rgba"]};
    `}
`;

export const StyledNavPlusContent = styled.div<{isSmallMax?: boolean}>`
    height: 100%;
    width: 100%;

    ${flexSmart("flex-start center")};

    ${props => props.isSmallMax ? `flex-direction: column;` : ``}
`;

export const StyledContent = styled.div<{panelOpen?: boolean, isSmallMax?: boolean}>`
    height: ${props => props.isSmallMax ? `calc(100% - 82px)` : `100%`};
    width: 100%;
    transition: width .5s ease-out;
`;