// libraries
import * as React from "react";
import { useBreakpoint } from "~/library/useBreakpoint";
// styles
import * as SC from "../Styled";
// components
import OverlaySection from "~/components/views/map-overlay-views/OverlaySection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
    faXmark, faRefresh, faCar, faEllipsisVertical, faLocationDot 
} from "@fortawesome/pro-regular-svg-icons";
// hooks & contexts
import LocationSearchOverlayContext from "~/contexts/LocationSearchOverlayContext";
import { useSelector } from "react-redux";
// constants
const START_PH: string = "Select Start Location";
const START_COLOR: string = "#049565";
const END_PH: string = "Select Destination";
const END_COLOR: string = "#FF0000";
const REFRESH_COLOR_GREY: string = "#BDBDBD";
const REFRESH_COLOR_GREEN: string = "#007556";
const XMARK_COLOR: string = "#C7C7CC";

export type IProps = {
    refreshRoutes: (isRefresh: boolean) => void
};

export const StartEndSection: React.FC<IProps> = (props) => {

    const overlayContext: any = React.useContext(LocationSearchOverlayContext);
    const routeStore: any = useSelector( (store: any) => store.route );
    const isLoading: boolean = routeStore.loading;

    const { sm: isSmallMax } = useBreakpoint();

    return (
        <OverlaySection 
            top={20} right={isSmallMax ? 0 : 42}
            styleOverwrite={`height: 39px; pointer-events: none; ${isSmallMax ? '' : '200px;'}`}
            childWrapperStyleOverwrite={`${isSmallMax ? '' : 'align-items: flex-end'};`}
        >

            <SC.StyledStartEndSection isSmallMax={isSmallMax}>

                <SC.IconColumn>
                    <FontAwesomeIcon icon={faCar} color={START_COLOR} />
                    <FontAwesomeIcon icon={faEllipsisVertical} />
                    <FontAwesomeIcon icon={faLocationDot} color={END_COLOR} />
                </SC.IconColumn>

                <SC.LocationColumn>
                    <SC.Location>
                        <SC.LocationText 
                        onClick={
                            (evt) => { 
                                overlayContext.setIsStart(true);
                                overlayContext.setShowOverlay(true);
                                document.getElementById('search')?.focus();
                            }}
                        >
                            {overlayContext.start !== "" ? overlayContext.start : START_PH}
                        </SC.LocationText>

                        {overlayContext.start !== "" &&
                        <SC.IconColumn
                            onClick={() => {
                                if(!isLoading) overlayContext.setNewStartLocation("", undefined, undefined);
                            }}>
                            <FontAwesomeIcon
                                className="fa-fw"
                                size={"sm"}
                                icon={faXmark}
                                color={XMARK_COLOR}
                            />
                        </SC.IconColumn>}
                    </SC.Location>
                    <SC.Divider />
                    <SC.Location>
                        <SC.LocationText
                        onClick={
                            (evt) => { 
                                overlayContext.setIsStart(false);
                                overlayContext.setShowOverlay(true);
                                document.getElementById('search')?.focus();
                            }}
                        >
                            {overlayContext.end !== "" ? overlayContext.end : END_PH}
                        </SC.LocationText>
                        
                        {overlayContext.end !== "" &&
                        <SC.IconColumn
                            onClick={() => {
                                if(!isLoading) overlayContext.setNewEndLocation("", undefined, undefined);
                            }}>
                            <FontAwesomeIcon
                                className="fa-fw"
                                size={"sm"}
                                icon={faXmark}
                                color={XMARK_COLOR}
                            />
                        </SC.IconColumn>}
                    </SC.Location>
                </SC.LocationColumn>

                <SC.RefreshColumn>
                    <FontAwesomeIcon 
                        icon={faRefresh} tabIndex={0} 
                        color={(overlayContext.start !== "" && overlayContext.end !== "" && !isLoading) ? REFRESH_COLOR_GREEN : REFRESH_COLOR_GREY} 
                        onClick={() => { if(!isLoading){ props.refreshRoutes(true) } }} />
                </SC.RefreshColumn>

            </SC.StyledStartEndSection>

        </OverlaySection>
    );
};

export default StartEndSection;