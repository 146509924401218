import { configureStore } from "@reduxjs/toolkit";
import { EAlgoApiObjectType } from "~/interfaces";
import { aldotMessageReducer } from "./algo-api/slices/aldot-messages";
import { aleaAlertReducer } from "./algo-api/slices/alea-alerts";
import { cameraReducer } from "./algo-api/slices/cameras";
import { cameraGroupReducer } from "./algo-api/slices/camera-group";
import { crashReducer } from "./algo-api/slices/crashes";
import { ferryReducer } from "./algo-api/slices/ferries";
import { incidentReducer } from "./algo-api/slices/incidents";
import { messageSignReducer } from "./algo-api/slices/message-signs";
import { regionalEventReducer } from "./algo-api/slices/regional-events";
import { roadworkReducer } from "./algo-api/slices/roadwork";
import { stateFacilityReducer } from "./algo-api/slices/state-facilities";
import { travelerInformationSystemReducer } from "./algo-api/slices/traveler-information-systems";
import { weatherAlertReducer } from "./algo-api/slices/weather-alerts-layer";
import { settingsReducer } from "./settings/settings";
import { userReducer } from "./algo-api/slices/users";
import { mapLayersReducer } from "./map-layers/map-layers";
import { roadConditionReducer } from "./algo-api/slices/road-conditions";
import { travelTimeReducer } from "./algo-api/slices/travel-times";
import { cityReducer } from "./algo-api/slices/cities";
import { countyReducer } from "./algo-api/slices/counties";
import { placeReducer } from "./algo-api/slices/places";
import { activeWeatherAlertReducer } from "./algo-api/slices/active-weather-alerts";
import { trafficEventReducer } from "./algo-api/slices/traffic-events";
import { nearbyCamerasReducer } from "./algo-api/slices/nearby-cameras";
import { weatherAlertsReducer } from "./algo-api/slices/weather-alerts";
import { feedbackReducer } from "./algo-api/slices/feedback";
import { locationReducer } from "./algo-api/slices/locations";
import { routeReducer } from "./algo-api/slices/routes";

export default configureStore({
    reducer: {
        [EAlgoApiObjectType["active-weather-alert"]]: activeWeatherAlertReducer,
        [EAlgoApiObjectType["aldot-message"]]: aldotMessageReducer,
        [EAlgoApiObjectType["alea-alert"]]: aleaAlertReducer,
        [EAlgoApiObjectType.camera]: cameraReducer,
        [EAlgoApiObjectType["camera-group"]]: cameraGroupReducer,
        [EAlgoApiObjectType.city]: cityReducer,
        [EAlgoApiObjectType.county]: countyReducer,
        [EAlgoApiObjectType.crash]: crashReducer,
        "feedback": feedbackReducer,
        [EAlgoApiObjectType.ferry]: ferryReducer,
        [EAlgoApiObjectType.incident]: incidentReducer,
        [EAlgoApiObjectType["location"]]: locationReducer,
        [EAlgoApiObjectType["message-sign"]]: messageSignReducer,
        "nearby-cameras": nearbyCamerasReducer,
        [EAlgoApiObjectType["other511"]]: travelerInformationSystemReducer,
        [EAlgoApiObjectType.place]: placeReducer,
        [EAlgoApiObjectType["regional-event"]]: regionalEventReducer,
        [EAlgoApiObjectType["road-condition"]]: roadConditionReducer,
        [EAlgoApiObjectType.roadwork]: roadworkReducer,
        [EAlgoApiObjectType.route]: routeReducer,
        [EAlgoApiObjectType["state-facility"]]: stateFacilityReducer,
        "traffic-event": trafficEventReducer,
        [EAlgoApiObjectType["travel-time"]]: travelTimeReducer,
        [EAlgoApiObjectType["weather-alert"]]: weatherAlertReducer,
        "weather-alerts": weatherAlertsReducer,
        settings: settingsReducer,
        ["map-layers"]: mapLayersReducer,
        users: userReducer
    }
});

/*  
    if you NEED to store non-serializable objects in the redux store
    you can paste the below code in the configureStore object above
    immediately after reducer's value. 
    note that this is considered naughty and you should know what you are doing 
*/

// middleware: (getDefaultMiddleware) => 
//     getDefaultMiddleware({
//         serializableCheck: false
//     })