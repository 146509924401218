// libraries
import { getAll as getAllAleaAlerts } from "~/store/algo-api/slices/alea-alerts";
// hooks & context
import { useEffect } from "react";
import { useDispatch } from "react-redux";

export const useLoadAleaAlerts = () => {

    const dispatch = useDispatch();

    useEffect(
        () => {
            dispatch(getAllAleaAlerts() as any);
        }, []
    );
}