// libraries
import * as React from "react";
// types & models
import { ISVGProps } from "~/resources/interfaces";

export const CameraGlyph: React.FC<ISVGProps> = (props) => {

    const {
        width, height, viewBox, color
    } = props;

    return (
        <svg 
            width={width || "215"} height={height || "172"} 
            viewBox={ viewBox || "0 0 215 172" } fill="none" 
        >
            <path 
                fillRule="evenodd" clipRule="evenodd" fill={color || "black"}
                d="M36.0691 40.0036C35.3218 37.3273 36.9769 34.5765 39.766 33.8594L167.533 1.00928C170.322 0.292176 173.189 1.88038 173.936 4.55663L197.616 89.3578C198.364 92.0341 196.709 94.7849 193.92 95.502L149.731 106.863C151.681 113.846 149.381 120.893 144.429 125.673L151.61 151.387L214.802 150.899L214.802 171.155L136.021 171.155L124.734 130.737C117.955 128.973 112.291 123.973 110.341 116.991L66.1527 128.352C63.3636 129.069 60.4968 127.481 59.7495 124.805L53.5294 102.53L21.598 129.7L0.80428 55.1275L42.3178 62.3808L36.0691 40.0036Z"
            />
        </svg>
    );
};

export default CameraGlyph;
