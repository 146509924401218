// libraries
import styled from "styled-components";
// constants
import { messageSignFontColor } from "~/constants";

export const StyledMessageSignLine = styled.div<{align?: string, lineIsOn?: boolean}>`
    width: 100%;

    font-family: 'LED Dot-Matrix', sans-serif;
    font-size: 32px;
    line-height: 26px;
    text-align: ${props => props.align ? props.align : "left"};
    color: ${messageSignFontColor};

    opacity: ${props => props.lineIsOn ? "1" : "0"};
`;