// libraries
import styled from "styled-components";
import { flexSmart } from "@caps-mobile/styled-lib";
import { whenSmallMax } from "~/theme";

export const OverlayWrapper = styled.div`
    width: auto;
    height: auto;
`;

export const TopRow = styled.div`
    height: auto;
    width: auto;

    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 2;

    ${flexSmart("flex-start center")}
`;

export const BottomRow = styled.div`
    height: auto;
    width: 100%;

    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 2;

    ${flexSmart("flex-end center")}
`;

export const RoutesOverlay = styled.div`
    padding: 4px 8px;
    box-sizing: border-box;

    border-radius: 8px;

    color: rgba(255, 255, 255, 0.8);

    background-color: rgba(30, 30, 30, 0.75);
`;

export const Primary = styled.div`
    height: auto;
    width: auto;

    font-weight: 600;
    font-size: 20px;
    line-height: 24px;

    ${whenSmallMax(`
        font-size: 14px;
        line-height: 15px;
    `)}
`;

export const Cross = styled.div`
    height: auto;
    width: auto;

    font-weight: 400;
    font-size: 20px;
    line-height: 24px;

    ${whenSmallMax(`
        font-size: 12px;
        line-height: 13px;
    `)}
`;

export const TagOverlay = styled.div`
    height: auto;
    width: auto;

    padding: 4px 8px;

    border-radius: 6px;

    color: rgba(255, 255, 255, 0.8);

    background-color: rgba(30, 30, 30, 0.75);
`;

export const Tag = styled.div``;