// libraries
import { flexSmart } from "@caps-mobile/styled-lib";
import styled from "styled-components";
// constants
import { BORDER_RADIUS } from "~/constants";

export const StyledMapImageWrap = styled.div<{isCamera?: boolean}>`
    height: auto;

    ${props => props.isCamera ? `` : `${flexSmart("center center")}`}

    background-color: rgba(0,0,0,0.05);

    border-radius: ${BORDER_RADIUS}px;

    box-sizing: border-box;

    > img {
        width: 100%;
        border-radius: ${BORDER_RADIUS}px;
    }
`;