// libraries 
import { flexSmart } from "@caps-mobile/styled-lib";
import styled from "styled-components";

export const MouseOverWrapper = styled.div<{showLabel?: boolean}>`
    height: 100px;
    width: auto;

    ${flexSmart("flex-end center")}

    ${props => props.showLabel
        ? `
            cursor: pointer;
            width: auto;
        `
        : ``
    }
`;

export const StyledOverlayButton = styled.button<{styleOverwrite?: string, showLabel?: boolean}>`
    height: 55px;
    width: 55px;

    padding: 12px;
    box-sizing: border-box;

    overflow: hidden;

    ${flexSmart("center center")}

    border-radius: 50%;
    border: none;

    background-color: ${props => props.theme.colors["bg-primary"].rgba};

    font-size: 20px;
    color: ${props => props.theme.colors["primary"].rgba};

    box-shadow: -2px 2px 10px 0px rgba(0, 0, 0, 0.25);

    ${props => props.showLabel
        ? `
            cursor: pointer;
            width: auto;
            border-radius: 30px;
            justify-content: space-between;
            padding: 15px;
        `
        : ``
    }
    
    transition: width .5s .5s, border-radius 0s 0s;

    ${props => props.styleOverwrite ? props.styleOverwrite : ""}
`;

export const Label = styled.div<{showLabel?: boolean}>`
    height: 100%;
    font-size: 16px;
    font-weight: 500;

    ${flexSmart("center center")}

    ${props => props.showLabel 
        ? `
            width: auto;
            height: 100%;
            margin-right: 8px;
            opacity: 1;
            transition: .5s;
            transition-delay: 0s;
        ` 
        : `
            width: 0px;
            height: 0px;
            opacity: 0;
            transition: 0s;
            transition-delay: .0s;
        ` 
    }

`;