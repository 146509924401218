// libraries
import * as React from "react";
import { get } from "~/store/algo-api/slices/locations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
// styles
import * as SC from "./Styled";
import * as PageStyles from "~/components/views/page-template/Styled";
// types & models
import { EAlgoApiObjectType, ICoord, IHistoryItem, LocationDestinationType } from "~/interfaces";
// hooks & context
import { useDispatch, useSelector } from "react-redux";
import LocationSearchOverlayContext from "~/contexts/LocationSearchOverlayContext";
// components
import SearchBar from "../search-bar/SearchBar";
import BackButton from "../back-button/BackButton";

export type IProps = { };

export const LocationSearchOverlay: React.FC<IProps> = (props) => {

    const overlayContext = React.useContext(LocationSearchOverlayContext);
    const [searchText, setSearchText] = React.useState<string>("");
    const [updatedKey, setUpdatedKey] = React.useState<number>(0);

    // watch the location store data
    let dataType: EAlgoApiObjectType = EAlgoApiObjectType["location"];
    const dataStore: any = useSelector((store: any) => store[dataType]);

    const dataObjects: any[] = dataStore.dataFilteredBySelections
        ? [...dataStore.dataFilteredBySelections] : [];

    // history objects
    const localEndHistory = localStorage.endHistoryObjects;
    const localStartHistory = localStorage.startHistoryObjects;
    const [ startHistoryObjects, setStartHistoryObjects ] = React.useState<IHistoryItem[]>(localStartHistory ? JSON.parse(localStartHistory) : []);
    const [ endHistoryObjects, setEndHistoryObjects ] = React.useState<IHistoryItem[]>(localEndHistory ? JSON.parse(localEndHistory) : []);

    // lat and lng of current location
    const [lat, setLat] = React.useState<number>();
    const [lng, setLng] = React.useState<number>();
    navigator.geolocation.getCurrentPosition(
        (position) => {
            setLat(position.coords.latitude);
            setLng(position.coords.longitude);
        }
    );

    // start location onClick
    function startLocationOnlick(geocode: ICoord, name: string, address: string) {
        overlayContext.setNewStartLocation(name, geocode.lat, geocode.lng);

        let newHistoryObjects: IHistoryItem[] = startHistoryObjects;
        if(newHistoryObjects.length >= 5){
            const index = newHistoryObjects.findIndex((x: IHistoryItem) => x.isRecentHistoryItem);
            if(index != -1)
                newHistoryObjects[index].isRecentHistoryItem = false;
        }
        const newHistoryItem: IHistoryItem = {
            isRecentHistoryItem: true, 
            locationDestinationType: LocationDestinationType["end location"], 
            name: name, 
            address: address, 
            geocode: geocode};
        newHistoryObjects.push(newHistoryItem);
        setStartHistoryObjects([...newHistoryObjects]);
        localStorage.setItem("startHistoryObjects", JSON.stringify(newHistoryObjects));
    }

    // end location onClick
    function endLocationOnlick(geocode: ICoord, name: string, address: string) {
        overlayContext.setNewEndLocation(name, geocode.lat, geocode.lng);

        let newHistoryObjects: IHistoryItem[] = endHistoryObjects;
        if(newHistoryObjects.length >= 5){
            const index = newHistoryObjects.findIndex((x: IHistoryItem) => x.isRecentHistoryItem);
            if(index != -1)
                newHistoryObjects[index].isRecentHistoryItem = false;
        }
        const newHistoryItem: IHistoryItem = {
            isRecentHistoryItem: true, 
            locationDestinationType: LocationDestinationType["end location"], 
            name: name, 
            address: address, 
            geocode: geocode};
        newHistoryObjects.push(newHistoryItem);
        setEndHistoryObjects([...newHistoryObjects]);
        localStorage.setItem("endHistoryObjects", JSON.stringify(newHistoryObjects));
    }

    const dispatch = useDispatch();

    return (
        <SC.StyledDataSearchOverlay id={"location-search-overlay"}
            showOverlay={overlayContext.showOverlay}>

            <SC.OverlayContent>

                <PageStyles.StyledPageTemplate>

                    <SC.BackAndTitleWrap>

                        <BackButton callback={() => {overlayContext.setShowOverlay(false); setSearchText(""); setUpdatedKey(Math.random());}} />

                        <SC.Title>
                            {overlayContext.isStart ? <span>Start Location</span> : <span>Destination Location</span>}
                        </SC.Title>

                    </SC.BackAndTitleWrap>

                    <SC.SearchRow>

                        <SC.SearchBarWrap>
                            <SearchBar key={updatedKey} autoSubmit={true} timeout={1000}
                                submitCallback={
                                    (evt: any, searchText: string) => {
                                        const trimmedText: string = searchText.trim();
                                        if(trimmedText && trimmedText.length != 0){
                                            if(lat && lng)
                                                dispatch(get(trimmedText, lat, lng) as any);
                                            else
                                                dispatch(get(trimmedText) as any);
                                        }
                                        setSearchText(trimmedText);
                                    }
                                }
                            />
                        </SC.SearchBarWrap>

                    </SC.SearchRow>

                    {/* My Location */}
                    {searchText === "" && lat && lng &&
                        <SC.MyLocationSection
                            onClick={() => {
                                if (overlayContext.isStart){
                                    overlayContext.setNewStartLocation("My Location", lat, lng);
                                }
                                else{
                                    overlayContext.setNewEndLocation("My Location", lat, lng);
                                }

                                overlayContext.setShowOverlay(false);
                                setSearchText("");
                                setUpdatedKey(Math.random());
                            }}>
                            <SC.Location>
                                <SC.LocationIcon myLocation={true}>
                                    <FontAwesomeIcon
                                        className="fa-fw"
                                        size={"lg"}
                                        icon={regular("location-crosshairs")}
                                    />
                                </SC.LocationIcon>
                                <SC.LocationText>
                                    <SC.MyLocationText>
                                        My Location
                                    </SC.MyLocationText>
                                </SC.LocationText>
                            </SC.Location>
                        </SC.MyLocationSection>
                    }

                    <PageStyles.FilteredContentSection>

                        <SC.Locations>

                            {/* Start Location History */}
                            {searchText === "" && overlayContext.isStart && startHistoryObjects && startHistoryObjects.map(
                                (location: IHistoryItem, index: number) => {
                                    if(location.isRecentHistoryItem){
                                        return (
                                            <SC.Location key={index}>
                                                <SC.LocationIconAndText 
                                                    onClick={() => {
                                                        overlayContext.setNewStartLocation(location.name, location.geocode.lat, location.geocode.lng);
                                                        overlayContext.setShowOverlay(false);
                                                        setSearchText("");
                                                        setUpdatedKey(Math.random());
                                                    }}>
                                                    <SC.LocationIcon locationHistory={true}>
                                                        <FontAwesomeIcon
                                                            className="fa-fw"
                                                            size={"lg"}
                                                            icon={regular("history")}
                                                        />
                                                    </SC.LocationIcon>
                                                    <SC.LocationText>
                                                        <SC.LocationName>{location.name}</SC.LocationName>
                                                        <SC.LocationAddress>{location.address}</SC.LocationAddress>
                                                    </SC.LocationText>
                                                </SC.LocationIconAndText>
                                                <SC.LocationIcon xMark={true} 
                                                    onClick={() => {
                                                        let newHistoryObjects: IHistoryItem[] = startHistoryObjects;
                                                        if(newHistoryObjects.length > 5){
                                                            const recentArray = newHistoryObjects.map((x: IHistoryItem) => x.isRecentHistoryItem);
                                                            const addIndex = recentArray.lastIndexOf(false);
                                                            if(addIndex != -1)
                                                                newHistoryObjects[addIndex].isRecentHistoryItem = true;
                                                        }
                                                        newHistoryObjects.splice(index, 1);
                                                        setStartHistoryObjects([...newHistoryObjects]);
                                                    }}>
                                                    <FontAwesomeIcon
                                                        className="fa-fw"
                                                        size={"sm"}
                                                        icon={regular("circle-xmark")}
                                                    />
                                                </SC.LocationIcon>
                                            </SC.Location>)
                                    }
                                })}

                            {/* End Location History */}
                            {searchText === "" && !overlayContext.isStart && endHistoryObjects && endHistoryObjects.map(
                                (location: IHistoryItem, index: number) => {
                                    if(location.isRecentHistoryItem){
                                        return (
                                            <SC.Location key={index}>
                                                <SC.LocationIconAndText 
                                                    onClick={() => {
                                                        overlayContext.setNewEndLocation(location.name, location.geocode.lat, location.geocode.lng);
                                                        overlayContext.setShowOverlay(false);
                                                        setSearchText("");
                                                        setUpdatedKey(Math.random());
                                                    }}>
                                                    <SC.LocationIcon locationHistory={true}>
                                                        <FontAwesomeIcon
                                                            className="fa-fw"
                                                            size={"lg"}
                                                            icon={regular("history")}
                                                        />
                                                    </SC.LocationIcon>
                                                    <SC.LocationText>
                                                        <SC.LocationName>{location.name}</SC.LocationName>
                                                        <SC.LocationAddress>{location.address}</SC.LocationAddress>
                                                    </SC.LocationText>
                                                </SC.LocationIconAndText>
                                                <SC.LocationIcon xMark={true} 
                                                    onClick={() => {
                                                        let newHistoryObjects: IHistoryItem[] = endHistoryObjects;
                                                        if(newHistoryObjects.length > 5){
                                                            const recentArray = newHistoryObjects.map((x: IHistoryItem) => x.isRecentHistoryItem);
                                                            const addIndex = recentArray.lastIndexOf(false);
                                                            if(addIndex != -1)
                                                                newHistoryObjects[addIndex].isRecentHistoryItem = true;
                                                        }
                                                        newHistoryObjects.splice(index, 1);
                                                        setEndHistoryObjects([...newHistoryObjects]);
                                                    }}>
                                                    <FontAwesomeIcon
                                                        className="fa-fw"
                                                        size={"sm"}
                                                        icon={regular("circle-xmark")}
                                                    />
                                                </SC.LocationIcon>
                                            </SC.Location>)
                                    }
                                })}

                            {/* Location Search Results */}
                            {searchText !== "" && dataObjects && dataObjects.map(
                                (location: any, index: number) => {
                                    const [name, ...addressSections] = location.label.split(',');
                                    const address = addressSections.join(',');

                                    const geocode: ICoord = {lat: location.location.latitude, lng: location.location.longitude};
                                    const history: ICoord[] = overlayContext.isStart ? 
                                        startHistoryObjects.map((x: IHistoryItem) => x.geocode) : 
                                        endHistoryObjects.map((x: IHistoryItem) => x.geocode);
                                    const isHistory: boolean = history.some((x: ICoord) => x.lat == geocode.lat && x.lng == geocode.lng);

                                    return (
                                        <SC.Location key={index}
                                            onClick={() => {
                                                overlayContext.isStart ? startLocationOnlick(geocode, name, address) : endLocationOnlick(geocode, name, address);
                                                overlayContext.setShowOverlay(false);
                                                setSearchText("");
                                                setUpdatedKey(Math.random());
                                            }}>
                                            <SC.LocationIcon locationHistory={isHistory}>
                                                <FontAwesomeIcon
                                                    className="fa-fw"
                                                    size={"lg"}
                                                    icon={isHistory ? regular("history") : regular("location-dot")}
                                                />
                                            </SC.LocationIcon>
                                            <SC.LocationText>
                                                <SC.LocationName>{name}</SC.LocationName>
                                                <SC.LocationAddress>{address}</SC.LocationAddress>
                                            </SC.LocationText>
                                        </SC.Location>)
                                })}

                        </SC.Locations>

                    </PageStyles.FilteredContentSection>

                </PageStyles.StyledPageTemplate>

            </SC.OverlayContent>

        </SC.StyledDataSearchOverlay>
    );

};

export default LocationSearchOverlay;