// libraries
import * as React from "react";
// styles
import * as SC from "./Styled";
// components
import { HereMap } from "@algo/here-maps";
import LocationSearchOverlay from "~/components/views/location-search-overlay/LocationSearchOverlay";
import RouteCamerasOverlay from "~/components/views/route-cameras-overlay/RouteCamerasOverlay";
import { useDispatch, useSelector } from "react-redux";
import RouteSection from "./map-overlay-views/route-section/RouteSection";
import ObjectDetailModal from "~/components/views/modal/object-detail-modal/ObjectDetailModal";
// constants
import { ZOOM_TO_VALUES } from "~/constants";
import StartEndSection from "./map-overlay-views/start-end-section/StartEndSection";
import { useBreakpoint } from "~/library/useBreakpoint";
// types & models
import { EMapTarget, IMarkerTapObject } from "~/interfaces";
import { IATRouteRequest } from "@algo/network-manager/models/v3";
// hooks & context
import { useSetZoomAndCenter } from "./hooks/useSetZoomAndCenter";
import { TrafficEventDataRefresh, RefreshRouteData, useResizeMapOnNavToggle, useWindowResize, useCursorOnFeature } from "./hooks/general-hooks";
import SiteNavContext from "~/navigation/SiteNavContext";
import LocationSearchOverlayContext from '../../../contexts/LocationSearchOverlayContext';
import RouteCamerasOverlayContext from "~/contexts/RouteCamerasOverlayContext";
import { generateRoutes, resetRoutes } from "~/store/algo-api/slices/routes";
import { useAllPolylineGroups } from "./hooks/useAllPolylineGroups";
import DetailModalContext from "~/contexts/DetailModalContext";
import { useAllMarkerGroups } from "./hooks/useAllMarkerGroups";

export type IProps = {
    //
};

export const TripPlannerPage: React.FC<IProps> = (props) => {

    const {
        //
    } = props;

    const [mapAndPlatform, setMapAndPlatform] = React.useState<[H.Map | undefined, H.service.Platform | undefined]>([undefined, undefined]);
    const navContext: any = React.useContext(SiteNavContext);
    const { sm: isSmallMax } = useBreakpoint();
    const { md: isMediumMax } = useBreakpoint();
    const [showCameraOverlay, setShowCameraOverlay] = React.useState<boolean>(false);
    const [showOverlay, setShowOverlay] = React.useState<boolean>(false);
    const [isStart, setIsStart] = React.useState<boolean>(false);
    const [start, setStart] = React.useState<string>("");
    const [end, setEnd] = React.useState<string>("");
    const [startCoord, setStartCoord] = React.useState<[number | undefined, number | undefined]>([undefined, undefined]);
    const [endCoord, setEndCoord] = React.useState<[number | undefined, number | undefined]>([undefined, undefined]);
    const [selectedIndex, setSelectedIndex] = React.useState<number>(0);
    const [routeRequest, setRouteRequest] = React.useState<IATRouteRequest>();

    const routeStore: any = useSelector( (store: any) => store.route );
    const routeData: any[] = routeStore.data?.slice(0, 3)
    ? [...routeStore.data?.slice(0, 3)] : [];

    const dispatch = useDispatch();

    TrafficEventDataRefresh(selectedIndex, routeRequest);

    const routeGroup = useAllPolylineGroups(mapAndPlatform[0], selectedIndex, setSelectedIndex);

    const detailModalContext: any = React.useContext(DetailModalContext);
    const markerTapHandler = (dataObject: IMarkerTapObject) => {
        detailModalContext.setModalContent(
            <ObjectDetailModal 
                object={dataObject} 
                doneCallback={() => detailModalContext.setShowModal(false)} />
        );
        detailModalContext.setShowModal(true);
    }
    
    // apply markers to the HereMap object
    useAllMarkerGroups(mapAndPlatform[0], markerTapHandler);

    const getBBox = (routeData: H.map.Group | null) => {
        if(!routeData) {
            const stateBounds = ZOOM_TO_VALUES[EMapTarget.alabama].bounds;
            if(stateBounds && mapAndPlatform[0]){
                var bbox = new H.geo.Rect(
                    isMediumMax && isSmallMax ? stateBounds.top + 1 : stateBounds.top,
                    stateBounds.left,
                    stateBounds.bottom,
                    stateBounds.right);

                return bbox;
            }
        }
        else{
            const top = routeData?.getBoundingBox().getTop() ? routeData?.getBoundingBox().getTop() : 0;
            const left = routeData?.getBoundingBox().getLeft() ? routeData?.getBoundingBox().getLeft() : 0;
            const bottom = routeData?.getBoundingBox().getBottom() ? routeData?.getBoundingBox().getBottom() : 0;
            const right = routeData?.getBoundingBox().getRight() ? routeData?.getBoundingBox().getRight() : 0;
            
            const topOffset = (bottom - top) * 0.25;
            const bottomOffset = (bottom - top) * 0.5;
            const latOffset = (right - left) * 0.05;

            var bbox = new H.geo.Rect(
                top - topOffset, 
                left -latOffset, 
                bottom + bottomOffset, 
                right + latOffset
            );

            return bbox;
        }
    }

    const boundingBox: H.geo.Rect | undefined = getBBox(routeGroup);

    // resizes map to fit screen and sets zoom when changing screen size
    useWindowResize(mapAndPlatform[0], window.innerWidth, window.innerHeight, boundingBox, isMediumMax && isSmallMax);

    // The map will not automatically resize when the panel is closed.
    // This effect will wait until the animation has finished and then resize
    useResizeMapOnNavToggle(mapAndPlatform[0], navContext.panelOpen);

    useSetZoomAndCenter(mapAndPlatform, boundingBox);

    // causes the cursor to change to pointer when mousing over markers on the map
    useCursorOnFeature(mapAndPlatform[0]);

    const setNewStartLocation = (name: string, lat?: number, lng?: number) => {
        setSelectedIndex(1);
        setStart(name);
        setStartCoord([lat, lng]);

        if(lat && lng && endCoord[0] && endCoord[1]){
            let newRouteRequest: IATRouteRequest = {
                origin: {
                    latitude: lat,
                    longitude: lng
                },
                destination: {
                    latitude: endCoord[0],
                    longitude: endCoord[1]
                },
                units: "Imperial"
            };
    
            setRouteRequest(newRouteRequest);
            dispatch(generateRoutes(newRouteRequest) as any);
        }
        else{
            dispatch(resetRoutes());
        }
        
    };

    const setNewEndLocation = (name: string, lat: number | undefined, lng: number | undefined) => {
        setSelectedIndex(1);
        setEnd(name);
        setEndCoord([lat, lng]);

        if(lat && lng && startCoord[0] && startCoord[1]){
            let newRouteRequest: IATRouteRequest = {
                origin: {
                    latitude: startCoord[0],
                    longitude: startCoord[1]
                },
                destination: {
                    latitude: lat,
                    longitude: lng
                },
                units: "Imperial"
            };

            setRouteRequest(newRouteRequest);
            dispatch(generateRoutes(newRouteRequest) as any);
        }
        else{
            dispatch(resetRoutes());
        }
    };

    const refreshRoutes = (isRefresh: boolean) => {
        if(isRefresh){
            let startLat = startCoord[0];
            let startLng = startCoord[1];
            let endLat = endCoord[0];
            let endLng = endCoord[1];

            if(start == 'My Location'){
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        startLat = position.coords.latitude;
                        startLng = position.coords.longitude;
                    }
                );
            }
            else if(end == 'My Location'){
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        endLat = position.coords.latitude;
                        endLng = position.coords.longitude;
                    }
                );
            }

            setStartCoord([startLat, startLng]);
            setEndCoord([endLat, endLng]);
            setSelectedIndex(1);

            if(startLat && startLng && endLat && endLng){
                let newRouteRequest: IATRouteRequest = {
                    origin: {
                        latitude: startLat,
                        longitude: startLng
                    },
                    destination: {
                        latitude: endLat,
                        longitude: endLng
                    },
                    units: "Metric"
                };

                setRouteRequest(newRouteRequest);
                dispatch(generateRoutes(newRouteRequest) as any);
            }
            else{
                dispatch(resetRoutes());
            }
        }
    };

    // holds information about the trip planner page search overlay
    const locationSearchOverlayValue = {
        showOverlay: showOverlay,
        setShowOverlay: (newVal: boolean) => setShowOverlay(newVal),
        toggleShowOverlay: () => setShowOverlay(!showOverlay),
        isStart: isStart,
        setIsStart: (newVal: boolean) => setIsStart(newVal),
        start: start,
        end: end,
        startLocation: startCoord,
        endLocation: endCoord,
        setNewStartLocation: (name: string, lat: number | undefined, lng: number | undefined) => setNewStartLocation(name, lat, lng),
        setNewEndLocation: (name: string, lat: number | undefined, lng: number | undefined) => setNewEndLocation(name, lat, lng)
    }

    // holds information about the trip planner page camera overlay
    const routeCamerasOverlayValue = {
        showOverlay: showCameraOverlay,
        setShowOverlay: (newVal: boolean) => setShowCameraOverlay(newVal),
        toggleShowOverlay: () => setShowCameraOverlay(!showCameraOverlay),
        routeIndex: selectedIndex
    }

    // remove data from routes slice when user leaves page
    RefreshRouteData();

    return (
        <LocationSearchOverlayContext.Provider value={locationSearchOverlayValue}>
        <RouteCamerasOverlayContext.Provider value={routeCamerasOverlayValue}>
            <SC.StyledTripPlannerPage>

                <HereMap
                    storeMapAndPlatform={ 
                        (map: H.Map, platform: H.service.Platform) => { setMapAndPlatform([map, platform]); } 
                    }
                />

                {/* Contains the full-page location search overlay for finding api data objects */}
                <LocationSearchOverlay />

                {/* Contains the full-page route cameras overlay for displaying route cameras */}
                <RouteCamerasOverlay />

                {/* Contains the search bar for finding start and end locations */}
                <StartEndSection refreshRoutes={(isRefresh: boolean) => refreshRoutes(isRefresh)} />

                <RouteSection routeData={routeData} selectedIndex={selectedIndex} setSelectedIndex={(index: number) => { setSelectedIndex(index); }} />

            </SC.StyledTripPlannerPage>
        </RouteCamerasOverlayContext.Provider>
        </LocationSearchOverlayContext.Provider>

    );
};

export default TripPlannerPage;