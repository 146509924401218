// libraries
import * as React from "react";
// types & models
import { ISVGProps } from "~/resources/interfaces";

export const Crash: React.FC<ISVGProps> = (props) => {

    const {
        width, height, viewBox, color
    } = props;

    return (
        <svg 
            width={width || "300"} height={height || "300"} 
            viewBox={viewBox || "0 0 300 300"} 
            fill="none" xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M18.4907 162.823C12.9654 157.412 12.8734 148.547 18.2851 143.023L139.999 18.782C145.489 13.1784 154.511 13.1784 160.001 18.7821L281.715 143.023C287.127 148.547 287.035 157.412 281.509 162.823L159.795 282.011C154.353 287.34 145.647 287.34 140.205 282.011L18.4907 162.823Z" fill="#C4122E"/>
            <path d="M18.4907 162.823C12.9654 157.412 12.8734 148.547 18.2851 143.023L139.999 18.782C145.489 13.1784 154.511 13.1784 160.001 18.7821L281.715 143.023C287.127 148.547 287.035 157.412 281.509 162.823L159.795 282.011C154.353 287.34 145.647 287.34 140.205 282.011L18.4907 162.823Z" stroke="white" strokeWidth="12"/>
            <path d="M18.4907 162.823C12.9654 157.412 12.8734 148.547 18.2851 143.023L139.999 18.782C145.489 13.1784 154.511 13.1784 160.001 18.7821L281.715 143.023C287.127 148.547 287.035 157.412 281.509 162.823L159.795 282.011C154.353 287.34 145.647 287.34 140.205 282.011L18.4907 162.823Z" stroke="black" strokeWidth="12"/>
            <path d="M113.157 88C114.657 88 116.157 89 116.657 90.75L124.157 109.25L143.157 103.25C144.657 103 146.407 103.5 147.407 104.75C147.657 104.75 147.657 105 147.657 105.25C143.407 107.25 139.657 110.25 136.907 114.5L126.657 129.25C119.907 131.5 114.157 137 112.157 144.5L107.157 163L98.4066 171C97.1566 172.25 95.4066 172.5 93.9066 171.75C92.4066 171 91.6566 169.5 91.6566 167.75L93.1566 148L73.4066 145C71.9066 144.75 70.4066 143.5 70.1566 142C69.6566 140.25 70.4066 138.75 71.6566 137.75L88.1566 126.5L78.4066 109.25C77.4066 107.75 77.6566 106 78.6566 104.75C79.6566 103.5 81.4066 103 82.9066 103.25L101.907 109.25L109.407 90.75C109.907 89 111.407 88 113.157 88ZM165.157 111L198.907 120C207.157 122.25 213.157 129.5 213.657 137.75L215.407 158.75C219.657 162.25 221.907 167.75 220.407 173.5L211.657 206.25C210.407 210.5 205.907 213 201.657 212L197.907 211C193.657 209.75 191.157 205.5 192.157 201.25L194.407 193.5L132.407 176.75L130.407 184.5C129.157 188.75 124.907 191.25 120.657 190.25L116.657 189.25C112.407 188 109.907 183.75 111.157 179.5L119.907 146.5C121.407 141 126.157 137 131.407 136.25L143.407 119C148.157 112 156.907 108.75 165.157 111ZM160.907 126.5C159.407 126 157.657 126.75 156.657 128L148.657 139.75L198.907 153.25L197.907 139C197.657 137.5 196.407 136 194.907 135.5L160.907 126.5ZM136.157 163.25C139.407 164.25 142.657 162.25 143.407 159C144.407 155.75 142.407 152.5 139.157 151.75C135.907 150.75 132.657 152.75 131.907 156C130.907 159.25 132.907 162.5 136.157 163.25ZM201.157 168.25C197.907 167.5 194.657 169.25 193.657 172.5C192.907 175.75 194.657 179 197.907 180C201.157 180.75 204.407 178.75 205.407 175.75C206.157 172.5 204.157 169.25 201.157 168.25Z" fill="white"/>
        </svg>
    );
};

export default Crash;