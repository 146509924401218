// libraries
import { useEffect, useState } from "react";
import { nuonoe } from "@caps-mobile/common-lib";
import { getCoords as getEventCoords } from "~/store/algo-api/slices/crashes";
import { getCoords as getGroupedCameraCoords } from "~/store/algo-api/slices/camera-group";
import { createDefaultMarker, EHereMapMarkerType, IHereMarkerInit } from "@algo/here-maps";
// hooks & context
import { usePrevious } from "@algo/hooks";
// types & models
import { EAlgoLayerType, ICoord, IMarkerTapObject } from "~/interfaces";
import { mapLayerToPin } from "~/resources/icons/algo-traffic-icons/map-pin";
import { useSelector } from "react-redux";

// given a map object type, returns the appropriate map marker size object
export const mapMarkerTypeToSize = (
    type: EAlgoLayerType
): {w: number, h: number} => {

    switch(type){
        case EAlgoLayerType["camera-group"]:
            return {w: 23, h: 23};
        default:
            return {w: 28, h: 28};
    }
}

// given a map object type, returns the appropriate function for parsing its coords
export const mapMarkerTypeToCoordFunction = (
): (item: any) => ICoord | null => {
    return getEventCoords;
}

// given a list of data objects, builds a list of marker objects for the map
export const buildMarkerInitsByType = (
    dataList: any[], 
    getCoords: (object: any) => { lat: number, lng: number} | null,
): IHereMarkerInit[] => {

    if (!dataList) return [];

    let markerInits: IHereMarkerInit[] = [];

    if (!nuonoe(dataList)) return markerInits;

    for (let i = 0; i < dataList.length; i++){

        let nextObject: any = dataList[i];
        let nextCoords: H.geo.Point = getCoords(nextObject) as H.geo.Point;

        let type: EHereMapMarkerType = EHereMapMarkerType.Default;

        let pinResource: any;
        let crossOrigin: boolean = false;

        let resourceVersion: number = 0;

        let typeString: string = dataList[i].type;
        let objectType: EAlgoLayerType = typeString.toLowerCase() as EAlgoLayerType;

        pinResource = mapLayerToPin(objectType);
        
        let icon = {
            iconSrc: pinResource,
            size: mapMarkerTypeToSize(objectType),
            crossOrigin
        };

        markerInits.push({
            type,
            position: nextCoords,
            options: {icon},
            data: {
                type: objectType,
                data: nextObject
            }
        });
    }

    return markerInits;
};

// a hook for building a group of Here Map markers for a given type of map item
// expects a list of data, the EAlgoLayerType of the data, and the checksum(api) value for the data
export const useMarkerGroup = (
    map: H.Map | undefined,
    markerTapCallback?: (markerData: IMarkerTapObject) => void
): H.map.Group | null => {

    // get the respective data and checksum
    const routeStore: any = useSelector( (store: any) => store.route );
    const data: any[] = routeStore.trafficEvents ?? [];
    const checksum: number = routeStore.trafficEventsLastChecksum;

    // track previous checksum
    const prevChecksum = usePrevious(checksum);

    // keep a list of created markers
    const [ markerGroup, setMarkerGroup ] = useState<H.map.Group | null>(null);

    useEffect(
        () => {

            // only execute logic if data exists and 
            // either checksum has changed or no markerGroup exists
            if (
                map &&
                data && 
                (
                    (prevChecksum !== checksum) || 
                    !markerGroup
                )
            ){

                // create a new marker group to hold the new marker objects if necessary
                let newMarkerGroup: H.map.Group = markerGroup || new window.H.map.Group();

                // remove all the current marker group if anything exists
                newMarkerGroup.forEach( (marker) => {marker.dispose();})
                newMarkerGroup.removeAll();

                if(data.length > 0 ){
                    // determine which function to use to extract object coords
                    let getCoords: (item: any) => ICoord | null = 
                        mapMarkerTypeToCoordFunction();

                    // build the list of marker inits
                    let markerInits: IHereMarkerInit[] = 
                        buildMarkerInitsByType(data, getCoords);

                    // create marker object for each init and append them to the new group
                    markerInits.forEach( (markerInit: IHereMarkerInit) => {
                        let marker: H.map.Marker | null = createDefaultMarker(markerInit);
                        marker?.setData(markerInit.data);

                        marker?.addEventListener('tap', (evt: any) => { 
                            markerTapCallback && markerTapCallback(evt.target.getData())
                        });

                        if (marker) {
                            newMarkerGroup.addObject(marker);
                        }
                    });

                    // update the group state
                    setMarkerGroup(newMarkerGroup);
                }
                else setMarkerGroup(null);
            }

            // cleanup logic
            return () => {
                //
            }

        }, [map, data, checksum, markerGroup]
    );

    // return the current marker group state
    return markerGroup;
};