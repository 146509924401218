// libraries
import styled from "styled-components";
import { absoluteArrayBuilder, flexSmart } from "@caps-mobile/styled-lib";

export type IStyledFloatingAlgoLogo = {
    top?: string;
    right?: string;
    bottom?: string;
    left?: string;
    styleOverwrite?: string;
};

export const StyledFloatingAlgoLogo = styled.div<IStyledFloatingAlgoLogo>`

    ${props => `
        height: 60px;
        width: 150px;

        ${absoluteArrayBuilder([
            `${props.top ? props.top : "auto"}`, 
            `${props.right ? props.right : "48px"}`,
            `${props.bottom ? props.bottom : "40px"}`,
            `${props.left ? props.left : "auto"}`
        ])}

        ${flexSmart("start center")}

        z-index: 8;

        color: #fff;

        background-color: transparent;

        ${props.styleOverwrite ? props.styleOverwrite : ""};
    `}
`;

// hacked in white backfill for the algo traffic logo circle portion
export const StyledLogoBackfill = styled.div`

    ${absoluteArrayBuilder([
        "auto", "92px", "2px", "auto"
    ])}
    z-index: -1;

    width: 55px;
    height: 55px;

    border-radius: 50%;

    background-color: #fff;
`;