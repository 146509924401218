// libraries
import { Fragment, useState } from "react";
import { milliToMinute, milliToSecond } from "@caps-mobile/date-time";
import { getEnumStrings } from "@caps-mobile/common-lib";
// enums
import { EMapTarget, ERefreshInterval } from "~/interfaces";
// hooks & contexts
import { updateSettings } from "~/store/settings/settings";
import { useDispatch, useSelector } from "react-redux";
// styles
import { BannerOptionSection } from "~/components/views/banner-option/Styled";
import { Header } from "../Styled";
// components
import BannerOption from "~/components/views/banner-option/BannerOption";
import FullPageModal from "~/components/views/modal/FullPageModal";
import RadioOptionsCard from "~/components/views/radio-options-card/RadioOptionsCard";
// constants
const TARGETS: string[] = getEnumStrings(EMapTarget);
const INTERVALS: string[] = getEnumStrings(ERefreshInterval);

export type IProps = {
    //
};

export const GeneralSection: React.FC<IProps> = (props) => {

    const {
        //
    } = props;

    const dispatch = useDispatch();
    const settingsStore: any = useSelector( (state: any) => state.settings);
    const { autoRefresh, refreshInterval, defaultMapTarget } = settingsStore;

    const [ showMapOptionsModal, setShowMapOptionsModal ] = useState<boolean>(false);
    const [ showIntervalOptionsModal, setShowIntervalOptionsModal ] = useState<boolean>(false);

    const handleMapOptionSelected = (selectedIndex: number) => {
        setShowMapOptionsModal(false);
        if (selectedIndex >= 0 && selectedIndex < TARGETS.length){

            let selectedTarget: string = TARGETS[selectedIndex || 0];
            
            dispatch(updateSettings(
                {defaultMapTarget: EMapTarget[selectedTarget as keyof typeof EMapTarget]}
            ) as any);   
        }
    }

    const handleIntervalOptionSelected = (selectedIndex: number) => {
        setShowIntervalOptionsModal(false);
        if (selectedIndex >= 0 && selectedIndex < INTERVALS.length){
            let selectedInterval: string = INTERVALS[selectedIndex || 0];
            dispatch(updateSettings(
                {refreshInterval: ERefreshInterval[selectedInterval as keyof typeof ERefreshInterval]}
            ) as any);   
        }
    }

    let refreshIntervalMinutes: number = 0;
    let refreshIntervalSeconds: number = 0;

    if (refreshInterval < 60000) {
        refreshIntervalSeconds = milliToSecond(refreshInterval);
    }
    else refreshIntervalMinutes = milliToMinute(refreshInterval);

    let refreshIntervalKey: string = (refreshIntervalMinutes > 0)
        ? `${refreshIntervalMinutes} minute${refreshIntervalMinutes !== 1 ? "s" : ""}`
        : `${refreshIntervalSeconds} second${refreshIntervalSeconds !== 1 ? "s" : ""}`;

    let refreshIntervalDisplay: string = (refreshIntervalMinutes > 0)
        ? `${refreshIntervalMinutes} min`
        : `${refreshIntervalSeconds} sec`

    return (
        <Fragment>

            { (showMapOptionsModal || showIntervalOptionsModal) &&
                <FullPageModal 
                    showCloseButton={true}
                    closeCallback={
                        () => showMapOptionsModal 
                            ? setShowMapOptionsModal(false)
                            : setShowIntervalOptionsModal
                    }
                >
                    <RadioOptionsCard
                        title={
                            showMapOptionsModal 
                                ? "Select startup map region" 
                                : "Select data refresh interval"
                        }
                        optionLabels={
                            showMapOptionsModal
                                ? TARGETS
                                : INTERVALS
                            }
                        currentSelection={
                            showMapOptionsModal
                                ? TARGETS.indexOf(defaultMapTarget)
                                : INTERVALS.indexOf(refreshIntervalKey)
                            }
                        selectCallback={
                            showMapOptionsModal 
                                ? handleMapOptionSelected
                                : handleIntervalOptionSelected
                        }
                        cancelCallback={
                            () => showMapOptionsModal 
                                ? setShowMapOptionsModal(false)
                                : setShowIntervalOptionsModal(false)
                        }
                    />

                </FullPageModal>
            }

            <Header> { `General` } </Header>

            <BannerOptionSection>

                <BannerOption 
                    clickCallback={
                        () => dispatch(updateSettings({autoRefresh: !autoRefresh}) as any)
                    }
                    label={"Auto Refresh"} value={`${autoRefresh ? "On" : "Off"}`} />

                {   autoRefresh &&
                    <BannerOption 
                        clickCallback={() => setShowIntervalOptionsModal(true)}
                        label={"Interval"} value={refreshIntervalDisplay} />
                }
            </BannerOptionSection>

            <BannerOptionSection>
                <BannerOption label={"Default Map"} 
                    clickCallback={() => setShowMapOptionsModal(true)}
                    value={
                        <span style={{textTransform: "capitalize"}}>
                            {defaultMapTarget}
                        </span>
                    } 
                />
            </BannerOptionSection>

        </Fragment>
    );
};

export default GeneralSection;