// libraries
import { flexSmart } from "@caps-mobile/styled-lib";
import styled from "styled-components";
// constants
import { BORDER_RADIUS } from "~/constants";

export const StyledMessageSignPage = styled.div`
    height: 100%;
    width: 100%;
    
    padding: 20px;
    box-sizing: border-box;

    background-color: rgba(0,0,0,1);

    border-radius: ${BORDER_RADIUS}px;
`;

export const LinesWrapper = styled.div<{pageIsOn?: boolean}>`
    height: 100%;
    ${flexSmart("space-evenly center")}
    flex-direction: column;

    display: ${props => props.pageIsOn ? "normal" : "none"};
`;