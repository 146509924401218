// libraries
import * as React from "react";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
// styles
import * as SC from "./Styled";
// components
import { NavPanelSection } from "./nav-panel-section/NavPanelSection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// context
import { ThemeContext } from "~/theme";
import SiteNavContext from "~/navigation/SiteNavContext";
// constants
import { navPanelMap } from "~/navigation/navigation-map";
import { ANON_SECTION } from "~/string-literals";
import Logo from "../../components/views/logo/Logo";
import Accordion from "../../components/views/accordion/Accordion";

export type IProps = {
    //
};

export const NavPanel: React.FC<IProps> = (props) => {

    const theme: any = React.useContext(ThemeContext);
    const navContext: any = React.useContext(SiteNavContext);
    
    return (
        <SC.StyledNavPanel panelOpen={navContext.panelOpen}>

            <SC.LogoRow panelOpen={navContext.panelOpen}>

                {   navContext.panelOpen &&
                    <SC.LogoWrap>
                        <Logo />
                    </SC.LogoWrap>
                }
                
                <SC.ToggleWrapper
                    onClick={() => navContext.togglePanelOpen()}
                >
                    { navContext.panelOpen
                        ? <FontAwesomeIcon
                            icon={solid("x")}
                            color={theme.colors["primary"].rgba}
                        />
                        : <FontAwesomeIcon
                            icon={solid("bars")}
                            color={theme.colors["primary"].rgba} 
                        />
                    }
                </SC.ToggleWrapper>

            </SC.LogoRow>

            {/* Render the title of the nav panel, if it exists */}
            {   navPanelMap.title && 
                <SC.NavTitle panelOpen={navContext.panelOpen}>
                    {navPanelMap.title}
                </SC.NavTitle> 
            }

            <SC.NavContentWrapper panelOpen={navContext.panelOpen}>

                {/* Render each of the nav sections, if any exist */}
                {   navPanelMap && navPanelMap.navSections && navPanelMap.navSections.map(
                        (navSection: any, mapIndex: number) => {
                            return (
                                navContext.panelOpen 
                                    ?   <SC.StyledNavSection 
                                            key={`${navSection.title || ANON_SECTION}${mapIndex}`}
                                            panelOpen={navContext.panelOpen}
                                        >
                                            <Accordion title={navSection.title} reverseChevron={true}>
                                                <NavPanelSection 
                                                    navSection={navSection}
                                                />
                                            </Accordion>
                                        </SC.StyledNavSection>
                                    : 
                                    <SC.StyledNavSection 
                                        key={`${navSection.title || ANON_SECTION}${mapIndex}`}
                                        panelOpen={navContext.panelOpen}
                                    >
                                        { (mapIndex !== 0) && 
                                            <SC.SectionDivider />
                                        }
                                        <NavPanelSection 
                                            navSection={navSection}
                                        />
                                    </SC.StyledNavSection>
                            );
                        }
                    )
                }

            </SC.NavContentWrapper>

        </SC.StyledNavPanel>
    )
};

export default NavPanel;

