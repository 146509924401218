// libraries
import * as React from "react";
// styles
import * as SC from "./Styled";
// component
import Logo from "~/components/views/logo/Logo";
import SiteNavContext, { ISiteNavContext } from "~/navigation/SiteNavContext";

export type IProps = {
    //
};

export const AuthLoadingPage: React.FC<IProps> = (props) => {

    const navContext: ISiteNavContext = React.useContext(SiteNavContext);

    return (
        <SC.StyledAuthLoading>

            <SC.LogoWrap>
                <Logo logoWidth={128} logoHeight={128} textWidth={128} textHeight={128} />
            </SC.LogoWrap>

            <SC.NoticeText>
                {"You are being re-directed by authentication flow... Please wait."}
            </SC.NoticeText>

            <SC.NoticeText>
                {"If you are not successfully re-directed in approximately 10 seconds, try clicking the link below."}
            </SC.NoticeText>

            <SC.ReturnLinkText
                onClick={() => { 
                    navContext.setSelectedSiteLink([0,0]); 
                    navContext.setSelectedRoute("/map");
                }}
            >
                {"Return to ALGO Traffic"}
            </SC.ReturnLinkText>

        </SC.StyledAuthLoading>
    );
};

export default AuthLoadingPage;