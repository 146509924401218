// libraries
import { usePrevious } from "@algo/hooks";
import { rgbaBuilder } from "@caps-mobile/styled-lib";
import {useEffect, useState} from "react";
import { useSelector } from "react-redux";
import { EAlgoApiObjectType, IMarkerTapObject } from "~/interfaces";

export const usePolygonGroup = (
    map: H.Map | undefined,
    markerTapCallback?: (markerData: IMarkerTapObject) => void
) => {

    const dataStore: any = useSelector( (state: any) => state["weather-alert"]);
    const data: any = dataStore.data;
    const checksum: number = dataStore.lastChecksum;

    const prevChecksum = usePrevious(checksum);

    const [polygonGroup, setPolygonGroup] = useState<H.map.Group | null>(null);

    useEffect(
        () => {

            if (
                map &&
                data &&
                data.length > 0 &&
                (
                    (checksum !== prevChecksum) ||
                    !polygonGroup
                )
            ) {

                let newPolygonGroup: H.map.Group = polygonGroup || new H.map.Group();
                // remove all the current group if anything exists
                newPolygonGroup.forEach( (object: H.map.Object) => {object.dispose();})
                newPolygonGroup.removeAll();

                data.map( (feature: any) => {
                    let reader: H.data.geojson.Reader | null = 
                        new H.data.geojson.Reader(
                            undefined,  // optional url string (to a geojson file)
                            {
                                style: function (mapObject) {
                                    if (mapObject instanceof H.map.Polygon) {
                                        mapObject.setStyle({
                                            fillColor: rgbaBuilder(feature.properties.category.fillColor),
                                            strokeColor: rgbaBuilder(feature.properties.category.strokeColor),
                                            lineWidth: 2,
                                        });
                                        mapObject.addEventListener(
                                            "tap", 
                                            () => markerTapCallback && markerTapCallback({ type: EAlgoApiObjectType["weather-alert"], data: feature } )
                                        );
                                        mapObject.setZIndex(feature.properties.zIndex);
                                        newPolygonGroup.addObject(mapObject);
                                    }
                                    else if (mapObject instanceof H.map.Group){
                                        mapObject.getObjects().forEach(
                                            (object: H.map.Object) => {
                                                if (object instanceof H.map.Polygon) {
                                                    object.setStyle({
                                                        fillColor: rgbaBuilder(feature.properties.category.fillColor),
                                                        strokeColor: rgbaBuilder(feature.properties.category.strokeColor),
                                                        lineWidth: 2,
                                                    });
                                                    object.addEventListener(
                                                        "tap", 
                                                        () => () => markerTapCallback && markerTapCallback({ type: EAlgoApiObjectType["weather-alert"], data: feature } )
                                                    );
                                                    object.setZIndex(feature.properties.zIndex);
                                                    newPolygonGroup.addObject(mapObject);
                                                }
                                            }
                                        )
                                    }
                                },
                                // added this bit to stop warnings, if there are BUGS, perhaps consider this property
                                disableLegacyMode: true
                            }
                        );

                    reader.parseData(feature);
                    reader = null;
                });

                setPolygonGroup(newPolygonGroup);
            }

            // cleanup logic
            return () => {
                //
            }
            
        }, [map, data, checksum, polygonGroup, markerTapCallback]
    );

    return polygonGroup;
};