// libraries
import * as React from "react";
// styles
import * as SC from "./Styled";
// components
import ProgressBar from "../progress-bar/ProgressBar";
import DateFormatter from "~/library/DateFormatter";
import { EVENT_DATE_FORMAT, EVENT_TIME_FORMAT } from "~/constants";

export type IProps = {
    start: string;
    end: string;
    showCaptions?: boolean;
    color?: string;
};

export const EventProgress: React.FC<IProps> = (props) => {

    const { color, start, end } = props;

    const startDate: Date = new Date(start);
    const endDate: Date = new Date(end);

    const Formatter: DateFormatter = new DateFormatter(startDate);
    const startDateString: string = Formatter.getDateString(EVENT_DATE_FORMAT);
    const startTimeString: string = Formatter.getTimeString(EVENT_TIME_FORMAT);
    
    Formatter.setDate(endDate);
    const endDateString: string = Formatter.getDateString(EVENT_DATE_FORMAT); 
    const endTimeString: string = Formatter.getTimeString(EVENT_TIME_FORMAT);
    
    return (
        <SC.StyledEventProgress>

            <ProgressBar 
                color={color || "rgba(196, 18, 46, 1)"} 
                progress={`${calculateElapsedPercent(start, end)}`} 
            />

            <SC.CaptionRow>

                <SC.StartCaption>
                    <div>{startDateString}</div>
                    <div>{startTimeString}</div>
                </SC.StartCaption>

                <SC.EndCaption>
                    <div>{endDateString}</div>
                    <div>{endTimeString}</div>
                </SC.EndCaption>

            </SC.CaptionRow>

        </SC.StyledEventProgress>
    )
};

export default EventProgress;

const calculateElapsedPercent = (start: string, end: string): number => {

    let startDate = new Date(start).valueOf();
    let endDate = new Date(end).valueOf();
    let currentDate = new Date().valueOf();

    return Math.round( 
        // time elapsed from start of event until now
        (currentDate - startDate) /
        // divided by the total duration of the event
        (endDate - startDate)
        // by 100 to convert to percent
        * 100
    );
};