import { EATEventType } from "@algo/network-manager/models/v3";
import { getEnumStrings } from "@caps-mobile/common-lib";
import { VideoJsPlayerOptions } from "video.js";
import { 
    EAlgoLayerType, EHereMapScheme, EHereMapType, 
    ICoord, IZoomToValue, IZoomToValues } from "./interfaces";
import { IMapLayers } from "./store/map-layers/map-layers";

// configuration and testing
export const isTesting: boolean = false;

export const STANDARD_VJS_CONFIG: VideoJsPlayerOptions = {
    autoplay: false,
    controls: true,
};

export const searchTimeout: number = 500;

//Google Analytics
export const TRACKING_ID = "UA-46208866-2";

// strings
export const testString: string = `
    Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris 
    nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in 
    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla 
    pariatur. Excepteur sint occaecat cupidatat non proident, 
    sunt in culpa qui officia deserunt mollit anim id est laborum.
`;
export const REQ_ACCESS_TEXT: string = "Transportation and emergency managers may request privileged access to ALGO Traffic by making a request ";

// uris
export const URI_REQUEST_ACCESS: string = "https://algotraffic.com";

// [ORDERED] the names in this list are in first come, first serve priority order
export const OBJ_ID_QUERY_NAMES: string[] = ["eventId", "cameraId", "weatherAlertId", "facilityId", "messageSignId", "ferryId"];
// [ORDERED] the types in this list are ordered, corresponding to their respective "id names" in OBJ_ID_QUERY_NAMES
export const OBJ_QUERY_TYPE: string[] = ["traffic-event", "camera", "weather-alert", "state-facility", "message-sign", "ferry"];

// geographic

// 1 degree latitude ~= 60miles
// so 1 / 60 degrees ~= 1 mile
// so 1 / 60 * 10 or 10 / 60 ~= 10 miles
// so 0.17 ~= 10 miles
export const tenMileLat: number = 0.16667;

// general zoom level standards for geographic framing
export const ZOOM_STATE: number = 8;
export const ZOOM_CITY: number = 11;
export const ZOOM_DETAIL: number = 12;

// default map zoom when no other factors are at play
export const D_MAP_ZOOM: number = ZOOM_STATE;

export const D_MAP_CENTER: ICoord = {
    lat: 32.6,
    lng: -86.315
};

export const D_MAP_TARGET: IZoomToValue = {
    center: D_MAP_CENTER,
    zoom: D_MAP_ZOOM
}

// standardized map settings for "zoom to" locations
export const ZOOM_TO_VALUES: IZoomToValues = {
    "alabama": {
        center: {
            lat: 32.82,
            lng: -86.315
        },
        zoom: ZOOM_STATE,
        bounds: {
            top: 35.45,
            left: -88.74,
            bottom: 29.82,
            right:-84.71
        }
    },
    "auburn": {
        center: {
            lat: 32.608751,
            lng: -85.481730
        },
        zoom: ZOOM_CITY
    },
    "birmingham": {
        center: {
            lat: 33.5207,
            lng: -86.8025
        },
        zoom: ZOOM_CITY
    },
    "dothan": {
        center: {
            lat: 31.223231,
            lng: -85.390489
        },
        zoom: ZOOM_CITY
    },
    "florence": {
        center: {
            lat: 34.800648,
            lng: -87.676332
        },
        zoom: ZOOM_CITY
    },
    "huntsville": {
        center: {
            lat: 34.730135,
            lng: -86.585981
        },
        zoom: ZOOM_CITY
    },
    "mobile": {
        center: {
            lat: 30.6954,
            lng: -88.0399
        },
        zoom: ZOOM_CITY
    },
    "montgomery": {
        center: {
            lat: 32.3668,
            lng: -86.3
        },
        zoom: ZOOM_CITY
    },
    "tuscaloosa": {
        center: {
            lat: 33.208639,
            lng: -87.569035
        },
        zoom: ZOOM_CITY
    }

}

//export const STREAM_TIMEOUT: number = 62;
export const PAGE_DURATION: number = isTesting ? 5 : 60;

export const SHOW_COUNT: number = 10;
export const SS_WIDTH: number = 353;
export const SS_HEIGHT: number = 198;

export const MAP_IMG_WIDTH: number = 533;
export const MAP_IMG_HEIGHT: number = 290;

// formatting
export const EVENT_DATE_FORMAT: string = "mm/dd/yyyy";
export const EVENT_TIME_FORMAT: string = "h:mm|AM";

// colors
export const floating_date_time_bg: string = "rgba(30,30,30, 0.75)";

export const algo_green_text: string = "rgba(0, 117, 86, 1)";
export const algo_green_text_light: string = "rgba(15, 93, 64, 1)";
export const algo_green_text_dark: string = "rgba(1, 181, 97, 1)";

export const alerts_text: string = "rgba(255,255,255,0.9)";

export const blue_dark: string = "rgba(49, 117, 194, 1)";
export const blue_light: string = "rgba(21, 81, 150, 1)";
export const blue_ticker: string = "rgba(34, 167, 240, 1)";

export const green_dark: string = "rgba(1, 181, 97, 1)";
export const green_light: string = "rgba(4, 149, 101, 1)";
export const green_ticker: string = "rgba(4, 149, 101, 1)";

export const lane_open: string = "rgba(20, 219, 139, 1)";
export const lane_closed: string = "rgba(240, 73, 73, 1)";
export const lane_median: string = "rgba(255, 210, 79, 1)";

export const unselected_route: string = "rgba(169,169,169,1)";
export const selected_route: string = "rgba(0, 117, 86, 1)";

export const messageSignFontColor: string = "rgba(255, 149, 0, 1)";

export const signColors: {[key in EATEventType]: string} = {
    [EATEventType.Crash]: "rgba(196, 18, 46, 1)",
    [EATEventType.Facility]: "rgba(21, 81, 150, 1)",
    [EATEventType.Incident]: "rgba(252, 209, 22, 1)",
    [EATEventType.RegionalEvent]: "rgba(156, 90, 52, 1)",
    [EATEventType.RoadCondition]: "rgba(252, 209, 22, 1)",
    [EATEventType.Roadwork]: "rgba(244, 145, 29, 1)",
    [EATEventType.Unknown]: "rgba(252, 209, 22, 1)"
};

export const layerColors: {[key: string]: string} = {
    "all": "rgba(117, 117, 117, 1)",
    "crash": "rgba(196, 18, 46, 1)",
    "incident": "rgba(252, 209, 22, 1)",
    "road-condition": "rgba(252, 209, 22, 1)",
    "weather-alert": "#22A7F0",
    "roadwork": "rgba(244, 145, 29, 1)",
    "message-sign": "black",
    "other511": "rgba(21, 81, 150, 1)",
    "regional-event": "rgba(156, 90, 52, 1)",
    "camera": "rgba(226, 214, 181, 1)",
    "camera-group": "rgba(226, 214, 181, 1)",
    "state-facility": "rgba(21, 81, 150, 1)",
    "traffic-layer": "rgba(46, 204, 113, 1)",
    "traveler-information-system": "rgba(21, 81, 150, 1)",
    "ferry": "#03705F",
}

export const LAYER_STRINGS: string[] = getEnumStrings(EAlgoLayerType);

export const DEFAULT_MAP_LAYERS: IMapLayers = {
    "all": false,
    "crash": true, 
    "incident": false, 
    "road-condition": true,
    "weather-alert": true,
    "roadwork": true,
    "message-sign": false,
    "regional-event": false,
    "camera-group": false, 
    "state-facility": true,
    "traffic-layer": true,
    "other511": true,
    "ferry": false, 
};

export const colors_dark: { [key: string]: string } = {
    "blue_dark": blue_dark,
    "green_dark": green_dark,
    "blue_ticker": blue_ticker,
    "green_ticker": green_ticker,
    "lane_open": lane_open,
    "lane_closed": lane_closed,
    "alerts_text": alerts_text,
    "algo_green_text": algo_green_text_dark,
    "floating_date_time_bg": floating_date_time_bg
};

export const colors_light: { [key: string]: string } = {
    "green_light": green_light,
    "blue_light": blue_light,
    "blue_ticker": blue_ticker,
    "green_ticker": green_ticker,
    "lane_open": lane_open,
    "lane_closed": lane_closed,
    "alerts_text": alerts_text,
    "algo_green_text": algo_green_text_light,
    "floating_date_time_bg": floating_date_time_bg
};

export const aleaAlertDark: string = blue_dark;
export const aleaAlertLight: string = blue_light;

export const aldotMessageDark: string = green_dark;
export const aldotMessageLight: string = green_light;

// box shadow
export const BOX_SHADOW: string = "-2px 2px 10px rgba(0, 0, 0, 0.25);";
export const BOX_SHADOW_HOVER: string = `-2px 2px 10px rgba(0,0,0,0.45);`;

// HERE MAPS
export const darkScheme: string = "normal.night";
export const lightScheme: string = "normal.day.grey";

// style-values
export const CARD_PADDING: number = 20;
export const BORDER_RADIUS: number = 12;

// sizing
export const iconXXLarge = {width: 80, height: 80};
export const iconXLarge = {width: 64, height: 64};
export const iconLarge = {width: 54, height: 54};
export const iconLocation = {width: 50, height: 50};
export const iconMedium = {width: 48, height: 48};
export const iconSmall = {width: 36, height: 36};
export const iconXSmall = {width: 28, height: 28};
export const pin = {width: 16, height: 16};

export const locationIconSize = iconLarge;
export const SHIELD_WIDTHS: number[] = [54, 54, 64, 80, 82];

export const tickerHeight: number = 21;

export const NAV_WIDTH_OPEN: number = 300;
export const NAV_WIDTH_MINI: number = 90;

// navigation & auth
export const noNavRoutes = ["/callback", "/silent_renew"];

export const DEFAULT_MAP_SCHEME: EHereMapScheme = EHereMapScheme["normal.day"];
export const DEFAULT_MAP_TYPE: EHereMapType = EHereMapType.normal;

export const NORMAL_MAP_SCHEME: EHereMapScheme = EHereMapScheme["normal.day"];
export const SATELLITE_MAP_SCHEME: EHereMapScheme = EHereMapScheme["satellite.day"];
export const TERRAIN_MAP_SCHEME: EHereMapScheme = EHereMapScheme["terrain.day"];