import React from "react"
// resources
import currentLocation from "~/resources/icons/algo-traffic-icons/map-pin/ic_current_location_pin.png";

export const useLocationMarker = (
    map: H.Map | undefined,
) => {

    React.useEffect(
        () => {
            
            if (map)
            {
                
                if (navigator.geolocation){
                    
                    navigator.geolocation.getCurrentPosition(
                        (position) => {
                            // Add a marker
                            let domMarker = new H.map.DomMarker(
                                { lat: position.coords.latitude, lng: position.coords.longitude },
                                {
                                    icon: new H.map.DomIcon(`
                                        <div>
                                            <style>
                                                .ripple {
                                                    width: 16px;
                                                    height: 16px; 
                                                    position: absolute; 
                                                    top: 0; 
                                                    left: 0;
                                                    animation: ripple-animation 1.5s infinite;
                                                }

                                                @keyframes ripple-animation {
                                                    0% {
                                                        transform: scale(1);
                                                    }
                                                    100% {
                                                        transform: scale(1.25);
                                                    }
                                                }
                                            </style>
                                            <img class="ripple" src="${currentLocation}">
                                        </div>
                                    `)
                                }
                            );
            
                            // let marker: H.map.Marker | null = createDefaultMarker(markerInit);
                            domMarker && map.addObject(domMarker);

                        }
                    );
                }
            }

            // cleanup logic
            return () => {
                //
            }

        }, [map]
    );
};