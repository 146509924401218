// libraries
import { flexSmart } from "@caps-mobile/styled-lib";
import styled from "styled-components";
import { BORDER_RADIUS, CARD_PADDING } from "~/constants";

export const StyledLegend = styled.div`
    width: 100%;
    padding: ${CARD_PADDING}px;
    margin-bottom: 10px;
    box-sizing: border-box;

    border-radius: ${BORDER_RADIUS}px;

    background-color: ${props => props.theme.colors["bg-primary"].rgba};

    box-shadow: -2px 2px 10px rgba(0, 0, 0, 0.25);
`;

export const Title = styled.div<{isMediumMax?: boolean}>`

    ${props => props.isMediumMax ?
        `font-size: 16px;
        line-height: 15px;
        font-weight: 600;

        padding-bottom: 15px;
        box-sizing: border-box;`
        : 
        `font-size: 16px;
        line-height: 19px;
        font-weight: 600;

        padding-bottom: 10px;
        box-sizing: border-box;`  
    }
    
`;

export const LegendItems = styled.div<{isMediumMax?: boolean}>`

    font-size: ${props => props.isMediumMax ? '14px' : 'auto'};

    & > :not(:last-child) {
        margin-bottom: 10px;
        box-sizing: border-box;
    }
`;

export const TrafficLegendItems = styled.div<{isMediumMax?: boolean}>`

    font-size: ${props => props.isMediumMax ? '14px' : 'auto'};

    display: flex;

    & > :not(:last-child) {
        padding-bottom: 10px;
        box-sizing: border-box;
    }
`;

export const ColorBox = styled.div<{fillColor?: string, strokeColor?: string}>`
    width: 150px;
    height: 15px;
    background-color: ${props => props.fillColor};
`;

export const ColorBoxStart = styled.div<{fillColor?: string, strokeColor?: string}>`
    width: 150px;
    height: 15px;
    margin-left: 5px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: ${props => props.fillColor};
`;

export const ColorBoxEnd = styled.div<{fillColor?: string, strokeColor?: string}>`
    width: 150px;
    height: 15px;
    margin-right: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: ${props => props.fillColor};
`;

export const Icon = styled.div`
    ${flexSmart("start center")}
    gap: 10px;
`;