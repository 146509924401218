// libraries
import styled from "styled-components";
import { flexSmart } from "@caps-mobile/styled-lib";

export const StyledTwitterPage = styled.div`
    height: 100%;
    width: 100%;

    ${flexSmart("center center")}

    background-color: ${props => props.theme.colors["primary"].rgba};

    font-size: 64px;
    color: ${props => props.theme.colors["text-primary"].rgba};
`;

export const Header = styled.div`
    font-weight: 600;
    font-size: 20px;
    padding-bottom: 20px;
`;