export const Message = {
    default: `
        width: 100%;
        height: 100%;

        display: flex;
        align-items: center;
        justify-content: flex-start;
        background-color: rgba(224, 243, 239, 1);
    `,
}

export default Message;