// libraries
import { flexSmart } from "@caps-mobile/styled-lib";
import styled from "styled-components";
import { BORDER_RADIUS, CARD_PADDING } from "~/constants";
import { whenSmallMax } from "~/theme";

export const StyledRadioOptionsCard = styled.div<{styleOverwrite?: string}>`
    positon: relative;
    height: auto;
    width: auto;

    min-width: 25%;

    padding: ${CARD_PADDING}px;
    box-sizing: border-box;

    ${flexSmart("flex-start flex-start")}
    flex-direction: column;

    border-radius: ${BORDER_RADIUS}px;

    background-color: ${props => props.theme.colors["bg-primary"].rgba};

    ${whenSmallMax(`
        min-width: 100%;
        height: 100%;
        border-radius: 0;
    `)}

    ${props => props.styleOverwrite ? props.styleOverwrite : ""};
`;

export const Header = styled.div`
    font-size: 24px;
    font-weight: 600;

    padding-bottom: 10px;
    box-sizing: border-box;
`;

export const Cancel = styled.div`
    box-sizing: boder-box;

    color: ${props => props.theme.colors["primary"].rgba};
    font-weight: 700;
    font-size: 17px;
    line-height: 147%;
    
    :hover{
        cursor: pointer;
    }
`

export const HeaderContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
`;

export const OptionRow = styled.div`
    width: 100%;

    padding: 10px 0;
    box-sizing: border-box;

    ${flexSmart("space-between center")}
    flex-direction: row;

    :hover{
        cursor: pointer;
    }
`;

export const RadioLabel = styled.div`
    text-transform: capitalize;
`;

