// libraries
import * as React from "react";
import { nuon } from "@caps-mobile/common-lib";
// types & models
import { IATCamera, IATCameraDto } from "@algo/network-manager/models/v3";
// styles
import * as SC from "./Styled";
// components
import ImgWithDefault from "../image-with-default/ImgWithDefault";
import CameraInfoOverlay from "../camera-info-overlay/CameraInfoOverlay";
// resources
import noPreviewImg from "~/resources/ui/graphics/no-camera-preview-url-provided.png";
import playBtn from "~/resources/ui/graphics/play-button.png";
import { useSelector } from "react-redux";

export type IProps = {
    camera: IATCamera | IATCameraDto;
    height?: string;
    width?: string;
    showPlayButton?: boolean;
    showCityOverlay?: boolean;
    clickCallback?: (cameraId: number) => void;
};

export const Snapshot: React.FC<IProps> = (props) => {

    let {  
        height = "100%", width = "100%", 
        camera, showPlayButton, showCityOverlay, clickCallback 
    } = props;
    
    const imageUrl = camera.imageUrl || noPreviewImg;
    const hasHLSPlaybackURL = camera.hlsUrl ? true : false;

    const hasClickCallback: boolean = nuon(clickCallback);

    return (
        <SC.SnapshotWrapper isClickable={hasClickCallback && hasHLSPlaybackURL}
            onClick={() => clickCallback && hasHLSPlaybackURL && clickCallback(camera.id)}
        >

            <ImgWithDefault 
                src={imageUrl} fallbackSrc={noPreviewImg} 
                width={width} height={height} />

            <CameraInfoOverlay camera={camera} 
                showRouteOverlay={true} showCityOverlay={showCityOverlay} />

            {   showPlayButton && hasHLSPlaybackURL &&
                <SC.FloatingPlayBtn>
                    <ImgWithDefault src={playBtn} width={52} height={52} />
                </SC.FloatingPlayBtn>
            }

        </SC.SnapshotWrapper>
    )
};

export default Snapshot;