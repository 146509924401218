// libraries
import { flexSmart } from "@caps-mobile/styled-lib";
import styled from "styled-components";

export const StyledLegendItem = styled.div`
    ${flexSmart("flex-start center")}
`;

export const ColorBox = styled.div<{fillColor?: string, strokeColor?: string}>`
    width: 22px;
    height: 22px;

    border-radius: 5px;

    border: thin solid ${props => props.strokeColor ? props.strokeColor : "rgba(117, 117, 117, .75)"};
    background-color: ${props => props.fillColor ? props.fillColor : "rgba(117, 117, 117, .25)"};
`;

export const Name = styled.div`
    padding-left: 10px;
    box-sizing: border-box;
`;