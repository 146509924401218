// libraries 
import * as React from "react";
// hooks & context
import { useNavTrigger } from "./nav-trigger";
// navigation
import { Route, Routes } from "react-router-dom";
// components-auth-flow
import SilentRenew from "~/authentication/silent-renew/SilentRenew";
import SessionEnded from "~/authentication/session-ended/SessionEnded";
// components-pages
import AboutPage from "~/components/pages/about/AboutPage";
import CamerasPage from "~/components/pages/cameras/Cameras";
import CommentsPage from "~/components/pages/comments/CommentsPage";
import DocumentsPage from "~/components/pages/documents/DocumentsPage";
import ExternalLinksPage from "~/components/pages/external-links/ExternalLinksPage";
import LawEnforcementAlertsPage from "~/components/pages/law-enforcement-alerts/LawEnforcementAlertsPage";
import MapPage from "~/components/pages/map/MapPage";
import SettingsPage from "~/components/pages/settings/SettingsPage";
import TrafficReportsPage from "~/components/pages/traffic-reports/TrafficReportsPage";
import TravelTimesPage from "~/components/pages/travel-times/TravelTimesPage";
import TripPlannerPage from "~/components/pages/trip-planner/TripPlanner";
import SocialMediaPage from "~/components/pages/social-media/SocialMediaPage";
import PrivacyPolicyPage from "~/components/pages/privacy-policy/PrivacyPolicy";
import TermsOfUsePage from "~/components/pages/terms-of-use/TermsOfUse";
import NotFoundPage from "~/components/pages/not-found/NotFound";
import AccessDeniedPage from "~/components/pages/access-denied/AccessDenied";
import More from "~/components/pages/more/More";
import LegendPage from "~/components/pages/legend/LegendPage";

export type IProps = {
    //
};

export const RoutesWrapper: React.FC<IProps> = (props) => {

    // listens for changes to nav context and updates the current route accordingly
    useNavTrigger();

    return (
        <Routes>
            <Route path={"/callback"} element={null} />
            <Route path={'/silent_renew'} element={<SilentRenew />} />
            <Route path={'/session_ended'} element={<SessionEnded />} />
            <Route path={'/access_denied'} element={<AccessDeniedPage />} />
            <Route path={"/map"} element={<MapPage />} />
            <Route path={"/cameras"} element={<CamerasPage />} />
            <Route path={"/comments"} element={<CommentsPage />} />
            <Route path={"/trip-planner"} element={<TripPlannerPage />} />
            <Route path={"/settings"} element={<SettingsPage />} />
            <Route path={"/travel-times"} element={<TravelTimesPage />} />
            <Route path={"/traffic-reports"} element={<TrafficReportsPage />} />
            <Route path={"/documents"} element={<DocumentsPage />} />
            <Route path={"/external-links"} element={<ExternalLinksPage />} />
            <Route path={"/social-media"} element={<SocialMediaPage />} />
            <Route path={"/law-enforcement-alerts"} element={<LawEnforcementAlertsPage />} />
            <Route path={"/about"} element={<AboutPage />} />
            <Route path={"/privacy-policy"} element={<PrivacyPolicyPage />} />
            <Route path={"/terms-of-use"} element={<TermsOfUsePage />} />
            <Route path={"/legend"} element={<LegendPage />} />
            <Route path={"/more"} element={<More />} />
            <Route path={"/"} element={<MapPage />} />
            <Route path={"*"} element={<NotFoundPage />} />
        </Routes>
    );
};

export default RoutesWrapper;