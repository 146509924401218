// libraries
import { useEffect, useRef } from "react";
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from "video.js";

export const useInitializePlayer = (
    d_config: VideoJsPlayerOptions,
    playerCallback?: (player: VideoJsPlayer) => void
): [VideoJsPlayer | undefined, React.RefObject<HTMLVideoElement>] => {

    const videoRef = useRef<HTMLVideoElement>(null);
    const player = useRef<videojs.Player>();

    useEffect(() => {

        if ((!player.current || player.current.isDisposed()) && videoRef.current) {
            player.current = videojs(videoRef.current, d_config, () => { });
            playerCallback && playerCallback(player.current);
        }

        return () => {
            if (player.current && !player.current.isDisposed()) {
                player.current.dispose();
            }
        };
    }, [d_config]);

    return [player.current, videoRef];
};
