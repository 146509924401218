// libraries
import { createAlgoSlice } from "./common";
import { nuon } from "@caps-mobile/common-lib";
// types & models
import { CameraNetworkManager, IProcessedResponse, IStoreProcessedResponse } from "@algo/network-manager/managers/v3";
import { EAlgoApiObjectType, ICoord, IFeature, IGeometry } from "~/interfaces";
// constants
import { CUR_API_VERSION, CUR_API_ENDPOINTS } from "../../api-endpoint-strings";
import { isTesting } from "~/constants"
// test data
import T_DATA_GEOJSON from "~/store/algo-api/test-data/cameras/all-cameras-grouped-geojson-4-6-23.json";
import T_DATA_GEOJSON_PROD from "../test-data/cameras/v3-cameras-layer-20230501.json";
import { buildLastResponse } from "~/store/library";
import { 
    ATCamera, IATCamera, IATCameraDto, IATCameraGroupDto, 
    IATVideoBoardDto, IATVideoItemDto, IATVideoPageDto 
} from "@algo/network-manager/models/v3";
import { getAccessToken } from "~/authentication/oidcConfig";

declare var __API_URL__: string;
const apiUrl: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).cameras}`;

export type ICameraGroupWithCoord = {
    coord: ICoord;
} & IATVideoBoardDto;

// create list slice
export const cameraGroupSlice = 
    createAlgoSlice(EAlgoApiObjectType["camera-group"]);

// get handles for the slice's actions
const { 
    beginInit, successInit, failureInit,
    successGetCameras, beginGeojson, 
    successGeojson, failureGeojson
} = cameraGroupSlice.actions;

export const getCameras = (group: IATCameraGroupDto, mode?: string, test: boolean = isTesting) => {
    
    return (
        dispatch: any,
        getState: any
    ) => {

        let cameraList: IATCameraDto[] = [];
        cameraList = getAllButFirstCameraFromGroup(group);

        let id: number = getFirstCameraFromGroup(group).id;
        setTimeout(
            () => {
                dispatch(successGetCameras(
                    { 
                        id, 
                        data: cameraList
                    }
                ));
            }, 2000
        )
    }
    
};

// handles dispatching a data get all from either api or test source based on args
export const getAllGrouped = (mode?: string, test: boolean = isTesting): any => {

    return (
        dispatch: any,
        getState: any
    ) => {

        if (getState()[EAlgoApiObjectType["camera-group"]].loadingGrouped) return;

        if (test)
            dispatch(getAllGrouped_test(mode));
        else 
            dispatch(getAllGrouped_api(mode));
    }
}

// retrieves all data from api for this data type
export const getAllGrouped_api = (mode?: string) => {

    return (
        dispatch: any,
        getState: any,
    ) => {

        let beginFunction = beginGeojson;
        let successFunction = successGeojson;
        let failureFunction = failureGeojson;

        if (mode === "init") {
            beginFunction = beginInit;
            successFunction = successInit;
            failureFunction = failureInit;
        }

        dispatch(beginFunction());

        let manager: CameraNetworkManager = new CameraNetworkManager(apiUrl);

        getAccessToken().then(
            (token: string) => {
                manager.setAccessToken(token);

                manager.getGrouped(buildLastResponse(getState()), {includeGeometry: true}, false)
                .then(
                    (response: IProcessedResponse) => {
                        if (response.error)
                            dispatch(failureFunction({ errorMessage: response.error.message}))
                            else {
                                let reduxObject: IStoreProcessedResponse = response.getReduxObject();
        
                                let features: IFeature[] = response.data.features;
                                let data: ICameraGroupWithCoord[] = [];
                                let geometries: IGeometry[] = [];
        
                                features.forEach( (feature: IFeature) => { 
                                    geometries.push(feature.geometry); 
                                    data.push(
                                        {...feature.properties.data, 
                                            coord: {
                                                lat: feature.geometry.coordinates[1], 
                                                lng: feature.geometry.coordinates[0]
                                            },
                                            id: feature.properties.data.pages[0]?.items[0]?.camera?.id
                                        }
                                    ); 
                                });
                                
                                dispatch(successFunction({...reduxObject, data, geometries}))
                            }
                    }
                ).catch(
                    (error: Error) => {
                        dispatch(failureFunction({ errorMessage: error.message }))
                    }
                );
            }
        ).catch(
            (err: any) => {
                manager.getGrouped(buildLastResponse(getState()), {includeGeometry: true}, false)
                .then(
                    (response: IProcessedResponse) => {
                        if (response.error)
                            dispatch(failureFunction({ errorMessage: response.error.message}))
                            else {
                                let reduxObject: IStoreProcessedResponse = response.getReduxObject();
        
                                let features: IFeature[] = response.data.features;
                                let data: ICameraGroupWithCoord[] = [];
                                let geometries: IGeometry[] = [];
        
                                features.forEach( (feature: IFeature) => { 
                                    geometries.push(feature.geometry); 
                                    data.push(
                                        {...feature.properties.data, 
                                            coord: {
                                                lat: feature.geometry.coordinates[1], 
                                                lng: feature.geometry.coordinates[0]
                                            },
                                            id: feature.properties.data.pages[0]?.items[0]?.camera?.id
                                        }
                                    ); 
                                });
                                
                                dispatch(successFunction({...reduxObject, data, geometries}))
                            }
                    }
                ).catch(
                    (error: Error) => {
                        dispatch(failureFunction({ errorMessage: error.message }))
                    }
                );
                console.error("Error retrieving user access token.");
            }
        )
    }
    
};

// retrieves test data for this data type
export const getAllGrouped_test = (mode?: string) => {

    return (
        dispatch: any,
        getState: any
    ) => {

        let beginFunction = beginGeojson;
        let successFunction = successGeojson;
        let failureFunction = failureGeojson;

        if (mode === "init") {
            beginFunction = beginInit;
            successFunction = successInit;
            failureFunction = failureInit;
        }

        dispatch(beginFunction());

        
        let features: IFeature[] = T_DATA_GEOJSON_PROD.features;
        let data: ICameraGroupWithCoord[] = [];
        let geometries: IGeometry[] = [];

        features.forEach( (feature: IFeature) => { 
            geometries.push(feature.geometry); 
            data.push(
                {...feature.properties.data, 
                    coord: {
                        lat: feature.geometry.coordinates[1], 
                        lng: feature.geometry.coordinates[0]
                    },
                    id: feature.properties.data.pages[0]?.items[0]?.camera?.id
                }
            ); 
        });
        
        setTimeout(
            () => {
                dispatch(successFunction({data, geometries, errorMessage: null, status: 200}))
            }, 2500
        );
    }
    
};

// filters data list based on given search term
export const filterGroupedBySearch = (searchTerm: string = "") => {

    //return commonFilterGroupedBySearch(cameraGroupSlice, searchTerm, searchMatch);
    return filterGroupedCameraBySearch(cameraGroupSlice, searchTerm, searchMatch);
    
};

export const filterGroupedCameraBySearch = (
    slice: any, 
    searchTerm: string = "", 
    searchMatch: (dataItem: any, searchTerm?: string) => boolean
) => {
    
    return (
        dispatch: any,
        getState: any
    ) => {

        let groupedData: any[] = getState()[slice.name].dataGrouped || [];
        let filteredData: any[] = groupedData;

        if (!searchTerm) {
            dispatch(
                slice.actions.filterDataGroupedBySearch(
                    {filteredData}
                )
            )
            
            return;
        }

        filteredData = groupedData.filter( 
            (dataGroup: any, filterIndex: number) => {

                let group: any = dataGroup;
                let pages: any[] = group.pages || [];

                let firstPage: any = pages[0];
                let firstItem: any | undefined = ( firstPage.items )
                    ? firstPage.items[0] 
                    : undefined;

                let dataObject: any = firstItem["camera"];

                if (searchMatch(dataObject, searchTerm)) return dataGroup;
            }
        );

        dispatch(slice.actions.filterDataGroupedBySearch({filteredData}));   
    }
}

const searchMatch = (camera: IATCamera, searchTerm: string = ""): boolean => {

    if (!searchTerm) return true;

    if (
        (camera.location) && 
            ( 
                camera.location.displayRouteDesignator?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                camera.location.displayCrossStreet?.toLowerCase().includes(searchTerm.toLowerCase())
            )
    ) return true;

    return false;
}

// this function is utilized by slices' 'filterData' function
// to determine the coordinate location of camera object for geo-filtering
export const getCoords = (
    item: ICameraGroupWithCoord
): {lat: number, lng: number} | null => {

    let lat: number = item.coord.lat;
    let lng: number = item.coord.lng;

    if (nuon(lat) && nuon(lng))
        return {lat, lng}

    else 
        return null;

};

export const getFirstCameraFromGroup = (group: IATCameraGroupDto) => {

    let board: IATVideoBoardDto = (group as IATVideoBoardDto);
    let pages: IATVideoPageDto[] = board.pages || [];

    let firstPage: IATVideoPageDto = pages[0];
    let firstItem: IATVideoItemDto | undefined = ( firstPage.items )
        ? firstPage.items[0] 
        : undefined;

    let firstCamera: IATCamera | undefined = (firstItem) 
        ? firstItem.camera 
        : new ATCamera();

    return firstCamera;
};

export const getAllButFirstCameraFromGroup = (group: IATCameraGroupDto) => {

    let cameraList: IATCameraDto[] = [];

    let board: IATVideoBoardDto = (group as IATVideoBoardDto);
    let pages: IATVideoPageDto[] = board.pages || [];

    pages.forEach( (page: IATVideoPageDto, pageIndex: number) => {
        page.items.forEach( (item: IATVideoItemDto, itemIndex: number) => {
            if (!(pageIndex === 0 && itemIndex === 0)){
                cameraList.push(item.camera);
            }
        })
    });

    return cameraList;
};

// exports the slice's reducer ( used in store file to build up master reducer )
export const cameraGroupReducer = cameraGroupSlice.reducer;