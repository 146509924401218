// libraries
import styled from "styled-components";
import { whenSmallMax } from "~/theme";

export const StyledMessageSignDetails = styled.div`
    height: 290px;
    width: calc(100% - 20px);

    margin: 0 0 0 20px;

    ${whenSmallMax(`
        width: 100%;
        margin: 0 0 0 0;
    `)}
`;