import { IRgbaObject } from "~/interfaces";

// create an rgba color string based on a color object
export const buildRgbString = (colorObject: IRgbaObject):string => {

    const {
        red, green, blue, alpha = 1
    } = colorObject;

    return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
};