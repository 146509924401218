// libraries
import styled from "styled-components";
import { flexSmart, RemoveScrollbar } from "@caps-mobile/styled-lib";
// constants
import { BORDER_RADIUS } from "~/constants";

export const LowerUI = styled.div`
    height: 120px;
    width: 332px;
    position: absolute;
    bottom: 20px;

    ${flexSmart("space-between center")}
    flex-direction: column;

    padding: 12px 20px;
    box-sizing: border-box;

    font-size: 24px;

    border-radius: ${BORDER_RADIUS}px;

    background-color: rgba(30, 30, 30, 0.75);
`;

export const ControlsRow = styled.div`
    height: auto;
    width: 100%;

    ${flexSmart("space-between center")}
`;

export const ArrowButton = styled.div`
    width: 100%;
    ${flexSmart("center center")}
`;

export const BackdropWrap = styled.div<{isDisabled?: boolean}>`
    height: 34px;
    width: 34px;

    ${flexSmart("center center")}

    border-radius: 50%;

    background-color: ${props => props.isDisabled 
        ? "rgba(235, 235, 245, 0.3)" 
        : "rgba(235, 235, 245, 0.6)"
    };
`;

export const UIPrevious = styled.div<{isDisabled?: boolean}>`
    width: 33%;
    height: 54px;

    ${flexSmart("space-between center")}
    flex-direction: column;

    :hover{
        cursor: pointer;
    }
`;

export const UITogglePlay = styled.div<{isDisabled?: boolean}>`
    width: 33%;
    height: 54px;
    
    ${flexSmart("space-between center")}
    flex-direction: column;

    :hover{
        cursor: pointer;
    }

    border-radius: 50%;

    font-size: 32px;
    color: ${props => props.isDisabled 
        ? "rgba(235, 235, 245, 0.3)" 
        : "rgba(235, 235, 245, 0.6)"
    };

    :hover{
        cursor: pointer;
    }
`;

export const UINext = styled.div<{isDisabled?: boolean}>`
    width: 33%;
    height: 54px;
    ${flexSmart("space-between flex-end")}
    flex-direction: column;

    :hover{
        cursor: pointer;
    }
`;

export const UICaption = styled.div<{isDisabled?: boolean}>`
    font-size: 12px;
    width: 100%;
    ${flexSmart("center center")}

    color: ${props => props.isDisabled 
        ? "rgba(235, 235, 245, 0.3)" 
        : "rgba(235, 235, 245, 0.6)"
    };
`;

export const ProgressBarRow = styled.div`
    height: auto;
    width: 100%;
`;

export const PagesRow = styled.div`
    width: 100%;
    height: 14px;

    display: flex;

    > {
        :first-child{
            margin-left: auto;
        }
        :last-child{
            margin-right: auto;
        }
    }

    overflow: auto;

    ${RemoveScrollbar}
`;

export const PageBubble = styled.span<{isActive: boolean}>`
    width: 14px;
    height: 14px;

    ${flexSmart("center center")}

    padding: 4px;
    box-sizing: border-box;

    text-align: center;
    font-size: 11px;
    line-height: 14px;
    color: ${props => props.isActive 
        ? "#000" 
        : "#fff"
    };

    border-radius: 50%;

    background-color: ${props => props.isActive 
        ? "rgba(235, 235, 245, 0.6)" 
        : "transparent"
    };

    : hover{
        cursor: pointer;
    }
`;