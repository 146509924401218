// libraries
import { 
    createAlgoSlice, commonLocationsBySearch 
} from "./common";
// types & models
import { LocationsNetworkManager } from "@algo/network-manager/managers/v3";
import { EAlgoApiObjectType } from "~/interfaces";
// constants
import { CUR_API_VERSION, CUR_API_ENDPOINTS } from "../../api-endpoint-strings";
import { isTesting } from "~/constants"

declare var __API_URL__: string;
const apiUrl: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).locations}`;

// create list slice
export const locationSlice = 
    createAlgoSlice(EAlgoApiObjectType["location"]);

// get handles for the slice's actions
const { 
    begin, success, failure,
    beginInit, successInit, failureInit
} = locationSlice.actions;

// handles dispatching a data get by search term from either api or test source based on args
export const get = (searchTerm: string, lat: number = 0, lng: number = 0, test: boolean = isTesting, testMode?: string) => {

    return (
        dispatch: any,
        getState: any
    ) => {

        if (getState()[EAlgoApiObjectType.location].loading) return;

        if (test)
            dispatch(get_test(searchTerm, lat, lng, testMode));
        else 
            dispatch(get_api(searchTerm, lat, lng));
    }
}

// retrieves data from api for this data type
export const get_api = (searchTerm: string, lat: number = 0, lng: number = 0) => {

    return commonLocationsBySearch(
        new LocationsNetworkManager(apiUrl), 
        locationSlice,
        { searchTerm: searchTerm, lat: lat, lng: lng }
    );
    
};

// retrieves test data for this data type
export const get_test = (searchTerm: string, lat: number = 0, lng: number = 0, mode?: string) => {
    alert("location: getById_test is not yet implemented.")
};

// exports the slice's reducer ( used in store file to build up master reducer )
export const locationReducer = locationSlice.reducer;