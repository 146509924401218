// libraries
import * as React from "react";
import { useBreakpoint } from "~/library/useBreakpoint";
import { RemoveScrollbar } from "@caps-mobile/styled-lib";
// styles
import OverlaySection from "../../../../views/map-overlay-views/OverlaySection";
// components
import Route from "./Route";

export type IProps = {
    routeData: any[],
    selectedIndex: number,
    setSelectedIndex: (index: number) => void
};

export const RouteSection: React.FC<IProps> = (props) => {

    const { sm: isSmallMax } = useBreakpoint();
    const { md: isMediumMax } = useBreakpoint();
    const { xl: isXLargeMax } = useBreakpoint();
    const routeData = props.routeData;
    const width = routeData.length === 3 ? 720 : 500;
    const maxWidth = routeData.length === 3 ? 1000 : 600;

    return (
        /* Overlay Views */
        <OverlaySection 
            bottom={isSmallMax ? 20 : 50}
            styleOverwrite={
                `${isMediumMax ? 
                'position: absolute; overflow: auto;' + RemoveScrollbar :
                isXLargeMax ?
                'position: absolute; overflow: auto; max-width: 800px;' + RemoveScrollbar :
                'position: absolute; overflow: hidden; pointer-events: none; max-width: ' + maxWidth + 'px'}`}
            childWrapperStyleOverwrite={
                `${isSmallMax ? 
                'flex-direction: row; margin-right: 10px; width: ' + width + 'px' : 
                isMediumMax ?
                'flex-direction: row; margin-right: 10px; margin-left: 10px; width: ' + width + 'px' :
                'flex-direction: row; margin-right: 10px;'}`}
        >
            {routeData && routeData.map(
                (route: any, index: number) => {
                    return ( 
                        <Route key={index} route={route} index={index + 1} isSelected={props.selectedIndex === index + 1} setSelected={(index: number) => props.setSelectedIndex(index)} />
                    )
                })
            }
        </OverlaySection>
    );

};

export default RouteSection;