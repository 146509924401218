// libraries
import { useContext, useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ReactGA from "react-ga4";
// context
import SiteNavContext from "~/navigation/SiteNavContext";
// constants
import { noNavRoutes } from "~/constants";

export const useNavTrigger = () => {

    const navContext = useContext(SiteNavContext);
    const location = useLocation();
    const navigate = useNavigate();
    const [ searchParams, setSearchParams] = useSearchParams();

    useEffect(
        () => {
            let pathname: string = location.pathname;

            if (noNavRoutes.includes(pathname))
                return;
            else if (pathname === navContext.selectedRoute) 
                return;
            else{
                // then navigate to the currently selected route
                navigate({
                    pathname: navContext.selectedRoute,
                    // search property is necessary to support the root page search query functionality
                    // if this is not provided, the search queries will be stripped off when re-directing to '/map' page
                    search: searchParams.toString()
                });

                const SendAnalytics = ()=> {
                    ReactGA.send({
                    hitType: "pageview",
                    page: navContext.selectedRoute,
                    });
                }

                SendAnalytics();
            }

            // triggers when the selected route changes
        }, [navContext.selectedRoute]
    )
}