// libraries
import { absoluteArrayBuilder, flexSmart } from "@caps-mobile/styled-lib";
import styled from "styled-components";
import { tickerHeight } from "~/constants";

export type IOverlayProps = {
    showOverlay?: boolean, 
    tickerCount?: number
};

export const StyledDataSearchOverlay = styled.div<IOverlayProps>`

    ${absoluteArrayBuilder(["auto", "0", "0", "auto"])}
    z-index: 2;

    height: ${props => props.showOverlay 
        ? `calc(100% - ${(props.tickerCount || 0) * tickerHeight}px)` 
        : "0px"
    };

    width: 100%;

    opacity: ${props => props.showOverlay ? "1" : "0"};

    background-color: ${props => props.theme.colors["bg-primary"].rgba};

    transition: height .5s ease-out, opacity 1s;
`;

export const OverlayContent = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
`;

export const SearchRow = styled.div`
    width: 100%;
    ${flexSmart("space-between center")}
    margin-bottom: 10px;
`;

export const SearchBarWrap = styled.div`
    width: calc(100% - 105px);
`;