// libraries
import * as React from "react";
// styles
import * as SC from "./Styled";
// components
import { IATFerryDto, IATFerryLandingDto } from "@algo/network-manager/models/v3";
import { dePascal } from "~/library/string-tools";

export type IProps = {
    object: IATFerryLandingDto;
};

export const FerryDetails: React.FC<IProps> = (props) => {

    const {
        object
    } = props;

    return (
        <SC.StyledFerryDetails>

            <SC.PropertyRow>
                <SC.PropertyName>{`Estimated Wait Time: `}</SC.PropertyName>
                &nbsp;&nbsp;
                <SC.PropertyValue>{object.estimatedWaitTime}</SC.PropertyValue>
            </SC.PropertyRow>

            {   object.ferries && object.ferries.map(
                (ferry: IATFerryDto, index: number) => {
                    return (
                        <SC.PropertyRow key={`${ferry.name}${index}`}>
                            <SC.PropertyName>{`${ferry.name}:`}</SC.PropertyName>
                            &nbsp;&nbsp;
                            <SC.PropertyValue>{dePascal(ferry.status.toString())}</SC.PropertyValue>
                        </SC.PropertyRow>
                    )
                }
            )}

            <SC.PropertyRow>
                <SC.PropertyName>{`Vehicles Over 21ft Allowed:  `}</SC.PropertyName>
                &nbsp;&nbsp;
                <SC.PropertyValue>{object.allowVehiclesOver21Feet ? `Yes` : `No`}</SC.PropertyValue>
            </SC.PropertyRow>
            

        </SC.StyledFerryDetails>
    )
};

export default FerryDetails;