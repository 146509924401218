// libraries
import { flexSmart, RemoveScrollbar } from "@caps-mobile/styled-lib";
import styled from "styled-components";
import { BORDER_RADIUS } from "~/constants";

export const StyledPermissionInstructions = styled.div`
    height: 60%;
    width: 60%;

    position: relative;

    padding: 20px;
    box-sizing: border-box;

    ${flexSmart("flex-start center")}
    flex-direction: column;

    font-size: 18px;
    font-weight: 400;

    border-radius: ${BORDER_RADIUS}px;

    background-color: ${props => props.theme.colors["bg-primary"].rgba};
`;

export const TitleLine = styled.div`
    width: 100%;
    height: 28px;
    
    ${flexSmart("flex-start center")}

    font-size: 28px;
    font-weight: 700;
`;

export const Content = styled.div`
    height: calc(100% - 65px);
    padding: 0 20px;
    box-sizing: border-box;

    > :not(:last-child){
        margin-top: 20px;
        box-sizing: border-box;
    }

    overflow: auto;
    ${RemoveScrollbar}
`;

export const ExplainText = styled.div`
    width: 100%;
`;

export const LinksSection = styled.div`
    width: 100%;
`;

export const DoneText = styled.div`
    position: absolute;
    right: 40px;
    z-index: 3;

    font-weight: 700;
    font-size: 17px;
    line-height: 147%;

    color: ${props => props.theme.colors["primary"].rgba};

    :hover{
        cursor: pointer;
    }
`;