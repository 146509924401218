// libraries
import * as React from "react";
import SchemeSelectOption from "./scheme-select-option/SchemeSelectOption";
// styles
import * as SC from "./Styled";
// constants
import { NORMAL_MAP_SCHEME, SATELLITE_MAP_SCHEME, TERRAIN_MAP_SCHEME } from "~/constants";

export type IProps = {
    title?: string;
};

export const SchemeSelectList: React.FC<IProps> = (props) => {

    const { title } = props;

    return (
        <SC.StyledSchemeSelectList>
            
            { title && <SC.Header> {title} </SC.Header> }

            <SC.SchemeOptionList>

                <SchemeSelectOption 
                    label={"Normal"}
                    value={NORMAL_MAP_SCHEME}
                />

                <SchemeSelectOption 
                    label={"Satellite"}
                    value={SATELLITE_MAP_SCHEME}
                />

                {/* <SchemeSelectOption 
                    label={"Terrain"}
                    value={TERRAIN_MAP_SCHEME}
                /> */}

            </SC.SchemeOptionList>

        </SC.StyledSchemeSelectList>
    )
};

export default SchemeSelectList;