// libraries
// types & models
import { IProcessedResponse, IFeedbackNetworkManager, FeedbackNetworkManager } from "@algo/network-manager/managers/v3";
import { IATFeedbackFormObjectInit } from "@algo/network-manager/models/v3";
// constants
import { CUR_API_VERSION, CUR_API_ENDPOINTS } from "../../api-endpoint-strings";
// test data
import { createSlice } from "@reduxjs/toolkit";

declare var __API_URL__: string;
const apiUrl: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).feedbacks}`;

// create list slice
export const feedbackSlice = createSlice(
    {
        name: "feedback",
        initialState: {
            loading: false,
            submitSuccess: false
        },
        reducers: {
            begin: (state: any, action: any) => {
                state.loading = true;
                state.submitSuccess = false;
            },
            success: (state: any, action: any) => {
                state.loading = false;
                state.submitSuccess = true;
            },
            failure: (state: any, action: any) => {
                state.loading = false;
                state.submitSuccess = false;
            },
            clear: (state: any) => {
                state.submitSuccess = false;
            }
        }
    }
);

// get handles for the slice's actions
const { 
    begin, success, failure, clear
} = feedbackSlice.actions;

// handles dispatching a data get by id from either api or test source based on args
export const createFeedback = (feedback: IATFeedbackFormObjectInit) => {

    return (
        dispatch: any,
        getState: any
    ) => {
        
        if (getState()["feedback"].loading) return;

        dispatch(begin({}));

        let manager: IFeedbackNetworkManager = new FeedbackNetworkManager(apiUrl);
        let feedbackInitObject: IATFeedbackFormObjectInit = {
            name: feedback.name,
            email: feedback.email,
            company: feedback.company,
            subject: feedback.subject,
            comments: feedback.comments
        };

        manager.createFeedback({ feedback: feedbackInitObject })
            .then(
                (response: IProcessedResponse) => {
                    if (response.error) {
                        dispatch(failure({}));
                    }
                    else dispatch(success({}));
                }
            ).catch(
                (error: Error) => dispatch(failure({}))
            );

    }
};

export const clearSubmitSuccess = () => {

    return (
        dispatch: any,
        getState: any
    ) => {
        
        if (getState()["feedback"].submitSuccess){
            dispatch(clear());
        }
    }
}

// exports the slice's reducer ( used in store file to build up master reducer )
export const feedbackReducer = feedbackSlice.reducer;