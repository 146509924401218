// libraries
import { flexSmart } from "@caps-mobile/styled-lib";
import styled from "styled-components";
// constants
import { BORDER_RADIUS } from "~/constants";

export const StyledBackButton = styled.div`
    width: 100px;
    height: 45px;

    ${flexSmart("center center")}

    font-size: 17px;
    line-height: 25px;

    background-color: ${props => props.theme.colors["bg-grey"].rgba};

    color: ${props => props.theme.colors["primary"].rgba};

    border: none;
    border-radius: ${BORDER_RADIUS}px;

    :hover {
        cursor: pointer;
    }
`;