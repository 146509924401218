// libraries
import * as React from "react";
// types & models
import { ISVGProps } from "~/resources/interfaces";

export const TrafficLayerGlyph: React.FC<ISVGProps> = (props) => {

    const {
        width, height, viewBox, color
    } = props;

    return (
        <svg 
            width={width || "31"} height={height || "31"} 
            viewBox={ viewBox || "0 0 31 31" } fill="none" 
        >
            <path 
                fill={color || "black"}
                d="M15.4983,0C14.1967,0 13.0232,0.494 12.1203,1.2917L10.3317,1.2917C8.9044,1.2917 7.7483,2.4477 7.7483,3.875L7.7483,28.4167C7.7483,29.844 8.9044,31 10.3317,31L20.665,31C22.0923,31 23.2483,29.844 23.2483,28.4167L23.2483,3.875C23.2483,2.4477 22.0923,1.2917 20.665,1.2917L18.8763,1.2917C17.9735,0.494 16.8,0 15.4983,0ZM1.29,3.875L1.7643,5.3004C2.2512,6.7638 3.6228,7.75 5.165,7.75L5.165,3.875L1.29,3.875ZM15.4983,3.875C16.9256,3.875 18.0817,5.031 18.0817,6.4583C18.0817,7.8856 16.9256,9.0417 15.4983,9.0417C14.071,9.0417 12.915,7.8856 12.915,6.4583C12.915,5.031 14.071,3.875 15.4983,3.875ZM25.8317,3.875L25.8317,7.75C27.3739,7.75 28.7454,6.7638 29.2324,5.3004L29.7067,3.875L25.8317,3.875ZM15.4983,12.9167C16.9256,12.9167 18.0817,14.0727 18.0817,15.5C18.0817,16.9273 16.9256,18.0833 15.4983,18.0833C14.071,18.0833 12.915,16.9273 12.915,15.5C12.915,14.0727 14.071,12.9167 15.4983,12.9167ZM1.29,14.2083L1.7643,15.6337C2.2512,17.0972 3.6228,18.0833 5.165,18.0833L5.165,14.2083L1.29,14.2083ZM25.8317,14.2083L25.8317,18.0833C27.3739,18.0833 28.7454,17.0972 29.2324,15.6337L29.7067,14.2083L25.8317,14.2083ZM15.4983,21.9583C16.9256,21.9583 18.0817,23.1144 18.0817,24.5417C18.0817,25.969 16.9256,27.125 15.4983,27.125C14.071,27.125 12.915,25.969 12.915,24.5417C12.915,23.1144 14.071,21.9583 15.4983,21.9583ZM1.29,24.5417L1.7643,25.967C2.2512,27.4305 3.6228,28.4167 5.165,28.4167L5.165,24.5417L1.29,24.5417ZM25.8317,24.5417L25.8317,28.4167C27.3739,28.4167 28.7454,27.4305 29.2324,25.967L29.7067,24.5417L25.8317,24.5417Z"
            />
        </svg>
    );
};

export default TrafficLayerGlyph;