// libraries
import { flexSmart, RemoveScrollbar } from "@caps-mobile/styled-lib";
import styled from "styled-components";
import { CardPartial } from "~/styles/Card";
import { whenSmallMax } from "~/theme";

export const ModalCard = styled.div<{large?: boolean}>`
    position: relative;

    ${CardPartial}

    max-height: 100%;
    max-width: ${props => props.large ? '95%' : '45%'};

    & > :not(:last-child){
        margin-bottom: 20px;
    }

    overflow: auto;

    ${RemoveScrollbar}

    ${whenSmallMax(`
        max-width: 100%;
        border-radius: 0;
    `)}
`;

export const FloatingCloseBtn = styled.div`

    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;

    padding: 10px;
    box-sizing: border-box;

    font-size: 32px;
    line-height: 32px;

    :hover{
        cursor: pointer;
    }
`;

export const TrafficLegend = styled.div<{isMediumMax?: boolean}>`

    width: ${props => props.isMediumMax ? '100%' : '585px'};
`;

export const Title = styled.div<{isMediumMax?: boolean}>`
    font-size: 24px;
    font-weight: 600;
    padding-bottom: 30px;
    box-sizing: border-box;
`;

export const TrafficLegendItems = styled.div<{isMediumMax?: boolean}>`

    font-size: ${props => props.isMediumMax ? '10px' : 'auto'};

    display: flex;
    align-items: center;
`;

export const ColorBox = styled.div<{fillColor?: string, strokeColor?: string}>`
    width: 150px;
    height: 15px;
    background-color: ${props => props.fillColor};
`;

export const ColorBoxStart = styled.div<{fillColor?: string, strokeColor?: string}>`
    width: 150px;
    height: 15px;
    margin-left: 5px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: ${props => props.fillColor};
`;

export const ColorBoxEnd = styled.div<{fillColor?: string, strokeColor?: string}>`
    width: 150px;
    height: 15px;
    margin-right: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: ${props => props.fillColor};
`;