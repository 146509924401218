// libraries
import { useState, useCallback } from "react";
import { getEnumStrings } from "@caps-mobile/common-lib";
import { dePascal } from "~/library/string-tools";
// hooks & context
import { useDropdownData, useFilterContent, useLoadCameras } from "./hooks";
// styles
import * as PageStyles from "~/components/views/page-template/Styled";
import * as SC from "./Styled";
// types & models
import { EATPlaceType, EATRegion, IATCityDto, IATCountyDto, IATPlaceDto } from "@algo/network-manager/models/v3";
// components
import PageTemplate from "~/components/views/page-template/PageTemplate";
import { AlgoDropdown } from "~/components/views/algo-dropdown/AlgoDropdown";
// resources
import 
    DetailsSection, { EDetailDisplayType } 
from "~/components/views/data-search-overlay/data-type-sections/detail-section/DetailsSection";
import { EAlgoApiObjectType } from "~/interfaces";
import NoResultsTemplate from "~/components/views/page-template/NoResultsTemplate";
// constants
const REGIONS: string[] = getEnumStrings(EATRegion).filter(x => x !== 'Unknown').map((str: string) => str !== "ALDOT" ? dePascal(str) : str);

export type IProps = {
    //
};

export const CamerasPage: React.FC<IProps> = (props) => {

    // trigger camera data load in case it was not done already
    const cameraStore: any = useLoadCameras();
    const filteredCameras = cameraStore.dataFilteredBySelections;

    // track the selected indices for dropdowns
    const [ cityIndices, setCityIndices ] = useState<number[]>([]);
    const [ countyIndices, setCountyIndices ] = useState<number[]>([]);
    const [ roadwayIndices, setRoadwayIndices ] = useState<number[]>([]);
    const [ regionIndices, setRegionIndices ] = useState<number[]>([]);

    // track the search text value
    const [ searchText, setSearchText ] = useState<string>("");

    // load city and county data if they aren't already loaded
    const [ cityStore, countyStore, placeStore ] = useDropdownData();

    // get the city and county names for dropdown options lists
    const cityStrings = cityStore.data?.map( (city: IATCityDto) => city.name || "??");
    const countyStrings = countyStore.data?.map( (county: IATCountyDto) => county.name || "??");
    const roadwayStrings = placeStore.data?.filter((place: IATPlaceDto) => place.type !== EATPlaceType.Arterial
                            && place.type !== EATPlaceType.Unknown && place.type !== EATPlaceType.ExitRamp 
                            && place.type !== EATPlaceType.MilePost).map( 
                                (place: IATPlaceDto) => place.name || "??");

    // filteres the content section when search selections or data stores change
    useFilterContent(
        searchText, cityIndices, countyIndices, roadwayIndices, regionIndices,
        cameraStore,
        cityStrings, countyStrings, roadwayStrings, REGIONS
    );

    // brevity
    const isLoadingContent: boolean = cameraStore.loading;

    // the following are the callback functions which trigger when a dropdown choice is made
    const cityDropdownChange = useCallback(
        (selectedIndex: number) => {
            commonDropdownSelectLogic(selectedIndex, cityIndices, setCityIndices);
        }, [cityIndices, setCityIndices]
    );

    const countyDropdownChange = useCallback(
        (selectedIndex: number) => {
            commonDropdownSelectLogic(selectedIndex, countyIndices, setCountyIndices);
        }, [countyIndices, setCountyIndices]
    );

    const roadwayDropdownChange = useCallback(
        (selectedIndex: number) => {
            commonDropdownSelectLogic(selectedIndex, roadwayIndices, setRoadwayIndices);
        }, [roadwayIndices, setRoadwayIndices]
    );

    const regionDropdownChange = useCallback(
        (selectedIndex: number) => {
            commonDropdownSelectLogic(selectedIndex, regionIndices, setRegionIndices);
        }, [regionIndices, setRegionIndices]
    );

    return (
        <PageTemplate 
            title={"Cameras"}
            searchBar={{
                autoSubmit: true, 
                submitCallback: (newText: string) => 
                    setSearchText(newText)
            }}
        >

            <PageStyles.FilterRow>

                <AlgoDropdown
                    options={cityStrings}
                    placeholder={"All Cities"}
                    showPlaceholder={cityIndices.length > 0 ? false : true}
                    scrollThreshold={6}
                    multiSelect={true}
                    callback={cityDropdownChange}
                />

                <AlgoDropdown
                    options={countyStrings}
                    placeholder={"All Counties"}
                    showPlaceholder={countyIndices.length > 0 ? false : true}
                    scrollThreshold={6}
                    multiSelect={true}
                    callback={countyDropdownChange}
                />

                <AlgoDropdown
                    options={roadwayStrings}
                    placeholder={"All Roadways"}
                    showPlaceholder={roadwayIndices.length > 0 ? false : true}
                    scrollThreshold={6}
                    multiSelect={true}
                    callback={roadwayDropdownChange}
                />

                <AlgoDropdown
                    options={REGIONS}
                    placeholder={"All Regions"}
                    showPlaceholder={regionIndices.length > 0 ? false : true}
                    scrollThreshold={6}
                    multiSelect={true}
                    callback={regionDropdownChange}
                />

            </PageStyles.FilterRow>

            <PageStyles.FilteredContentSection>

                { filteredCameras && filteredCameras.length > 0 &&
                    <div>
                        <DetailsSection dataObjects={filteredCameras} showAll={true}
                            dataType={EAlgoApiObjectType.camera} displayType={EDetailDisplayType.snapshot} />

                        <SC.cameraDisclaimer>
                            ALGO Traffic cameras are a public safety tool for use in real time and not intended to be recorded or 
                            otherwise used for any commercial purpose. All unauthorized photography, recording, storing, or 
                            transmitting of visual material, data, or information gathered from ALGO traffic cameras without the 
                            permission of ALDOT is expressly prohibited.
                        </SC.cameraDisclaimer>
                    </div>
                }

                { (!filteredCameras || !filteredCameras?.length || filteredCameras?.length <= 0) &&
                    <NoResultsTemplate loading={isLoadingContent} pageType="Cameras" />
                }

            </PageStyles.FilteredContentSection>

        </PageTemplate>
    );
};

const commonDropdownSelectLogic = (
    selectedIndex: number, 
    indicesList: number[], 
    stateUpdateCallback: (newArray: number[]) => void
) => {
    if(selectedIndex === -1){
        stateUpdateCallback([]);
    }
    else{
        let curIndex: number = indicesList.indexOf(selectedIndex);
        let newIndices: number[] = indicesList;
        if (curIndex === -1){
            newIndices.push(selectedIndex);
        }
        else{
            newIndices.splice(curIndex, 1);
        }
        stateUpdateCallback([...newIndices]);
    }
};

export default CamerasPage;