// libraries
import * as React from "react";
import PageTemplate from "~/components/views/page-template/PageTemplate";
// hooks & context
import SiteNavContext from "~/navigation/SiteNavContext";
// styles
import * as PageStyles from "../../views/page-template/Styled";
// constants
const thisApplicationCollects: string = 
    `This Application collects some Personal Data from its Users.`;
const personalDataCollected: string =
    `Personal Data collected for the following purposes and using the following services:`;
const pushNotifications: string =
    `Push notifications - This Application may send push notifications to the User.`;
const dataOwner: string =
    `Alabama Department of Transportation 1409 Coliseum Boulevard Montgomery, Alabama 36110, aldotinfo@dot.state.al.us`;

export type IProps = {
    //
};

export const PrivacyPolicyPage: React.FC<IProps> = (props) => {

    const navContext = React.useContext(SiteNavContext);

    return (
        <PageTemplate title={"Privacy Policy"} 
            backButton={true} backButtonCallback={() => navContext.navigate("/about")}
        >

            <PageStyles.PageText>{thisApplicationCollects}</PageStyles.PageText>

            <PageStyles.PageH1>{"Privacy summary"}</PageStyles.PageH1>

            <PageStyles.PageText>{personalDataCollected}</PageStyles.PageText>

            <ul style={{  listStyleType: "disc", paddingLeft: "30px"}}>
                <li>{"Analytics"}</li>
                <ul style={{  listStyleType: "disc", paddingLeft: "25px"}}>
                    <li>{"Google Analytics"}</li>
                    <li>{"Personal Data: Cookies and Usage Data"}</li>
                </ul>
                <li>{"Contacting the User"}</li>
                <ul style={{  listStyleType: "disc", paddingLeft: "25px"}}>
                    <li>{"Mailing list or newsletter"}</li>
                    <li>{"Personal Data: email address, first name and last name"}</li>
                    <li>{"Contact form"}</li>
                    <ul style={{  listStyleType: "disc", paddingLeft: "25px"}}>
                        <li>{"Personal Data: company name, email address, first name, last name and phone number"}</li>
                    </ul>
                </ul>
                <li>{"Hosting and backend infrastructure"}</li>
                <ul style={{  listStyleType: "disc", paddingLeft: "25px"}}>
                    <li>{"Firebase"}</li>
                    <li>{"Personal Data: various types of Data as specified in the privacy policy of the service"}</li>
                </ul>
                <li>{"Location-based interactions"}</li>
                <ul style={{  listStyleType: "disc", paddingLeft: "25px"}}>
                    <li>{"Geolocation"}</li>
                    <li>{"Personal Data: geographic position"}</li>
                </ul>
            </ul>

            <PageStyles.PageH1>{"Further information about Personal Data"}</PageStyles.PageH1>

            <ul style={{  listStyleType: "disc", paddingLeft: "25px", margin: 0}}>
                <li>{pushNotifications}</li>
            </ul>

            <PageStyles.PageH1>{"Contact information"}</PageStyles.PageH1>

            <ul style={{  listStyleType: "disc", paddingLeft: "25px", margin: 0}}>
                <li>{"Data Owner"}</li>
                <ul style={{  listStyleType: "disc", paddingLeft: "25px"}}>
                    <li>{dataOwner}</li>
                </ul>
            </ul>

            <PageStyles.PageH1>{"Full Policy"}</PageStyles.PageH1>

            <PageStyles.PageH2>{"Data Controller and Owner"}</PageStyles.PageH2>

            <PageStyles.PageText>{dataOwner}</PageStyles.PageText>

        </PageTemplate>
    );
};

export default PrivacyPolicyPage;