// styles
import Breakpoints from "./breakpoints";
import UI from "./ui";
import ColorsLight from "./colors-light";
import ColorsDark from "./colors-dark";
import Typography from "./typography";
import Card from "./card";
import Navbar from "./navbar";
import Message from "./message";
import Globals from "./globals";
import Dropdown from "./dropdown";
import { ATCongestionLevel } from "~/interfaces";

export enum EThemeOptions {
    Dark,
    Light,
    Default
};

export const DefaultTheme = EThemeOptions.Dark;

export const getColorObject = (option: EThemeOptions) => {
    
    switch(option){
        case EThemeOptions.Dark:
            return ColorsDark;
        case EThemeOptions.Light:
            return ColorsLight;
        default: 
            return ColorsDark;
    }
};

export const getTravelColor = (
    dataObject: any
) => {
    
    let congestionLevel: ATCongestionLevel = dataObject.congestionLevel;

    switch(ATCongestionLevel[congestionLevel]){
        
        case ATCongestionLevel.Unaffected:
            return "#05A665";
        case ATCongestionLevel.Minor:
            return "#F0C149";
        case ATCongestionLevel.Moderate:
            return "#F08549";
        case ATCongestionLevel.Major:
            return "#F04949";
        default:
            return "black";

    }
}   

export const Theme = (mode: EThemeOptions) => {
    return {
        mode: mode,
        globals: Globals,
        breakpoints: Breakpoints,
        ui: UI(getColorObject(mode)),
        typography: Typography,
        colors: getColorObject(mode),
        card: Card,
        navbar: Navbar(getColorObject(mode)),
        message: Message,
        dropdown: Dropdown,
        getColorAtAlpha
    };
};

export const getDarkTheme = () => {
    
    let theme = Theme(EThemeOptions.Dark);

    return theme;
};

export const getLightTheme = () => {

    let theme = Theme(EThemeOptions.Light);

    return theme;
};

export const getDefaultTheme = () => {
    return {...Theme(EThemeOptions.Default)};
}

export const getTheme = (mod: EThemeOptions) => {

    switch(mod){
        case EThemeOptions.Dark:
            return getDarkTheme();
        case EThemeOptions.Light:
            return getLightTheme();
        default:
            return getDefaultTheme();
    }
};

export const getColorAtAlpha = (   
    colorObject: any, 
    alphaValue: number = 1
): string => {

    let red: string = colorObject.rgb.red;
    let green: string = colorObject.rgb.green;
    let blue: string = colorObject.rgb.blue;

    return `rgba(${red}, ${green}, ${blue}, ${alphaValue})`;
}

export default Theme;