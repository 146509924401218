import { IATFeedbackFormObjectInit } from "@algo/network-manager/models/v3";

export enum EInputFieldName {
    "name" = "name",
    "email" = "email",
    "company" = "company",
    "subject" = "subject",
    "comments" = "comments"
};

export type IATFeedbackFormObject =
    IATFeedbackFormObjectInit & 
    {
        nameValid: boolean | null;
        emailValid: boolean | null;
        subjectValid: boolean | null;
        commentsValid: boolean | null;

        isValid: () => boolean;
        isValidName: (val?: string) => boolean;
        isValidEmail: (val?: string) => boolean;
        isValidSubject: (val?: string) => boolean;
        isValidComments: (val?: string) => boolean;

    }

export class ATFeedbackFormObject {

    name: string;
    email: string;
    company: string;
    subject: string;
    comments: string;

    nameValid: boolean | null;
    emailValid: boolean | null;
    subjectValid: boolean | null;
    commentsValid: boolean | null;

    constructor(initObject?: IATFeedbackFormObjectInit){
        this.name = initObject?.name || "";
        this.email = initObject?.email || "";
        this.company = initObject?.company || "";
        this.subject = initObject?.subject || "";
        this.comments = initObject?.comments || "";

        this.nameValid = null;
        this.emailValid = null;
        this.subjectValid = null;
        this.commentsValid  = null;
    }

    isValid(){
        this.isValidName();
        this.isValidEmail();
        this.isValidSubject();
        this.isValidComments();
        
        return (
            this.nameValid && 
            this.emailValid && 
            this.subjectValid && 
            this.commentsValid
        ) ? true : false;
    }

    isValidName(name: string = this.name){
        // name must be greater than 1 character in length
        if (name && name.length > 1) {
            this.nameValid = true;
            return true;
        }

        else{
            this.nameValid = false;
            return false;
        }
    }

    isValidEmail(email: string = this.email){
        // email must contain an @ character
        if (email && email.length > 0 && email.indexOf("@") !== -1) {
            this.emailValid = true;
            return true;
        }
        else{
            this.emailValid = false;
            return false;
        }
    }

    isValidSubject(subject: string = this.subject){
        // the subject field must contain some content
        if (subject){
            this.subjectValid = true;
            return true;
        }
        else{
            this.subjectValid = false;
            return false;
        }
    }

    isValidComments(comments: string = this.comments){
        // the comments text area must contain some content
        if (comments) {
            this.commentsValid = true;
            return true;
        }
        else{
            this.commentsValid = false;
            return false;
        }
    }
}