// libraries
import * as React from "react";
import { useBreakpoint } from "~/library/useBreakpoint";
// styles
import * as SC from "../Styled";
// constants
import { lane_closed, lane_median, lane_open } from "~/constants";

export type IProps = {
    //
};

export const TrafficSpeedLegend: React.FC<IProps> = (props) => {

    const { md: isMediumMax } = useBreakpoint();


    return (
        <SC.StyledLegend>

            <SC.Title isMediumMax={isMediumMax}>
                {"Traffic Speed Legend"}
            </SC.Title>

            <SC.TrafficLegendItems isMediumMax={isMediumMax}>
                <span>Fast</span>
                <SC.ColorBoxStart fillColor={lane_open} />
                <SC.ColorBox fillColor={lane_median} />
                <SC.ColorBox fillColor={lane_closed} />
                <SC.ColorBoxEnd fillColor="black" />
                <span>Blocked</span>
            </SC.TrafficLegendItems>

        </SC.StyledLegend>
    );
};

export default TrafficSpeedLegend;