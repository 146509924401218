// libraries
import * as React from "react";
// hooks
import { useNavigateAfterSignout } from "./hooks";

export type IProps = {
    //
};

export const SessionEnded: React.FC<IProps> = (props) => {

    useNavigateAfterSignout("/map");

    return (
        <div>Session Ended</div>
    )
};

export default SessionEnded;

