// libraries
import { commonFilterBySearch, commonGetById_api, createAlgoSlice } from "./common";
// types & models
import { IProcessedResponse, IWeatherAlertNetworkManager, WeatherAlertNetworkManager } from "@algo/network-manager/managers/v3";
import { EAlgoApiObjectType } from "~/interfaces";
// constants
import { CUR_API_VERSION, CUR_API_ENDPOINTS } from "../../api-endpoint-strings";
import { isTesting } from "~/constants";
// test data
import T_DATA from "~/store/algo-api/test-data/active-weather-alerts/all-weather-alerts-active.json";
import TEST_ALERT from "~/store/algo-api/test-data/weather-alert-layer/first-alert-from-layer.json";
import { buildLastResponse } from "~/store/library";
import { ICWAlertDto } from "@algo/network-manager/models/v3/cw";
import { createSlice } from "@reduxjs/toolkit";

declare var __API_URL__: string;
const apiUrl: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).weatherAlerts}`;

export type IWeatherAlertsInit = {
    [key: string]: { alert: ICWAlertDto, loading: boolean };
};

export const WeatherAlertsInit: IWeatherAlertsInit = {
    
};

// create list slice
export const weatherAlertsSlice = createSlice(
    {
        name: "weather-alerts",
        initialState: WeatherAlertsInit,
        reducers: {
            begin: (state: any, action: any) => {
                state[action.payload.id] = { ...state[action.payload.id], loading: true };
            },
            success: (state: any, action: any) => {
                state[action.payload.id] = { 
                    ...state[action.payload.id],
                    alert: action.payload.data,
                    loading: false 
                };
            },
            failure: (state: any, action: any) => {
                state[action.payload.id] = { 
                    ...state[action.payload.id], 
                    alert: null,
                    loading: false 
                };
            }
        }
    }
);

// get handles for the slice's actions
const { 
    begin, success, failure
} = weatherAlertsSlice.actions;

// handles dispatching a data get by id from either api or test source based on args
export const getById = (id: string, test: boolean = isTesting, testMode?: string) => {

    return (
        dispatch: any,
        getState: any
    ) => {
        if (test)
            dispatch(getById_test(id, testMode));
        else 
            dispatch(getById_api(id));
    }
}

// retrieves data from api for this data type
export const getById_api = (id: string) => {

    return (
        dispatch: any,
        getState: any
    ) => {

        let manager = new WeatherAlertNetworkManager(apiUrl);
    
        manager.getById(buildLastResponse({}), {id}, false)
            .then(
                (response: IProcessedResponse) => {
                    if (response.error)
                        dispatch(failure({ errorMessage: response.error.message} ))
                    else {
                        dispatch(success( { id: id, data: response.getReduxObject().data} ));
                    }
                }
            ).catch(
                (error: Error) => dispatch(failure({ errorMessage: error.message } ))
            )
        }
};

// retrieves test data for this data type
export const getById_test = (id: string, mode?: string) => {
        
    return (
        dispatch: any,
        getState: any
    ) => {
    
        dispatch(success( { id: id, data: TEST_ALERT} ));

    }
};

// exports the slice's reducer ( used in store file to build up master reducer )
export const weatherAlertsReducer = weatherAlertsSlice.reducer;