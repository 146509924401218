// libraries
import { getAll as getAllCities } from "~/store/algo-api/slices/cities";
import { getAll as getAllCounties } from "~/store/algo-api/slices/counties";
import { getAll as getAllPlaces } from "~/store/algo-api/slices/places";
import { getAll as getAllCameras } from "~/store/algo-api/slices/cameras";
import { filterBySelections as filterCameras } from "~/store/algo-api/slices/cameras";
// hooks & context
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// dispatches store action creators for loading city and place data
// this data is used to populate the filter dropdowns for searching travel times
export const useDropdownData = () => {

    const dispatch = useDispatch();

    const cityStore = useSelector( (state: any) => state.city);
    const countyStore = useSelector( (state: any) => state.county);
    const placeStore = useSelector( (state: any) => state.place);

    useEffect(
        () => {

            if (!countyStore.data)
                dispatch(getAllCounties() as any);
            if (!cityStore.data)
                dispatch(getAllCities() as any);
            if (!placeStore.data)
                dispatch(getAllPlaces() as any);

        }, [countyStore.data, cityStore.data, placeStore.data]
    );

    return [cityStore, countyStore, placeStore]
};

export const useLoadCameras = () => {

    const dispatch = useDispatch();
    const cameraStore: any = useSelector( (store: any) => store["camera"]);

    useEffect(
        () => {
            dispatch(getAllCameras() as any);
        }, []
    );

    return cameraStore;
};

// filteres the content section when search selections or data stores change
export const useFilterContent = (
    searchText: string,
    cityIndices: number[], countyIndices: number[], roadwayIndices: number[], regionIndices: number[],
    cameraStore: any,
    cityStrings: string[], countyStrings: string[], roadwayStrings: string[],
    REGIONS: string[]
) => {

    const dispatch = useDispatch();

    useEffect(
        () => {
            let cities: string[] = cityIndices ? cityIndices.map ( (index: number) => cityStrings[index]) : [];
            let counties: string[] = countyIndices ? countyIndices.map ( (index: number) => countyStrings[index]) : [];
            let places: string[] = roadwayIndices ? roadwayIndices.map( (index: number) => roadwayStrings[index]) : [];
            let regions: string[] = regionIndices ? regionIndices.map( (index: number) => REGIONS[index]) : [];

            dispatch(filterCameras(cities, counties, places, regions, searchText) as any);
        }, [ 
            searchText, 
            cityIndices, countyIndices, roadwayIndices, regionIndices,
            cameraStore.data
        ]
    );
}