// libraries
import * as React from "react";
// styles
import * as SC from "./Styled";
// types & models
import { EAlgoApiObjectType } from "~/interfaces";
// components
import ApiObjectIcon from "./api-object-icon/ApiObjectIcon";
import ApiObjectDetails from "./api-object-details/ApiObjectDetails";
import ExtendedObjectDetails from "../extended-object-details/ExtendedObjectDetails";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronRight } from "@fortawesome/pro-regular-svg-icons";
// constants
import { green_light } from "~/constants";

export enum EDetailType {
    "header", "detail", "footer", "progress", "travelTime", "trafficEvent"
};

export type IDetailMap = {
    type: EDetailType;
    detail: any;
    travelDetail?: any;
};

export type IProps = {
    object: any;
    type: EAlgoApiObjectType;
    showChevron?: boolean;
    extraStyling?: boolean;
    lawEnforcement?: boolean;
};

export const ApiObjectView: React.FC<IProps> = (props) => {

    const { object, type, showChevron = true, extraStyling, lawEnforcement } = props;

    const [ showExtended, setShowExtended ] = React.useState<boolean>(false);
    
    return (
        
        <SC.StyledApiObjectView
            onClick={() => {setShowExtended( showExtended => !showExtended)}}
        >
            
            <SC.DetailsAndChevronRow>

                <SC.BasicDetailRow lawEnforcement={lawEnforcement}>
                    
                    <ApiObjectIcon object={object} type={type} />

                    <ApiObjectDetails 
                        object={object} type={type} showChevron={showChevron} extraStyling={extraStyling} />

                </SC.BasicDetailRow>

                {   showChevron && 
                    <SC.ChevronWrap>
                        {   showExtended
                            ? <FontAwesomeIcon icon={faChevronDown} color={green_light} />
                            : <FontAwesomeIcon icon={faChevronRight} color={green_light} />
                        }
                    </SC.ChevronWrap>
                }

            </SC.DetailsAndChevronRow>

            { ( showChevron && showExtended ) && 
                <ExtendedObjectDetails object={object} type={type} />
            }

        </SC.StyledApiObjectView>
    );

};

export default ApiObjectView;