// libraries
import * as React from "react";

export type IRouteCamerasOverlayContext = {
    showOverlay: boolean;
    setShowOverlay: (newVal: boolean) => void;
    toggleShowOverlay: () => void;
    routeIndex: number;
};

// route cameras overlay ui state
export const RouteCamerasOverlayContext = React.createContext({
    showOverlay: false,
    setShowOverlay: (newVal: boolean) => {},
    toggleShowOverlay: () => {},
    routeIndex: 0
});

export default RouteCamerasOverlayContext;