// libraries
import * as React from "react";
// styles
import * as SC from "../Styled";

export type IProps = {
    resumeCallback: () => void;
};

export const VBTimeoutCard: React.FC<IProps> = (props) => {

    return (
        <SC.TimeoutCard>
            <SC.TimeoutText>
                {`Still Watching?`}
            </SC.TimeoutText>

            <SC.TimeoutButton onClick={props.resumeCallback}>
                {`Resume`}
            </SC.TimeoutButton>
        </SC.TimeoutCard>
    );
};

export default VBTimeoutCard;