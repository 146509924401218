// libraries
import { getCoords as getCameraCoords } from "./cameras";
import { getCoords as getEventCoords } from "./traffic-events";
import { getCoords as getFacilityCoords } from "./state-facilities";
// types & models
import { EAlgoApiObjectType, ICoord } from "~/interfaces";

export const getCoordsFunctionByType = (
    type: string
): (object: any) => ICoord | null => {

    switch(type){
        case EAlgoApiObjectType.camera:
        case EAlgoApiObjectType["camera-group"]:
            return getCameraCoords;
        case EAlgoApiObjectType.crash:
        case EAlgoApiObjectType.incident:
        case EAlgoApiObjectType.roadwork:
        case EAlgoApiObjectType["regional-event"]:
        case EAlgoApiObjectType["road-condition"]:
            return getEventCoords;
        case EAlgoApiObjectType["state-facility"]:
            return getFacilityCoords;
        default:
            return (object: any) => { return null };
    }
}