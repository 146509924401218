// libraries
import styled from "styled-components";
import { BORDER_RADIUS } from "~/constants";

export type IProps = {
    progress?: string;
    bgColor?: string;
    height?: string;
}

export const StyledProgressBar = styled.div<IProps>`
    height: ${props => props.height ? props.height : "8px"};
    width: ${props => props.progress ? `${props.progress}%` : "100%"};
    max-width: 100%;

    background-color: ${props => props.color || "black"};

    border-radius: ${BORDER_RADIUS}px;
`;