// libraries
import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  html{
    height: 100%;
    width: 100%;
  }

  body{
    height: 100%;
    width: 100%;
    margin: 0;

    font-family: "SF Pro Text", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Noto sans", Helvetica Neue, sans-serif;
  }

  #root{
    height: 100%;
    width: 100%;
    position: absolute;

    .post {
      padding: 12px;
      padding-left: env(safe-area-inset-left);
      padding-right: env(safe-area-inset-right);
    }

    @supports(padding: max(0px)){
      .post{
        padding-left: max(12px, env(safe-area-inset-left));
        padding-right: max(12px, env(safe-area-inset-right));
      }
    }
  }
`;