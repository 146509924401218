// libraries
import * as React from "react";
// types & models
import { ISVGProps } from "~/resources/interfaces";

export const StateFacilityGlyph: React.FC<ISVGProps> = (props) => {

    const {
        width, height, viewBox, color
    } = props;

    return (
        <svg 
            width={width || "229"} height={height || "152"} 
            viewBox={ viewBox || "0 0 229 152" } fill="none" 
        >
            
            <path 
                fillRule="evenodd" clipRule="evenodd" fill={ color || "white" } 
                d="M46.5127 13.8364H185.254V0H46.5127V13.8364Z" />

            <path 
                fillRule="evenodd" clipRule="evenodd" fill={ color || "white" }
                d="M0.761719 63.9988H44.523V52.3093H0.761719V63.9988Z" />

            <path 
                fillRule="evenodd" clipRule="evenodd" fill={ color || "white" }
                d="M184.756 63.9988H228.517V52.3093H184.756V63.9988Z" />

            <path 
                fillRule="evenodd" clipRule="evenodd" fill={ color || "white" }
                d="M228.517 68.4724H166.267L150.153 17.8942H127.713L143.827 68.4724H84.4649L100.581 17.8942H78.1409L62.0249 68.4724H0.761719V82.3108H57.6149L35.7224 151.023H58.1624L80.0549 82.3108H148.237L170.129 151.023H192.569L170.677 82.3108H228.517V68.4724Z" />

        </svg>
    );
};

export default StateFacilityGlyph;