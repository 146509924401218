// libraries
import * as React from "react";
// hooks & context
import { useLayerOptions } from "./hooks";
// styles
import * as SC from "./Styled";
// components
import FullPageModal from "~/components/views/modal/FullPageModal";
import IconToggleList from "~/components/views/icon-toggle-list/IconToggleList";
import SchemeSelectList from "~/components/views/scheme-select-list/SchemeSelectList";
import { useBreakpoint } from "~/library/useBreakpoint";
// constants
import { lane_closed, lane_median, lane_open } from "~/constants";

export type IProps = {
    modalOpen: boolean;
    closeCallback: () => any;
};

export const MapLayersModal: React.FC<IProps> = (props) => {

    const { modalOpen, closeCallback } = props;

    const layerOptions = useLayerOptions();
    
    const { md: isMediumMax } = useBreakpoint();
    const { sm: isSmallMax } = useBreakpoint();

    if (!modalOpen) return null;

    return (
        <FullPageModal showCloseButton={false} closeCallback={closeCallback}>

            <SC.ModalCard large={true}>
                <IconToggleList title={"Map Layers"} options={layerOptions} closeCallback={closeCallback} />

                <SchemeSelectList title={"Map Types"} />

                {!isSmallMax && 
                    <SC.TrafficLegend isMediumMax={isMediumMax}>
                        <SC.Title isMediumMax={isMediumMax}>
                            {"Traffic Speed Legend"}
                        </SC.Title>

                        <SC.TrafficLegendItems isMediumMax={isMediumMax}>
                            <div>Fast</div>
                            <SC.ColorBoxStart fillColor={lane_open} />
                            <SC.ColorBox fillColor={lane_median} />
                            <SC.ColorBox fillColor={lane_closed} />
                            <SC.ColorBoxEnd fillColor="black" />
                            <div>Blocked</div>
                        </SC.TrafficLegendItems>
                    </SC.TrafficLegend>
                }
            </SC.ModalCard>

        </FullPageModal>
    );

};

export default MapLayersModal;