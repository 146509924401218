// library
import React from 'react';
import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';
import ReactGA from "react-ga4";
// styles
import { GlobalStyle } from './styles/GlobalStyles';
// store
import { Provider } from 'react-redux';
import store from "./store/store";
// context
import { AuthProvider } from "react-oidc-context";
// components
import App from './App';
import { oidcConfig } from './authentication/oidcConfig';
import { BrowserRouter } from 'react-router-dom';
import 'video.js/dist/video-js.css'
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// constants
import { TRACKING_ID } from "./constants";

const container = document.getElementById('root')!;
const root = createRoot(container);

//Initialize GA4 (Google Analytics)
ReactGA.initialize(TRACKING_ID);

root.render(
  <AuthProvider { ...oidcConfig}>
    <React.StrictMode>
      <Provider store={store}>
        <GlobalStyle />
        <BrowserRouter>
            <App />
        </BrowserRouter>
      </Provider>
    </React.StrictMode>
  </AuthProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
