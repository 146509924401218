// libraries
import { 
    commonGetAll_api, commonGetById_api, 
    commonFilterBySearch, createAlgoSlice 
} from "./common";
// types & models
import { TravelTimeNetworkManager } from "@algo/network-manager/managers/v3";
import { EAlgoApiObjectType, ICoord } from "~/interfaces";
// constants
import { CUR_API_VERSION, CUR_API_ENDPOINTS } from "../../api-endpoint-strings";
import { isTesting } from "~/constants"
// test data
import T_DATA from "../test-data/travel-times/all-travel-times-11-4-22.json";
import { 
    ATPlace, ATTravelTimePoint, IATPlaceDto, IATTravelTimeDto, IATTravelTimePointDto 
} from "@algo/network-manager/models/v3";

declare var __API_URL__: string;
const apiUrl: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).travelTimes}`;

// create list slice
export const travelTimeSlice = 
    createAlgoSlice(EAlgoApiObjectType["travel-time"]);

// get handles for the slice's actions
const { 
    begin, success, failure, 
    filterDataBySearch, filterDataBySelections 
} = travelTimeSlice.actions;

// handles dispatching a data get by id from either api or test source based on args
export const getById = (id: number, test: boolean = isTesting, testMode?: string) => {

    return (
        dispatch: any,
        getState: any
    ) => {
        if (test)
            dispatch(getById_test(id, testMode));
        else 
            dispatch(getById_api(id));
    }
}

// retrieves data from api for this data type
export const getById_api = (id: number) => {

    return commonGetById_api(
        new TravelTimeNetworkManager(apiUrl), 
        id, 
        travelTimeSlice
    );
    
};

// retrieves test data for this data type
export const getById_test = (id: number, mode?: string) => {
    alert("travel time: getById_test is not yet implemented.")
};

// handles dispatching a data get all from either api or test source based on args
export const getAll = (
    params?: any,
    test: boolean = isTesting, 
    testMode?: string
): any => {

    return (
        dispatch: any,
        getState: any
    ) => {

        if (getState()[EAlgoApiObjectType["travel-time"]].loading) return;

        if (test)
            dispatch(getAll_test(testMode));
        else 
            dispatch(getAll_api(params));
    }
}

// retrieves all data from api for this data type
export const getAll_api = (params: any) => {

    return commonGetAll_api(
        new TravelTimeNetworkManager(apiUrl), 
        travelTimeSlice,
        params
    );
    
};

// retrieves test data for this data type
export const getAll_test = (mode?: string) => {

    return (
        dispatch: any,
        getState: any
    ) => {

        dispatch(begin());
        
        setTimeout(
            () => {
                dispatch(success({data: T_DATA, errorMessage: null, status: 200}))
            }, 2500
        );
    }
    
};

// filters data list based on given search term
export const filterBySearch = (searchTerm: string = "") => {

    return commonFilterBySearch(travelTimeSlice, searchTerm, searchMatch);
    
};

// used to compare given city name with city values in origin and destination of given travelTime
const cityMatch = (travelTime: IATTravelTimeDto, cityName: string) => {

    // short-circuit
    if (!cityName) return true;

    let origin: IATTravelTimePointDto = travelTime.origin || new ATTravelTimePoint();
    let destination: IATTravelTimePointDto = travelTime.destination || new ATTravelTimePoint();

    if (
        cityName === origin.city ||
        cityName === destination.city
    )   return true;

    return false;
};

const roadwayMatch = (travelTime: IATTravelTimeDto, roadwayId: number) => {

    // short-circuit
    if (!roadwayId) return true;

    let origin: IATTravelTimePointDto = travelTime.origin || new ATTravelTimePoint();
    let originPlace: IATPlaceDto = origin.place || new ATPlace();
    let originIntersect: IATPlaceDto = origin.intersectingPlace || new ATPlace();

    let destination: IATTravelTimePointDto = travelTime.destination || new ATTravelTimePoint();
    let destinationPlace: IATPlaceDto = destination.place || new ATPlace();
    let destinationIntersect: IATPlaceDto = destination.intersectingPlace || new ATPlace();

    if (
        roadwayId === originPlace.id ||
        roadwayId === destinationPlace.id ||
        roadwayId === originIntersect.id ||
        roadwayId === destinationIntersect.id 
    )   return true;

    return false;
};

const searchMatch = (
    travelTime: IATTravelTimeDto, 
    searchTerm: string = "",
): boolean => {

    if (!searchTerm) return true;

    if (
        travelTime.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        travelTime.estimatedTimeMinutes?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
        travelTime.averageSpeedMph.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )   return true;

    return false;
}

export const filterBySelections = (
    cityNames: string[],
    roadwayIds: number[],
    searchTerm: string,
) => {

    return (
        dispatch: any,
        getState: any
    ) => {

        let data: any[] = getState()[EAlgoApiObjectType["travel-time"]].data;

        if (!data) return;

        if (data.length === 0) {
            dispatch(filterDataBySelections({dataFilteredBySelection: data}));
            return;
        }

        let filteredData: any[] = [];

        // if there is data...
        if (data.length > 0){
            // iterate over each travel time...
            data.forEach(
                (travelTime: IATTravelTimeDto) => {
                    // first, we check the cities for a match
                    // first, assume the cities do not match
                    let citiesMatch: boolean = false;
                    // if there are no cities selected, then consider it a match
                    if (cityNames.length === 0) citiesMatch = true;
                    else {  // if you have cities selected
                        // then check if ANY of those selected cities are a match
                        for (let i = 0; i < cityNames.length; i++){
                            if (cityMatch(travelTime, cityNames[i])) {
                                // if the city was a match, set the boolean and break the loop
                                citiesMatch = true;
                                break;
                            }
                        }
                    }

                    // next, we check the roadways for a match
                    // first, assume there are no matching roadways
                    let roadwaysMatch: boolean = false;
                    // if there are no roadways selected, then consider it a match
                    if (roadwayIds.length === 0) roadwaysMatch = true;
                    else {
                        // then check if ANY of those selected roadways are a match
                        for (let i = 0; i < roadwayIds.length; i++){
                            // if the roadway was a match, set the boolean and break the loop
                            if (roadwayMatch(travelTime, roadwayIds[i])){
                                roadwaysMatch = true;
                                break;
                            }
                        }
                    }

                    // last, we check that search also matches
                    // if all matches are true, add this travelTime to the filteredList
                    if (
                        citiesMatch &&
                        roadwaysMatch &&
                        searchMatch(travelTime, searchTerm)
                    ){
                        filteredData.push(travelTime);
                    }
                }
            )
        }

        dispatch(filterDataBySelections({dataFilteredBySelections: filteredData}));
    }
}

// this function is utilized by slices' 'filterData' function
// to determine the coordinate location of camera object for geo-filtering
export const getCoords = (
    item: IATTravelTimeDto
): ICoord | null => {

    return null;

};

// exports the slice's reducer ( used in store file to build up master reducer )
export const travelTimeReducer = travelTimeSlice.reducer;