// libraries
import { flexSmart, RemoveScrollbar } from "@caps-mobile/styled-lib";
import styled from "styled-components";
import { BORDER_RADIUS, NAV_WIDTH_MINI, NAV_WIDTH_OPEN } from "~/constants";

export type IPanel = {
    panelOpen?: boolean;
}

export const StyledNavPanel = styled.div<IPanel>`
    width: ${NAV_WIDTH_OPEN}px;
    height: 100%;

    padding: 0px 20px 5px 20px;

    box-sizing: border-box;

    opacity: 1;

    ${props => props.panelOpen 
        ? `` 
        : `
            width: ${NAV_WIDTH_MINI}px;
        `
    }

    background-color: rgba(245, 245, 247, 1);
    border-right: thin solid rgba(212, 212, 212, 1);

    transition: width .5s ease-out, opacity ${ props => props.panelOpen ? "1.5s" : ".0s" } .25s;
`

export const LogoRow = styled.div<IPanel>`
    width: 100%;
    height: 42px;
    display: flex;
    align-items: center;

    ${props => props.panelOpen 
        ?   `justify-content: space-between;`
        :   `justify-content: center;`
    }
    

`;

export const LogoWrap = styled.div`
    height: 36px; 
    ${flexSmart("flex-start center")}
`;

export const ToggleWrapper = styled.div`
    height: 100%;
    font-size: 18px;

    display: flex;
    align-items: center;

    :hover {
        cursor: pointer;
    }
`;

export const NavTitle = styled.div<IPanel>`
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;

    ${props => props.panelOpen ? `display: none;` : ""}

    color: ${props => props.theme.colors["text-primary"].rgba};
`;

export const NavContentWrapper = styled.div<IPanel>`
    height: calc(100% - 42px);
    width: 100%;

    padding-top: 20px;
    box-sizing: border-box;

    ${RemoveScrollbar}
`;

export const StyledNavSection = styled.div<{panelOpen?: boolean}>`
    padding-bottom: 20px;
    box-sizing: border-box;

    ${props => props.panelOpen ? `` : `padding-bottom: 0px;`}
`;

export const SectionDivider = styled.div`
    width: 100%;
    height: 2px;

    background-color: #8E8E93;

    border: none;
    border-radius: ${BORDER_RADIUS}px;
`;