// libraries
import * as React from "react";
import { useNavigate } from "react-router-dom";
// styles
import * as SC from "./Styled";
// components
import BackButton from "../back-button/BackButton";
import SearchBar from "../search-bar/SearchBar";
import { useBreakpoint } from "~/library/useBreakpoint";

export type ISearchBarProps = {
    autoSubmit?: boolean;
    submitCallback?: (searchText: string) => void;
};

export type IProps = {
    title?: string;
    children?: React.ReactNode;
    backButton?: boolean;
    backButtonCallback?: () => void;
    searchBar?: ISearchBarProps;
};

export const PageTemplate: React.FC<IProps> = (props) => {

    const { title, backButton, backButtonCallback, searchBar } = props;

    const navigate = useNavigate();

    return (
        <SC.StyledPageTemplate>
            
            {   (title || backButton) &&
                <SC.BackAndTitleWrap>

                    {  backButton &&
                        <BackButton callback={() => backButtonCallback && backButtonCallback()} />
                    }

                    { title &&
                        <SC.Title>
                            {title}
                        </SC.Title>
                    }

                </SC.BackAndTitleWrap>
            }

            { searchBar && 
                <SC.SearchRow>
                    <SearchBar autoSubmit={searchBar.autoSubmit}
                        submitCallback={
                            (evt: any, searchText: string) => { 
                                if (searchBar.submitCallback)
                                    searchBar.submitCallback(searchText);
                            }
                        }
                    />
                </SC.SearchRow>
            }

            <SC.Content>
                {props.children}
            </SC.Content>

        </SC.StyledPageTemplate>
    );
};

export default PageTemplate;