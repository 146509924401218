// libraries
import { commonFilterBySearch, commonGetById_api, createAlgoSlice } from "./common";
// types & models
import { IProcessedResponse, WeatherAlertNetworkManager } from "@algo/network-manager/managers/v3";
import { EAlgoApiObjectType } from "~/interfaces";
// constants
import { CUR_API_VERSION, CUR_API_ENDPOINTS } from "../../api-endpoint-strings";
import { isTesting } from "~/constants";
// test data
import T_DATA from "~/store/algo-api/test-data/active-weather-alerts/all-weather-alerts-active.json";
import { buildLastResponse } from "~/store/library";
import { ICWAlertDto } from "@algo/network-manager/models/v3/cw";

declare var __API_URL__: string;
const apiUrl: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).weatherAlerts}`;

// create list slice
export const activeWeatherAlertSlice = 
    createAlgoSlice(EAlgoApiObjectType["active-weather-alert"]);

// get handles for the slice's actions
const { 
    begin, success, failure,
    beginInit, successInit, failureInit,
} = activeWeatherAlertSlice.actions;

// handles dispatching a data get by id from either api or test source based on args
export const getById = (id: number, test: boolean = isTesting, testMode?: string) => {

    return (
        dispatch: any,
        getState: any
    ) => {
        if (test)
            dispatch(getById_test(id, testMode));
        else 
            dispatch(getById_api(id));
    }
}

// retrieves data from api for this data type
export const getById_api = (id: number) => {

    return commonGetById_api(
        new WeatherAlertNetworkManager(apiUrl), 
        id, 
        activeWeatherAlertSlice
    );
    
};

// retrieves test data for this data type
export const getById_test = (id: number, mode?: string) => {
    
    alert("active-weather-alert: getById_test is not yet implemented.")
};

// handles dispatching a data get all from either api or test source based on args
export const getAll = (mode?: string, test: boolean = isTesting): any => {

    return (
        dispatch: any,
        getState: any
    ) => {

        if (getState()[EAlgoApiObjectType["active-weather-alert"]].loading) return;

        if (test)
            dispatch(getAll_test(mode));
        else 
            dispatch(getAll_api(mode));
    }
}

// retrieves all data from api for this data type
export const getAll_api = (mode?: string) => {

    return (
        dispatch: any,
        getState: any
    ) => {

        let beginFunction = begin;
        let successFunction = success;
        let failureFunction = failure;
        if (mode === "init") {
            beginFunction = beginInit;
            successFunction = successInit;
            failureFunction = failureInit;
        }

        dispatch(beginFunction());

        let manager: WeatherAlertNetworkManager = new WeatherAlertNetworkManager(apiUrl);

        manager.getActive(buildLastResponse(getState()), {}, false)
            .then(
                (response: IProcessedResponse) => {
                    if (response.error)
                        dispatch(failureFunction({ errorMessage: response.error.message}))
                    else 
                        dispatch(successFunction(response.getReduxObject()))
                }
            ).catch(
                (error: Error) => dispatch(failureFunction({ errorMessage: error.message }))
            )
    }

};

// retrieves test data for this data type
export const getAll_test = (mode?: string) => {

    return (
        dispatch: any,
        getState: any
    ) => {

        let beginFunction = begin;
        let successFunction = success;
        let failureFunction = failure;
        if (mode === "init") {
            beginFunction = beginInit;
            successFunction = successInit;
            failureFunction = failureInit;
        }

        dispatch(beginFunction());
        
        setTimeout(
            () => {
                dispatch(successFunction({data: T_DATA, errorMessage: null, status: 200}))
            }, 2500
        );
    }
    
};

// filters data list based on given search term
export const filterBySearch = (searchTerm: string = "") => {

    return commonFilterBySearch(activeWeatherAlertSlice, searchTerm, searchMatch);
    
};

const searchMatch = (alert: ICWAlertDto, searchTerm: string = ""): boolean => {

    if (!searchTerm) return true;

    if (
        alert.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        alert.headline?.toLowerCase().includes(searchTerm.toLowerCase())
    ) return true;

    return false;
}

// exports the slice's reducer ( used in store file to build up master reducer )
export const activeWeatherAlertReducer = activeWeatherAlertSlice.reducer;