// libraries
import styled from "styled-components";
import { flexSmart } from "@caps-mobile/styled-lib";
// constants
import { BORDER_RADIUS, BOX_SHADOW } from "~/constants";
import { whenSmallMax } from "~/theme";

export const StyledSchemeSelectOption = styled.div`
    min-width: 33%;

    padding: 20px 0;
    box-sizing: border-box;

    ${flexSmart("center center")}
    flex-direction: column;

    :hover{
        cursor: pointer;
    }

    ${whenSmallMax(`
        min-width: 100%;
    `)}
`;

export const ToggleOptionImgWrapper = styled.div<{isActive?: boolean, bgColor?: string}>`

    ${flexSmart("center center")}

    padding: 3px;
    box-sizing: border-box;

    background-color: ${props => props.isActive ? props.bgColor : "transparent"};

    box-shadow: ${BOX_SHADOW};

    border: ${props => props.isActive ? "2px solid rgba(1, 181, 97, 1)" : "none"};
    border-radius: ${BORDER_RADIUS}px;

    opacity: ${props => props.isActive ? "1" : "0.5"};

    overflow: hidden;

    :hover{
        opacity: 1;
    }

    > img{
        border-radius: ${BORDER_RADIUS - 1}px;
    }
`;

export const SchemeOptionLabel = styled.div`

    padding-top: 10px;

    font-size: 16px;
    font-weight: 400;

    white-space: wrap;

    text-transform: capitalize;
    text-align: center;
`;