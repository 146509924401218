// libraries
import * as React from "react";
// styles
import * as PageStyles from "../../views/page-template/Styled";
// types & models
// components
import PageTemplate from "../../views/page-template/PageTemplate";
import CommentsForm from "./CommentsForm";
// constants
const text1: string = `Alabama Department of Transportation welcomes your comments.`;
const text2: string = `If you are in an emergency roadside situation, please call 911 or *HP for immediate assistance.`;

export type IProps = {
    //
};

export const CommentsPage: React.FC<IProps> = (props) => {

    return (
        <PageTemplate title={"Comments"}>

            <PageStyles.PageText style={{marginBottom: "20px"}}>
                {text1}<b> Note: </b>{text2}
            </PageStyles.PageText>

            <CommentsForm />

        </PageTemplate>
    )
}

export default CommentsPage;