// libraries
import * as React from "react";
import BannerOption, { EDefaultValue } from "~/components/views/banner-option/BannerOption";
import { BannerOptionSection } from "~/components/views/banner-option/Styled";
// styles
import * as SC from "./Styled";
import * as BannerStyles from "~/components/views/banner-option/Styled";
// types & models
import { IDocObject } from "~/interfaces";
import { algo_green_text } from "~/constants";
// constants
const titleText: string = `Disabling Location Services:`;
const explainText: string = 
    `Due to browser permissions api standards, ` +
    `it is not possible to disable Location Services from ` +
    `within this app. You will need to use your browser tools to disable ` +
    `these services yourself. Below are links to instructions for some common platforms.`;

const explainText2: string = 
    `Once you have successfully disabled location services via your browser's tools, 
    refresh this page and Site Permissions should then display: Location Services Off`;

const DOC_STRINGS: IDocObject[] = [
    {
        title: "Apple iOS",
        url: `https://support.apple.com/en-us/HT207092`
    },
    {
        title: "Safari",
        url: `https://support.apple.com/guide/personal-safety/control-who-can-access-your-location-ips9bf20ad2f/web`
    },
    {
        title: "Google Android",
        url: `https://support.google.com/accounts/answer/3467281?hl=en`
    },
    {
        title: "Chrome Browser",
        url: `https://support.google.com/chrome/answer/142065?hl=en&co=GENIE.Platform%3DDesktop&oco=0`
    },
    {
        title: "Firefox Browser",
        url: `https://support.mozilla.org/en-US/kb/does-firefox-share-my-location-websites`
    }
];

export type IProps = {
    doneCallback: () => void;
};

export const ToggleInstructions: React.FC<IProps> = (props) => {

    const {doneCallback} = props;

    return (
        <SC.StyledPermissionInstructions>

            <SC.TitleLine>{titleText}</SC.TitleLine>

            <SC.DoneText onClick={doneCallback}>
                {`Done`}
            </SC.DoneText>

            <SC.Content>
                <SC.ExplainText>{explainText}</SC.ExplainText>

                <SC.LinksSection>

                    {DOC_STRINGS.map(
                        (docObject: IDocObject, index: number) => {
                            return (
                                <BannerOptionSection key={`${docObject.title}${index}`}>
                                    <BannerOption key={docObject.title}
                                        clickCallback={() => { window.open(docObject.url)}}
                                        label={
                                            <React.Fragment>
                                                <BannerStyles.OptionLabel color={algo_green_text}>
                                                    {docObject.title}
                                                </BannerStyles.OptionLabel>
                                            </React.Fragment>
                                        } 
                                        defaultValue={EDefaultValue["external-link"]}
                                    />
                                </BannerOptionSection>
                            );
                        }
                    )}

                </SC.LinksSection>

                <SC.ExplainText>{explainText2}</SC.ExplainText>

            </SC.Content>

        </SC.StyledPermissionInstructions>
    );
};

export default ToggleInstructions;