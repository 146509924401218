// libraries
import * as React from "react";
import PageTemplate from "~/components/views/page-template/PageTemplate";
// hooks & context
import SiteNavContext from "~/navigation/SiteNavContext";
// styles
import * as PageStyles from "../../views/page-template/Styled";
// constants
const termsOfUseText: string = 
    `The ALGOTraffic application is a system used to notify users of upcoming events`+ 
    ` along the Alabama roadways. ALGOTraffic does not guarantee the accuracy of the` +
    ` data. Do not use ALGOTraffic while driving.`;

export type IProps = {
    //
};

export const TermsOfUsePage: React.FC<IProps> = (props) => {

    const navContext = React.useContext(SiteNavContext);

    return (
        <PageTemplate title={"Terms of Use"} 
            backButton={true} backButtonCallback={() => navContext.navigate("/about")}
        >

            <PageStyles.PageText>
                {termsOfUseText}
            </PageStyles.PageText>

        </PageTemplate>
    );
};

export default TermsOfUsePage;