// libraries
import { absoluteArrayBuilder, flexSmart } from "@caps-mobile/styled-lib";
import styled from "styled-components";
import { BORDER_RADIUS, tickerHeight } from "~/constants";

export type IOverlayProps = {
    showOverlay?: boolean, 
    tickerCount?: number
};

export const StyledDataSearchOverlay = styled.div<IOverlayProps>`

    ${absoluteArrayBuilder(["auto", "0", "0", "auto"])}
    z-index: 2;

    height: ${props => props.showOverlay 
        ? `calc(100% - ${(props.tickerCount || 0) * tickerHeight}px)` 
        : "0px"
    };

    width: 100%;

    opacity: ${props => props.showOverlay ? "1" : "0"};

    background-color: ${props => props.theme.colors["bg-primary"].rgba};

    transition: height .5s ease-out, opacity 1s;
`;

export const OverlayContent = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
`;

export const SearchRow = styled.div`
    width: 100%;
    ${flexSmart("space-between center")}
    margin-bottom: 10px;
`;

export const SearchBarWrap = styled.div`
    width: 100%;
`;

export const Title = styled.div`
    width: 100%;

    box-sizing: border-box;

    font-size: 35px;
    line-height: 108.36%;
    font-weight: 600;
`;

export const BackAndTitleWrap = styled.div`
    width: 100%;
    ${flexSmart("flex-start center")}
    flex-direction: row;

    padding-bottom: 20px;

    >:first-child{
        margin-right: 20px;
    }
`;

export const Locations = styled.div`
    width: 100%;
    box-sizing: border-box;

    background-color: ${props => props.theme.colors["bg-grey"].rgba};
    border: none;
    border-radius: ${BORDER_RADIUS}px;
`;

export const MyLocationSection = styled.div`
    width: 100%;
    box-sizing: border-box;
    margin-top: 20px;

    background-color: ${props => props.theme.colors["bg-grey"].rgba};
    border: none;
    border-radius: ${BORDER_RADIUS}px;
`;

export const Location = styled.div<{styleOverwrite?: string}>`
    width: 100%;
    
    padding: 10px 20px;
    box-sizing: border-box;

    ${flexSmart("start center")}

    :first-child{
        border-top-right-radius: ${BORDER_RADIUS}px;
        border-top-left-radius: ${BORDER_RADIUS}px;
    }

    :last-child{
        border-bottom-right-radius: ${BORDER_RADIUS}px;
        border-bottom-left-radius: ${BORDER_RADIUS}px;
    }

    :hover{
        cursor: pointer;
    }

    ${props => props.styleOverwrite ? props.styleOverwrite : ""}
`;

export const LocationIconAndText = styled.div<{styleOverwrite?: string}>`
    width: 98%;

    ${flexSmart("start center")}

    :hover{
        cursor: pointer;
    }

    ${props => props.styleOverwrite ? props.styleOverwrite : ""}
`;

export const LocationIcon = styled.div<{styleOverwrite?: string, myLocation?: boolean, locationHistory?: boolean, xMark?: boolean}>`
    color: ${props => props.myLocation ? '#135DA7' : 
                props.locationHistory ? '#575EFF' : 
                props.xMark ? `#8E8E93` : '#FF9900'};

    ${props => props.styleOverwrite ? props.styleOverwrite : ""}
`;

export const LocationText = styled.span<{styleOverwrite?: string}>`
    padding-left: 15px;

    ${props => props.styleOverwrite ? props.styleOverwrite : ""}
`;

export const MyLocationText = styled.span<{styleOverwrite?: string}>`
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;

    ${props => props.styleOverwrite ? props.styleOverwrite : ""}
`;

export const LocationName = styled.span<{styleOverwrite?: string}>`
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    ${props => props.styleOverwrite ? props.styleOverwrite : ""}
`;

export const LocationAddress = styled.div<{styleOverwrite?: string}>`
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;

    ${props => props.styleOverwrite ? props.styleOverwrite : ""}
`;