// libraries
import styled from "styled-components";
import { flexSmart } from "@caps-mobile/styled-lib";
import { BORDER_RADIUS } from "~/constants";
import { whenSmallMax } from "~/theme";

export const StyledExtendedObjectDetails = styled.div`
    height: 100%;
    width: 100%;
`;

export const GraphicRow = styled.div<{fullHeight?: boolean}>`
    height: auto;
    width: 100%;

    margin: 20px 0 0 0;
    box-sizing: border-box;

    ${flexSmart("center start")}

    ${whenSmallMax(`
        flex-wrap: wrap;
    `)} 
`;

    export const MapImageColumn = styled.div<{isCamera?: boolean}>`
        height: 100%;

        ${props => props.isCamera ? `width: 100%;` : `min-width: 55%;`}

        border-radius: ${BORDER_RADIUS}px;

        ${whenSmallMax(`
            min-width: 100%;
        `)}
    `;
    
    export const MoreDetailsColumn = styled.div`
        height: 100%;
        width: 100%;

        ${flexSmart("center center")}
        min-width: 45%;

        ${whenSmallMax(`
            min-width: 100%;
            margin-top: 10px;
        `)}
    `;