// libraries
import * as React from "react";
// styles
import * as SC from "./Styled";
// components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-regular-svg-icons";
// constants
import { algo_green_text, searchTimeout } from "~/constants";


export type IProps = {
    submitCallback?: (evt: any, searchText: string) => any;
    autoSubmit?: boolean;
    styleOverwrite?: string;
    timeout?: number;
};

export const SearchBar: React.FC<IProps> = (props) => {

    const [searchText, setSearchText] = React.useState<string>("");
    const searchPlaceholder: string = "Search";
    const [timer, setTimer] = React.useState<NodeJS.Timeout | undefined>();

    const handleValueChange = (evt: any) => {

        let currentValue: string = evt.currentTarget.value;
        setSearchText(currentValue);

        // if the key was Enter, then just immediately trigger submit callback
        if (evt.key === "Enter") {
            props.submitCallback && props.submitCallback(evt, currentValue);
        }
        // if key was not escape, and autoSubmit is enabled, then wait 1/2 second and trigger
        else if (props.autoSubmit && props.submitCallback && evt.key !== "Escape") {
            if (timer)
                clearTimeout(timer);

            const newTimer = setTimeout(() => {
                props.submitCallback!(evt, currentValue);
            }, props.timeout ?? searchTimeout);

            setTimer(newTimer);
        }
        
    }

    return (
        <SC.StyledSearchBar
            styleOverwrite={props.styleOverwrite}
        >

            <SC.IconClickWrap
                onClick={(evt: any) => { 
                    if (props.submitCallback) props.submitCallback(evt, searchText)
                }}
            >

                <FontAwesomeIcon
                    className="fa-fw"
                    icon={faSearch}
                    size={"1x"}
                    color={algo_green_text}
                />

            </SC.IconClickWrap>

            <SC.SearchText
                id={"search"}
                placeholder={searchPlaceholder}
                value={searchText}
                onChange={(evt: any) => handleValueChange(evt)}
            />

        </SC.StyledSearchBar>
    );
};

export default SearchBar;