// libraries
import * as React from "react";
import { getCoordsFunctionByType } from "~/store/algo-api/slices";
import { isTrafficEvent } from "~/store/algo-api/slices/traffic-events";
// styles
import * as SC from "./Styled";
// types & models
import { EAlgoApiObjectType } from "~/interfaces";
// components
import CameraDetails from "./detail-types/camera-details/CameraDetails";
import CamerasSection from "./cameras-section/CamerasSection";
import FerryDetails from "./detail-types/ferry-details/FerryDetails";
import MapImage from "./map-section/MapImage";
import MessageSignDetails from "./detail-types/message-sign-details/MessageSignDetails";
import Other511Details from "./detail-types/other-511-details/Other511Details";
import StateFacilityDetails from "./detail-types/state-facility-details/StateFacilityDetails";
import TrafficEventDetails from "./detail-types/traffic-event-details/TrafficEventDetails";
import WeatherAlertDetails from "./detail-types/weather-alert-details/WeatherAlertDetails";
import CameraGroupDetails from "./detail-types/camera-group-details/CameraGroupDetails";
// constants
import { D_MAP_CENTER } from "~/constants";

export type IProps = {
    object: any;
    type: EAlgoApiObjectType;
};

export const ExtendedObjectDetails: React.FC<IProps> = (props) => {

    const { type, object } = props;

    if (isNoDetailsType(type)) return null;

    const hasMap: boolean = isTypeWithMap(type);
    const hasMoreDetails: boolean = isTypeWithMoreDetails(type);
    const hasCameras: boolean = isTypeWithCameras(type);

    return (
        <SC.StyledExtendedObjectDetails>

            <SC.GraphicRow fullHeight={!hasCameras}>

                { hasMap && 
                    <SC.MapImageColumn isCamera={(type === EAlgoApiObjectType.camera || type === EAlgoApiObjectType["camera-group"])}>
                        <MapImage coords={getCoordsFunctionByType(type)(object) || D_MAP_CENTER} id={object.id} type={type} />
                    </SC.MapImageColumn>
                }

                { hasMoreDetails && type !== EAlgoApiObjectType.camera && type !== EAlgoApiObjectType["camera-group"] &&
                    <SC.MoreDetailsColumn>
                        { isTrafficEvent(type) && <TrafficEventDetails object={object} /> }
                        { ( type === EAlgoApiObjectType.other511 ) && <Other511Details object={object} /> }
                        { ( type === EAlgoApiObjectType.ferry ) && <FerryDetails object={object} /> }
                        { ( type === EAlgoApiObjectType["message-sign"] ) && <MessageSignDetails object={object} /> }
                        { ( type === EAlgoApiObjectType["state-facility"] ) && <StateFacilityDetails object={object} /> }
                        { ( type === EAlgoApiObjectType["weather-alert"] ) && <WeatherAlertDetails object={object} /> }
                    </SC.MoreDetailsColumn>
                }

            </SC.GraphicRow>

            { hasCameras && <CamerasSection object={object} type={type} /> }

        </SC.StyledExtendedObjectDetails>
    );
};

export default ExtendedObjectDetails;

export const isNoDetailsType = (type: EAlgoApiObjectType): boolean => {

    if (
        type === EAlgoApiObjectType["aldot-message"] ||
        type === EAlgoApiObjectType["alea-alert"]
    ) return true;

    return false;
};

export const isTypeWithMap = (type: EAlgoApiObjectType): boolean => {

    if (isTrafficEvent(type)) return true;

    switch(type){
        case EAlgoApiObjectType.camera:
        case EAlgoApiObjectType["camera-group"]:
        case EAlgoApiObjectType.ferry:
        case EAlgoApiObjectType["message-sign"]:
        case EAlgoApiObjectType["state-facility"]:
            return true;
        default:
            return false;
    }
};

export const isTypeWithMoreDetails = (type: EAlgoApiObjectType): boolean => {

    if (isTrafficEvent(type)) return true;

    switch(type){
        case EAlgoApiObjectType.camera:
        case EAlgoApiObjectType["camera-group"]:
        case EAlgoApiObjectType.ferry:
        case EAlgoApiObjectType["message-sign"]:
        case EAlgoApiObjectType["state-facility"]:
        case EAlgoApiObjectType.other511:
        case EAlgoApiObjectType["weather-alert"]:
            return true;
        default:
            return false;
    }
    
};

export const isTypeWithCameras = (type: EAlgoApiObjectType): boolean => {

    if (isTrafficEvent(type)) return true;

    switch(type){
        case EAlgoApiObjectType["camera-group"]:
        case EAlgoApiObjectType.camera:
        case EAlgoApiObjectType["weather-alert"]:
            return true;
        default:
            return false;
    }
};