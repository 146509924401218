import { useEffect } from "react";

export const useSetZoomAndCenter = (
    mapAndPlatform: [H.Map | undefined, H.service.Platform | undefined],
    boundBox: H.geo.Rect | undefined
) => {
    useEffect(
        () => {
            let map: H.Map | undefined = mapAndPlatform[0];
            let platform: H.service.Platform | undefined = mapAndPlatform[1];

            if (map && platform) {
               if (boundBox) {
                    map.getViewModel().setLookAtData({
                        bounds: boundBox
                    });
                }
            }

        }, [mapAndPlatform[0], mapAndPlatform[1], boundBox]
    );
};