// libraries
import * as React from "react";
// styles
import * as SC from "./Styled";
// hooks & contexts
import { useSelector } from "react-redux";
import { DetailModalContext, IDetailModalContext } from "~/contexts/DetailModalContext";
// types & models
import { 
    EATAleaAlertType, IATAldotMessageDto, IATAleaAlertDto 
} from "@algo/network-manager/models/v3";
import { EAlgoApiObjectType } from "~/interfaces";
// components
import ObjectDetailModal from "../modal/object-detail-modal/ObjectDetailModal";
import { Ticker } from "./Ticker";
// constants
import { green_ticker } from "~/constants";

export type IProps = {
    //
};

export const AlertAndMessageTicker: React.FC<IProps> = (props) => {

    const aleaAlertStore: any = 
        useSelector( (state: any) => state["alea-alert"]);
    
    const aldotMessageStore: any = 
        useSelector( (state: any) => state["aldot-message"]);

    const detailModalContext: IDetailModalContext = React.useContext(DetailModalContext);

    const aleaContent: React.ReactNode[] = mapDataToContentString(detailModalContext, aleaAlertStore.data, "alea");
    const aldotContent: React.ReactNode[] = mapDataToContentString(detailModalContext, aldotMessageStore.data, "aldot", );

    // add the aleaContent...
    let fullContentArray: React.ReactNode[] = [aleaContent];
    // if there were alea alerts and aldot messages, then add a middot divider...
    if (aleaContent.length > 0 && aldotContent.length > 0) 
        fullContentArray.push(htmlMapDot("middot-key"));
    // add the aldotContent
    fullContentArray.push(aldotContent);

    return (
        <Ticker 
            color={green_ticker}
            content={fullContentArray}
        />
    );

};

export default AlertAndMessageTicker;

const htmlMapDot = (key: string) => {
    return <span key={key}>&nbsp;&nbsp;&middot;&nbsp;&nbsp;</span>;
}

const mapDataToContentString = (
    detailModalContext: IDetailModalContext, 
    data?: IATAleaAlertDto[] | IATAldotMessageDto[], 
    type: string = "alea"
) => {

    // if there is no data, return empty array
    if (!data || data.length === 0) return [];

    // array to hold the spans of alert text
    let contentArray: any[] = [];

    for(let i = 0; i < data.length; i++){
        let nextAlert: any = data[i];

        // if we are dealing with an alea alert...
        if (type === "alea"){
            let alert: IATAleaAlertDto = nextAlert;
            // AND that alea alert is an AMBER alert...
            if (alert.type.toLowerCase() === EATAleaAlertType.Amber.toLowerCase()){

                // first, if we are NOT dealing with the first content list candidate, we should
                // pre-pend a middot for readability
                if (contentArray.length > 0){
                    contentArray.push(htmlMapDot(`${nextAlert.id + i}`));
                }

                // then, add it to the content array
                contentArray.push(
                    <SC.TickerItem key={alert.id} 
                        onClick={ () => {
                            detailModalContext.setModalContent(
                                <ObjectDetailModal 
                                    object={{ type: EAlgoApiObjectType["alea-alert"], data: alert}} 
                                    doneCallback={() => detailModalContext.setShowModal(false)} 
                                />
                            );
                            detailModalContext.setShowModal(true);
                        }
                    }>
                        {`${alert.title}: ${(alert as any).text}`}
                    </SC.TickerItem>
                )
            }           
        }
        // otherwise, add any ALDOT message
        else {
            let message: IATAldotMessageDto = nextAlert;

            // first, if we are NOT dealing with the first content list candidate, we should
            // pre-pend a middot for readability
            if (contentArray.length > 0){
                contentArray.push(`${nextAlert.id + i}`);
            }

            // then, add it to the content array
            contentArray.push(
                <SC.TickerItem key={message.id}
                    onClick={ () => {
                        detailModalContext.setModalContent(
                            <ObjectDetailModal 
                                object={{ type: EAlgoApiObjectType["aldot-message"], data: message}} 
                                doneCallback={() => detailModalContext.setShowModal(false)} 
                            />
                        );
                        detailModalContext.setShowModal(true);
                    }
                }>
                    {`${message.title}: ${message.body}`}
                </SC.TickerItem>
            )
        }
    }

    return contentArray;
}