// libraries
import styled from "styled-components";
import { flexSmart } from "@caps-mobile/styled-lib";
import { algo_green_text, BORDER_RADIUS } from "../../../constants";
import { whenSmallMax, whenSmallMin } from "~/theme";

export const Form = styled.div`
    width: 100%;
    
    > :not(:first-child){
        margin-top: 20px;
    }
`;

export const FormRow = styled.div`
    width: 100%;
    
    ${flexSmart("space-between center")}

    > :not(:first-child){
        margin-left: 10px;
    }
`;

export const FormSubmitRow = styled.div`
    width: 100%;
    
    ${flexSmart("space-between center")}

    ${whenSmallMin(`
        > :not(:first-child){
            margin-left: 10px;
        }
    `)}

    ${whenSmallMax(`
        flex-direction: column;
        align-items: start;
        gap: 10px;
    `)}
`;

export const FormInput = styled.input<{isValid?: boolean | null}>`
    height: 39px;
    width: 100%;

    overflow: hidden;
    text-overflow: ellipsis;
    
    flex-grow: 1;

    padding: 10px 20px;
    box-sizing: border-box;

    border: 1px solid ${props => props.isValid === false ? "red" : algo_green_text};
    border-radius: ${BORDER_RADIUS}px;

    font-weight: 500;
    font-family: "SF Pro Text", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Noto sans", Helvetica Neue, sans-serif;

    :focus-visible{
        outline: none;
        border: 1px solid ${props => props.theme.colors["border-primary"]["rgba"]};
        box-shadow: 0px 0px 10px 0px ${props => props.theme.colors["border-primary-old"]["rgba"]};
        -webkit-box-shadow: 0px 0px 10px 0px ${props => props.theme.colors["border-primary-old"]["rgba"]};
        -moz-box-shadow: 0px 0px 10px 0px ${props => props.theme.colors["border-primary-old"]["rgba"]};
    }

    :focus{
        outline: none;
    }
`;

export const FormTextArea = styled.textarea<{isValid?: boolean | null}>`
    width: 100%;
    height: 170px;

    padding: 10px 20px;
    box-sizing: border-box;

    border: 1px solid ${props => props.isValid === false ? "red" : algo_green_text};
    border-radius: ${BORDER_RADIUS}px;

    font-weight: 500;
    font-family: "SF Pro Text", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Noto sans", Helvetica Neue, sans-serif;

    :focus-visible{
        outline: none;
        border: 1px solid ${props => props.theme.colors["border-primary"]["rgba"]};
        box-shadow: 0px 0px 10px 0px ${props => props.theme.colors["border-primary-old"]["rgba"]};
        -webkit-box-shadow: 0px 0px 10px 0px ${props => props.theme.colors["border-primary-old"]["rgba"]};
        -moz-box-shadow: 0px 0px 10px 0px ${props => props.theme.colors["border-primary-old"]["rgba"]};
    }

    :focus{
        outline: none;
    }
`;

export const SubmitButton = styled.button`
    width: 90px;
    height: 39px;

    background: ${algo_green_text};
    border: none;
    border-radius: ${BORDER_RADIUS}px;

    color: #fff;
    font-weight: 500;

    :hover{
        cursor: pointer;
    }
`;