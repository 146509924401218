// libraries
import * as React from "react";
// types & models
import { ISVGProps } from "~/resources/interfaces";

export const MessageSignGlyph: React.FC<ISVGProps> = (props) => {

    const {
        width, height, viewBox, color
    } = props;

    return (
        <svg 
            width={width || "234"} height={height || "231"} 
            viewBox={ viewBox || "0 0 234 231" } fill="none" 
        >
            <path 
                fillRule="evenodd" clipRule="evenodd" fill={color || "#858585"}
                d="M23.2102 42.7592C35.4819 42.7592 45.4301 33.2134 45.4301 21.4381C45.4301 9.66283 35.4819 0.117065 23.2102 0.117065C10.9384 0.117065 0.990234 9.66283 0.990234 21.4381C0.990234 33.2134 10.9384 42.7592 23.2102 42.7592Z" />
            <path 
                fillRule="evenodd" clipRule="evenodd" fill={color || "#858585"}
                d="M85.9485 42.7592C98.2202 42.7592 108.168 33.2134 108.168 21.4381C108.168 9.66283 98.2202 0.117065 85.9485 0.117065C73.6767 0.117065 63.7285 9.66283 63.7285 21.4381C63.7285 33.2134 73.6767 42.7592 85.9485 42.7592Z" />
            <path 
                fillRule="evenodd" clipRule="evenodd" fill={color || "#858585"}
                d="M148.688 42.7592C160.959 42.7592 170.908 33.2134 170.908 21.4381C170.908 9.66283 160.959 0.117065 148.688 0.117065C136.416 0.117065 126.468 9.66283 126.468 21.4381C126.468 33.2134 136.416 42.7592 148.688 42.7592Z" />
            <path 
                fillRule="evenodd" clipRule="evenodd" fill={color || "#858585"}
                d="M211.426 42.7592C223.698 42.7592 233.646 33.2134 233.646 21.4381C233.646 9.66283 223.698 0.117065 211.426 0.117065C199.154 0.117065 189.206 9.66283 189.206 21.4381C189.206 33.2134 199.154 42.7592 211.426 42.7592Z"/>
            <path 
                fillRule="evenodd" clipRule="evenodd" fill={color || "#858585"}
                d="M23.2102 105.468C35.4819 105.468 45.4301 95.9225 45.4301 84.1472C45.4301 72.3719 35.4819 62.8261 23.2102 62.8261C10.9384 62.8261 0.990234 72.3719 0.990234 84.1472C0.990234 95.9225 10.9384 105.468 23.2102 105.468Z"/>
            <path 
                fillRule="evenodd" clipRule="evenodd" fill={color || "#858585"}
                d="M85.9485 105.468C98.2202 105.468 108.168 95.9225 108.168 84.1472C108.168 72.3719 98.2202 62.8261 85.9485 62.8261C73.6767 62.8261 63.7285 72.3719 63.7285 84.1472C63.7285 95.9225 73.6767 105.468 85.9485 105.468Z" />
            <path 
                fillRule="evenodd" clipRule="evenodd" fill={color || "#858585"}
                d="M148.688 105.468C160.959 105.468 170.908 95.9225 170.908 84.1472C170.908 72.3719 160.959 62.8261 148.688 62.8261C136.416 62.8261 126.468 72.3719 126.468 84.1472C126.468 95.9225 136.416 105.468 148.688 105.468Z"/>
            <path 
                fillRule="evenodd" clipRule="evenodd" fill={color || "#858585"}
                d="M211.426 105.468C223.698 105.468 233.646 95.9225 233.646 84.1472C233.646 72.3719 223.698 62.8261 211.426 62.8261C199.154 62.8261 189.206 72.3719 189.206 84.1472C189.206 95.9225 199.154 105.468 211.426 105.468Z"/>
            <path 
                fillRule="evenodd" clipRule="evenodd" fill={color || "#858585"}
                d="M23.2102 168.177C35.4819 168.177 45.4301 158.632 45.4301 146.856C45.4301 135.081 35.4819 125.535 23.2102 125.535C10.9384 125.535 0.990234 135.081 0.990234 146.856C0.990234 158.632 10.9384 168.177 23.2102 168.177Z"/>
            <path 
                fillRule="evenodd" clipRule="evenodd" fill={color || "#858585"}
                d="M85.9485 168.177C98.2202 168.177 108.168 158.632 108.168 146.856C108.168 135.081 98.2202 125.535 85.9485 125.535C73.6767 125.535 63.7285 135.081 63.7285 146.856C63.7285 158.632 73.6767 168.177 85.9485 168.177Z"/>
            <path 
                fillRule="evenodd" clipRule="evenodd" fill={color || "#858585"}
                d="M148.688 168.177C160.959 168.177 170.908 158.632 170.908 146.856C170.908 135.081 160.959 125.535 148.688 125.535C136.416 125.535 126.468 135.081 126.468 146.856C126.468 158.632 136.416 168.177 148.688 168.177Z"/>
            <path 
                fillRule="evenodd" clipRule="evenodd" fill={color || "#858585"}
                d="M211.426 168.177C223.698 168.177 233.646 158.632 233.646 146.856C233.646 135.081 223.698 125.535 211.426 125.535C199.154 125.535 189.206 135.081 189.206 146.856C189.206 158.632 199.154 168.177 211.426 168.177Z" />
            <path 
                fillRule="evenodd" clipRule="evenodd" fill={color || "#858585"}
                d="M23.2102 230.886C35.4819 230.886 45.4301 221.341 45.4301 209.565C45.4301 197.79 35.4819 188.244 23.2102 188.244C10.9384 188.244 0.990234 197.79 0.990234 209.565C0.990234 221.341 10.9384 230.886 23.2102 230.886Z" />
            <path 
                fillRule="evenodd" clipRule="evenodd" fill={color || "#858585"}
                d="M85.9485 230.886C98.2202 230.886 108.168 221.341 108.168 209.565C108.168 197.79 98.2202 188.244 85.9485 188.244C73.6767 188.244 63.7285 197.79 63.7285 209.565C63.7285 221.341 73.6767 230.886 85.9485 230.886Z" />
            <path 
                fillRule="evenodd" clipRule="evenodd" fill={color || "#858585"}
                d="M148.688 230.886C160.959 230.886 170.908 221.341 170.908 209.565C170.908 197.79 160.959 188.244 148.688 188.244C136.416 188.244 126.468 197.79 126.468 209.565C126.468 221.341 136.416 230.886 148.688 230.886Z" />
            <path 
                fillRule="evenodd" clipRule="evenodd" fill={color || "#858585"}
                d="M211.426 230.886C223.698 230.886 233.646 221.341 233.646 209.565C233.646 197.79 223.698 188.244 211.426 188.244C199.154 188.244 189.206 197.79 189.206 209.565C189.206 221.341 199.154 230.886 211.426 230.886Z" />

        </svg>

    );
};

export default MessageSignGlyph;