// libraries
import { 
    commonFilterBySearch, commonGetAll_api, commonInit_api, 
    commonGetById_api, createAlgoSlice } from "./common";
// types & models
import { filterBySelections as trafficEventFilterBySelections, searchMatch } from "./traffic-events";
import { TrafficEventNetworkManager } from "@algo/network-manager/managers/v3";
import { EAlgoApiObjectType } from "~/interfaces";
// constants
import { CUR_API_VERSION, CUR_API_ENDPOINTS } from "../../api-endpoint-strings";
import { isTesting } from "~/constants"
// test data
import T_DATA from "~/store/algo-api/test-data/regional-events/all-regional-events-10-3-22.json";
import { EATEventType, IATLocationDto, IATTrafficEvent } from "@algo/network-manager/models/v3";

declare var __API_URL__: string;
const apiUrl: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).trafficEvents}`;

// create list slice
export const regionalEventSlice = 
    createAlgoSlice(EAlgoApiObjectType["regional-event"]);

// get handles for the slice's actions
const { 
    begin, success, failure, 
    beginInit, successInit, failureInit,
    filterDataBySelections
} = regionalEventSlice.actions;

// handles dispatching a data get by id from either api or test source based on args
export const getById = (id: number, test: boolean = isTesting, testMode?: string) => {

    return (
        dispatch: any,
        getState: any
    ) => {
        if (test)
            dispatch(getById_test(id, testMode));
        else 
            dispatch(getById_api(id));
    }
}

// retrieves data from api for this data type
export const getById_api = (id: number) => {

    return commonGetById_api(
        new TrafficEventNetworkManager(apiUrl), 
        { id: id, type: EATEventType.RegionalEvent}, 
        regionalEventSlice
    );
    
};

// retrieves test data for this data type
export const getById_test = (id: number, mode?: string) => {
    
    alert("regional-event: getById_test is not yet implemented.")
};

// handles dispatching a data get all from either api or test source based on args
export const getAll = (mode?: string, test: boolean = isTesting): any => {

    return (
        dispatch: any,
        getState: any
    ) => {

        if (getState()[EAlgoApiObjectType["regional-event"]].loading) return;

        if (test)
            dispatch(getAll_test(mode));
        else 
            dispatch(getAll_api(mode));
    }
}

// retrieves all data from api for this data type
export const getAll_api = (mode?: string) => {
    
    if (mode === "init")
        return commonInit_api(
            new TrafficEventNetworkManager(apiUrl), 
            regionalEventSlice,
            { type: EATEventType.RegionalEvent }
        )
    else 
        return commonGetAll_api(
            new TrafficEventNetworkManager(apiUrl), 
            regionalEventSlice,
            { type: EATEventType.RegionalEvent }
        );
};

// retrieves test data for this data type
export const getAll_test = (mode?: string) => {

    return (
        dispatch: any,
        getState: any
    ) => {

        let beginFunction = begin;
        let successFunction = success;
        if (mode === "init") {
            beginFunction = beginInit;
            successFunction = successInit;
        }

        dispatch(beginFunction());
        
        setTimeout(
            () => {
                dispatch(successFunction({
                    data: T_DATA, 
                    xChecksum: Math.random(), 
                    xCount: T_DATA.length, 
                    errorMessage: null, 
                    status: 200
                }))
            }, 2500
        );
    }
    
};

// filters data list based on given search term
export const filterBySearch = (searchTerm: string = "") => {

    return commonFilterBySearch(regionalEventSlice, searchTerm, searchMatch);
    
};

export const filterBySelections = (
    reportTypes: string[],
    severities: string[],
    cityNames: string[],
    countyNames: string[],
    regionNames: string[],
    searchText: string
) => {

    return trafficEventFilterBySelections(
        EAlgoApiObjectType["regional-event"],
        filterDataBySelections,
        reportTypes,
        severities,
        cityNames,
        countyNames,
        regionNames,
        searchText
    );
}

// exports the slice's reducer ( used in store file to build up master reducer )
export const regionalEventReducer = regionalEventSlice.reducer;