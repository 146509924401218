// libraries
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "~/store/algo-api/slices/users";
// constants
import { PAGE_DURATION } from "~/constants";
import { AAUserAuthorizationProfile } from "@algo/network-manager/models/v3/admin";
import { minuteToMilli } from "@caps-mobile/date-time";

export const useStreamTimeout = (): number => {
    const dispatch = useDispatch();
    const [ streamTimeout, setStreamTimeout ] = useState<number>(PAGE_DURATION);
    const userStore = useSelector( (state: any) => state.users);
    const [ timeoutId, settimeoutId ] = useState<NodeJS.Timeout>();
    
    const repeatRefresh = (interval: number = minuteToMilli(5)) => {

        if (!userStore.profile && !userStore.loadingProfile){
            dispatch(getProfile() as any);
        }

        if (userStore.profile){
            let profile: AAUserAuthorizationProfile = userStore.profile;
            setStreamTimeout(getTimeoutByCredential(profile));
        }

        let newTimeoutId: NodeJS.Timeout | undefined = setTimeout(
            () => repeatRefresh(interval),
            interval
        );

        if(timeoutId)
            clearTimeout(timeoutId);

        settimeoutId(newTimeoutId);

    }

    useEffect(
        () => {

            // first, reset the refresh timeout to cancel current refresh loop
            if(timeoutId)
                clearTimeout(timeoutId);

            // then, start the refresh loop with the new interval value found in store
            repeatRefresh();

        }, [userStore]
    )
    
    return streamTimeout;
};

const getTimeoutByCredential = (profileData: any): number => {

    let Profile: AAUserAuthorizationProfile = new AAUserAuthorizationProfile(profileData); 

    if (Profile.hasPrivilege("UnlimitedStreaming")) return -1;
    
    if (
        Profile.hasPrivilege("MediaStreamAccess") ||
        Profile.hasPrivilege("FirstResponderStreamAccess") ||
        Profile.hasPrivilege("PrivateSectorLimitedStreamAccess") ||
        Profile.hasPrivilege("InternalStreamAccess")
    ) return (30 * 60);
    
    else return PAGE_DURATION;
}