// replaces all hyphons in string with the given newChar, or spaces by default
export const dehyphenate = (hyphenString: string, newChar?: string) => {
    let dehyphedString: string = "";
    dehyphedString = hyphenString.replaceAll("-", newChar ? newChar : " ");
    return dehyphedString;
};

// replaces all periods in string with the given newChar, or spaces by default
export const deperiod = (periodString: string, newChar?: string) => {
    let deperiodString: string = "";
    deperiodString = periodString.replaceAll(".", newChar ? newChar : " ");
    return deperiodString;
};

// add a space before any uppercase letters in given string
export const dePascal = (pascalString: string): string => {
    let dePascalString: string = pascalString.replace(/([A-Z])/g, ' $1').trim();
    return dePascalString;
};