// libraries
import * as React from "react";
import { getEnumStrings } from "@caps-mobile/common-lib";
import { dePascal } from "~/library/string-tools";
import { useBreakpoint } from "~/library/useBreakpoint";
// hooks & context
import { useSelector } from "react-redux";
import { useDropdownData, useFilterContent } from "./hooks";
import DetailModalContext from "~/contexts/DetailModalContext";
// styles
import * as PageStyles from "~/components/views/page-template/Styled";
import * as SC from "~/components/views/data-search-overlay/data-type-sections/detail-section/section-types/Styled";
// types & models
import { EAlgoLayerType } from "~/interfaces";
import { EATRegion, EATSeverity, IATCityDto, IATCountyDto } from "@algo/network-manager/models/v3";
import { EATEventType } from "@algo/network-manager/models/v3";
// components
import PageTemplate from "../../views/page-template/PageTemplate";
import { AlgoDropdown } from "../../views/algo-dropdown/AlgoDropdown";
import ApiObjectView from "../../views/api-object-details/ApiObjectView";
import ObjectDetailModal from "../../views/modal/object-detail-modal/ObjectDetailModal";
import NoResultsTemplate from "~/components/views/page-template/NoResultsTemplate";
// constants
const EVENT_TYPES: string[] = getEnumStrings(EATEventType).filter(x => x !== 'Unknown').map((str: string) => dePascal(str));
const SEVERITIES: string[] = getEnumStrings(EATSeverity).filter(x => x !== 'Unknown').map((str: string) => dePascal(str));
const REGIONS: string[] = getEnumStrings(EATRegion).filter(x => x !== 'Unknown').map((str: string) => str !== "ALDOT" ? dePascal(str) : str);

export type IProps = {
    //
};

export const TrafficReportsPage: React.FC<IProps> = (props) => {

    const {
        //
    } = props;

    const { md: isMediumMax } = useBreakpoint();
    const columnCount: number = isMediumMax ? 1 : 2;
    const detailModalContext: any = React.useContext(DetailModalContext);

    // event type data stores
    const crashStore: any = useSelector( (store: any) => store["crash"]);
    const incidentStore: any = useSelector( (store: any) => store["incident"]);
    const regionalEventStore: any = useSelector( (store: any) => store["regional-event"]);
    const roadConditionStore: any = useSelector( (store: any) => store["road-condition"]);
    const roadworkStore: any = useSelector( (store: any) => store["roadwork"]);

    // gather together all of the event type data lists into a mappable list
    const dataTypeSections: {[key in EAlgoLayerType]?: any[]} = {};
        dataTypeSections[EAlgoLayerType["crash"]] = crashStore.dataFilteredBySelections 
            ? [...crashStore.dataFilteredBySelections] : [];
        dataTypeSections[EAlgoLayerType["incident"]] = incidentStore.dataFilteredBySelections 
            ? [...incidentStore.dataFilteredBySelections] : [];
        dataTypeSections[EAlgoLayerType["regional-event"]] = regionalEventStore.dataFilteredBySelections 
            ? [...regionalEventStore.dataFilteredBySelections] : [];
        dataTypeSections[EAlgoLayerType["road-condition"]] = roadConditionStore.dataFilteredBySelections 
            ? [...roadConditionStore.dataFilteredBySelections] : [];
        dataTypeSections[EAlgoLayerType["roadwork"]] = roadworkStore.dataFilteredBySelections 
            ? [...roadworkStore.dataFilteredBySelections] : [];

    // load city and county data if they aren't already loaded
    const [ cityStore, countyStore ] = useDropdownData();

    // get the city and county names for dropdown options lists
    const cityStrings = cityStore.data?.map( (city: IATCityDto) => city.name || "??");
    const countyStrings = countyStore.data?.map( (county: IATCountyDto) => county.name || "??");

    // track the selected indices for dropdowns
    const [ eventTypeIndices, setEventTypeIndices ] = React.useState<number[]>([]);
    const [ severityIndices, setSeverityIndices ] = React.useState<number[]>([]);
    const [ cityIndices, setCityIndices ] = React.useState<number[]>([]);
    const [ countyIndices, setCountyIndices ] = React.useState<number[]>([]);
    const [ regionIndices, setRegionIndices ] = React.useState<number[]>([]);
    // track the search text value
    const [ searchText, setSearchText ] = React.useState<string>("");

    const isLoadingContent: boolean = 
        crashStore.loading ||
        incidentStore.loading ||
        regionalEventStore.loading ||
        roadConditionStore.loading ||
        roadworkStore.loading;

    const noData: boolean = ((!crashStore || !crashStore.dataFilteredBySelections?.length || crashStore.dataFilteredBySelections?.length <= 0) &&
    (!incidentStore || !incidentStore.dataFilteredBySelections?.length || incidentStore.dataFilteredBySelections?.length <= 0) &&
    (!regionalEventStore || !regionalEventStore.dataFilteredBySelections?.length || regionalEventStore.dataFilteredBySelections?.length <= 0) &&
    (!roadConditionStore || !roadConditionStore.dataFilteredBySelections?.length || roadConditionStore.dataFilteredBySelections?.length <= 0) &&
    (!roadworkStore || !roadworkStore.dataFilteredBySelections?.length || roadworkStore.dataFilteredBySelections?.length <= 0));

    // filteres the content section when search selections or data stores change
    useFilterContent(
        searchText, eventTypeIndices, severityIndices, cityIndices, 
        countyIndices, regionIndices, crashStore, incidentStore, 
        regionalEventStore, roadConditionStore, roadworkStore,
        cityStrings, countyStrings, EVENT_TYPES, SEVERITIES, REGIONS
    );

    return (
        <PageTemplate 
            title={"Traffic Reports"}
            searchBar={{
                autoSubmit: true, 
                submitCallback: (newText: string) => 
                    setSearchText(newText)
            }}
        >

            <PageStyles.FilterRow>

                <AlgoDropdown
                    options={EVENT_TYPES}
                    placeholder={"All Report Types"}
                    showPlaceholder={eventTypeIndices.length > 0 ? false : true}
                    scrollThreshold={6}
                    multiSelect={true}
                    callback={(selectedIndex: number) => {
                        if(selectedIndex === -1){
                            setEventTypeIndices([]);
                        }
                        else{
                            let curIndex: number = eventTypeIndices.indexOf(selectedIndex);
                            let newIndices: number[] = eventTypeIndices;
                            if (curIndex === -1) {
                                newIndices.push(selectedIndex);
                            }
                            else {
                                newIndices.splice(curIndex, 1);
                            }
                            setEventTypeIndices([...newIndices]);
                        }
                    }}
                />

                <AlgoDropdown
                    options={SEVERITIES}
                    placeholder={"All Severities"}
                    showPlaceholder={severityIndices.length > 0 ? false : true}
                    scrollThreshold={6}
                    multiSelect={true}
                    callback={(selectedIndex: number) => {
                        if(selectedIndex === -1){
                            setSeverityIndices([]);
                        }
                        else{
                            let curIndex: number = severityIndices.indexOf(selectedIndex);
                            let newIndices: number[] = severityIndices;
                            if (curIndex === -1) {
                                newIndices.push(selectedIndex);
                            }
                            else {
                                newIndices.splice(curIndex, 1);
                            }
                            setSeverityIndices([...newIndices]);
                        }
                    }}
                />

                <AlgoDropdown
                    options={cityStrings}
                    placeholder={"All Cities"}
                    showPlaceholder={cityIndices.length > 0 ? false : true}
                    scrollThreshold={6}
                    multiSelect={true}
                    callback={(selectedIndex: number) => {
                        if(selectedIndex === -1){
                            setCityIndices([]);
                        }
                        else{
                            let curIndex: number = cityIndices.indexOf(selectedIndex);
                            let newIndices: number[] = cityIndices;
                            if (curIndex === -1) {
                                newIndices.push(selectedIndex);
                            }
                            else {
                                newIndices.splice(curIndex, 1);
                            }
                            setCityIndices([...newIndices]);
                        }
                    }}
                />

                <AlgoDropdown
                    options={countyStrings}
                    placeholder={"All Counties"}
                    showPlaceholder={countyIndices.length > 0 ? false : true}
                    scrollThreshold={6}
                    multiSelect={true}
                    callback={(selectedIndex: number) => {
                        if(selectedIndex === -1){
                            setCountyIndices([]);
                        }
                        else{
                            let curIndex: number = countyIndices.indexOf(selectedIndex);
                            let newIndices: number[] = countyIndices;
                            if (curIndex === -1) {
                                newIndices.push(selectedIndex);
                            }
                            else {
                                newIndices.splice(curIndex, 1);
                            }
                            setCountyIndices([...newIndices]);
                        }
                    }}
                />

                <AlgoDropdown
                    options={REGIONS}
                    placeholder={"All Regions"}
                    showPlaceholder={regionIndices.length > 0 ? false : true}
                    scrollThreshold={6}
                    multiSelect={true}
                    callback={(selectedIndex: number) => {
                        if(selectedIndex === -1){
                            setRegionIndices([]);
                        }
                        else{
                            let curIndex: number = regionIndices.indexOf(selectedIndex);
                            let newIndices: number[] = regionIndices;
                            if (curIndex === -1) {
                                newIndices.push(selectedIndex);
                            }
                            else {
                                newIndices.splice(curIndex, 1);
                            }
                            setRegionIndices([...newIndices]);
                        }
                    }}
                />

            </PageStyles.FilterRow>

            <PageStyles.FilteredContentSection twoColumns={true}>

                { dataTypeSections && 
                    Object.keys(dataTypeSections).map(
                        (key: any, mapIndex: number) => {
                            if (dataTypeSections[key as keyof typeof EAlgoLayerType]?.length === 0) 
                                return null;
                            else return (
                                dataTypeSections[key as keyof typeof dataTypeSections]?.map(
                                    (dataObject: any, mapIndex: number) => {
                                        const detailModalHandler = () => {
                                            detailModalContext.setModalContent(
                                                <ObjectDetailModal 
                                                    object={{type: key, data: dataObject}} 
                                                    doneCallback={() => detailModalContext.setShowModal(false)} />
                                            );
                                            detailModalContext.setShowModal(true);
                                        }
                                        return (
                                            <SC.DetailsCard key={dataObject.id || mapIndex} extraStyling={true} showPointer={true} columnSize={100/columnCount} onClick={() => detailModalHandler()}>
                                                <ApiObjectView
                                                    object={dataObject} type={key} showChevron={false}/>
                                            </SC.DetailsCard>
                                        )
                                    }
                                )
                            )
                        }
                    )
                }

            </PageStyles.FilteredContentSection>

            { noData &&
                <NoResultsTemplate loading={isLoadingContent} pageType="Traffic Reports" />
            }

        </PageTemplate>
    );
};

export default TrafficReportsPage;