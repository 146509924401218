// libraries
import { Fragment, useContext } from "react";
// hooks & context
import { useNavigate } from "react-router-dom";
// enums
import { IDocObject } from "~/interfaces";
// styles
import * as BannerStyles from "~/components/views/banner-option/Styled";
import { Header } from "~/components/views/page-template/Styled";
// components
import BannerOption, { EDefaultValue } from "~/components/views/banner-option/BannerOption";
// constants
import { algo_green_text } from "~/constants";
import SiteNavContext from "~/navigation/SiteNavContext";
import navMap, { getNavMapValue } from "~/navigation/navigation-map";

const DOC_STRINGS: IDocObject[] = [
    {
        title: "Terms of Use",
        url: `/terms-of-use`
    },
    {
        title: "Privacy Policy",
        url: `/privacy-policy`
    }
];

export type IProps = {
    //
};

export const DisclosuresSection: React.FC<IProps> = (props) => {
    
    const navContext = useContext(SiteNavContext)

    return (
        <Fragment>

            <Header> { `Disclosures` } </Header>

            <BannerStyles.BannerOptionSection>

                {DOC_STRINGS.map(
                    (docObject: IDocObject) => {
                        return (
                            <BannerOption key={docObject.title}
                                clickCallback={() => {navContext.navigate(docObject.url)}}
                                label={
                                    <Fragment>
                                        <BannerStyles.OptionLabel color={algo_green_text}>{docObject.title}</BannerStyles.OptionLabel>
                                    </Fragment>
                                }
                                defaultValue={EDefaultValue["chevron-right"]}
                            />
                        );
                    }
                )}
                
            </BannerStyles.BannerOptionSection>

        </Fragment>
    );
};

export default DisclosuresSection;