// libraries
import { Fragment, useEffect, useState } from "react";
// styles
import { BannerOptionSection } from "~/components/views/banner-option/Styled";
import { Header } from "../Styled";
// components
import BannerOption from "~/components/views/banner-option/BannerOption";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { useAuth } from "react-oidc-context";

export type IProps = {
    //
};

export const GeneralSection: React.FC<IProps> = (props) => {

    const {
        //
    } = props;

    const auth = useAuth();

    const userNameLabel: string = 
        (auth.isAuthenticated)
            ? (auth.user?.profile.preferred_username)
                ? auth.user?.profile.preferred_username
                : "Unknown UserName"
            : `ALGO Authentication Login`

    const handleClickLogin = () => {
        if (auth.isAuthenticated){
            auth.signoutRedirect();
        }
        else {
            auth.signinRedirect();
        }
    };

    return (
        <Fragment>

            <Header> { `Transportation & Emergency Management` } </Header>

            <BannerOptionSection>
                <BannerOption
                    label={userNameLabel}
                    labelStyleOverwrite={`color: green;`}
                    clickCallback={handleClickLogin}
                    value={ 
                        (auth.isAuthenticated)
                            ?   <span style={{color: "#FF3B30"}}>{`Log Out`}</span>
                            :   <FontAwesomeIcon className="fa-fw" icon={faChevronRight} />
                    }
                />
            </BannerOptionSection>

        </Fragment>
    );
};

export default GeneralSection;