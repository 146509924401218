// libraries
import styled from "styled-components";
import { flexSmart } from "@caps-mobile/styled-lib";

export const CloseButtonTitleWrapper = styled.div`
    padding-bottom: 20px;
    width: 100%;

    ${flexSmart("space-betweeen center")}
`;

export const CloseButton = styled.div`z
    box-sizing: border-box;

    color: ${props => props.theme.colors["primary"].rgba};
    font-weight: 700;
    font-size: 17px;
    line-height: 147%;

    :hover{
        cursor: pointer;
    }
`;

export const Title = styled.div`
    width: 100%;

    box-sizing: border-box;

    font-size: 48px;
    line-height: 108.36%;
    font-weight: 600;
`;
