// libraries
import * as React from "react";
// styles
import * as SC from "./Styled";
// components

export type ILegendItem = {
    fillColor?: string;
    strokeColor?: string;
    name?: string;
}

export const LegendItem: React.FC<ILegendItem> = (props) => {

    const { fillColor, strokeColor } = props;

    return (
        <SC.StyledLegendItem>

            <SC.ColorBox fillColor={fillColor} strokeColor={strokeColor} />

            <SC.Name>{props.name}</SC.Name>

        </SC.StyledLegendItem>
    );

};

export default LegendItem;