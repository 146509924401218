// libraries
import React from 'react';
import {ThemeProvider} from "styled-components";
// hooks & context
import { ThemeContext } from "~/theme";
import { useColorSchemePreference } from '@algo/hooks';
import { 
    useInitApiData, useInitializeMapLayers, 
    useInitializeUserSettings, useRefreshData } from './global-hooks';
import { useAuth, hasAuthParams } from "react-oidc-context";
import { SiteNavContext } from './navigation/SiteNavContext';
import { DetailModalContext, IDetailModalContext, useDetailModalContext 
} from './contexts/DetailModalContext';
import { VideoboardModalContext, IVideoboardModalContext, useVideoboardModalContext 
} from './contexts/VideoboardModalContext';

import { useLocation } from 'react-router-dom';
import { useSiteNavContext } from './contexts/DataSearchOverlayContext';
import { useThemeContext } from './contexts/useThemeContext';
// components
import AppContent from './components/app-content/AppContent';
import SigningInPage from './components/pages/signing-in/SigningIn';
import AuthLoadingPage from './components/pages/auth-loading/AuthLoading';
import SigningOutPage from './components/pages/signing-out/SigningOut';
// constants
import { HERE_SCRIPT_BUNDLE_URI, HERE_SCRIPT_HARP } from '@algo/here-maps';

type IProps = {
  //
};

const App: React.FC<IProps> = () => {

    const location = useLocation();
    let pathName: string = location.pathname;

    // initialize the value of the theme context
    const [themeMode, themeObject] = useThemeContext();

    // tracks whether navigation panel is open or closed
    const [panelOpen, setPanelOpen] = 
        React.useState<boolean>(true);

    // holds information about navigation
    // used for controlling nav-based styles and logic
    const siteNavContextInit: any = useSiteNavContext(pathName, panelOpen, setPanelOpen);

    // triggers when useColorSchemePreference first runs
    const initialThemeSet = () => {

        // if (themeMode !== EThemeOptions.Dark){
        //     setThemeMode(EThemeOptions.Dark);
        //     setThemeObject(getTheme(EThemeOptions.Dark));
        // }

    }

    // triggers when user's prefers-color-scheme value changes
    const listenerCallback = (event: any) => {
        // if (event.matches) {
        //     setThemeMode(EThemeOptions.Dark);
        //     setThemeObject(getTheme(EThemeOptions.Dark));
        // }
        // else {
        //     setThemeMode(EThemeOptions.Light);
        //     setThemeObject(getTheme(EThemeOptions.Light));
        // }
    }
    
    // watches @media theme mode preference and updates toggles accordingly
    useColorSchemePreference(initialThemeSet, listenerCallback);

    // checks the localStorage for previously saved User Settings to update Settings store
    useInitializeUserSettings();

    // checks the localStorage for previously saved Map Layers settings to update MapLayers store
    useInitializeMapLayers();

    // get initial api data
    useInitApiData();

    // refresh all api data on user-selected interval
    useRefreshData();

    const auth = useAuth();
    
    // Make sure HERE scripts load before doing any silent sign in logic
    // const [existingScripts, setExistingScripts] = React.useState<string[]>([]);

    // const [scriptsLoaded, setScriptsLoaded] = React.useState<boolean>(false);

    // React.useEffect(() => {
    //     if (!scriptsLoaded) {
    //         document.querySelectorAll('script[src]').forEach((scriptElement) => {
    //             const srcAttribute = scriptElement.getAttribute('src');
    
    //             // console.log("Checking src attr", scriptElement);
    
    //             if (srcAttribute === HERE_SCRIPT_BUNDLE_URI && !existingScripts.includes(HERE_SCRIPT_BUNDLE_URI)) {
    //                 // console.log("Found Here Script Bundle", HERE_SCRIPT_BUNDLE_URI);
    
    //                 setExistingScripts([...existingScripts, srcAttribute!]);
    //             }
                
    //             if (srcAttribute === HERE_SCRIPT_HARP && !existingScripts.includes(HERE_SCRIPT_HARP)) {
    //                 // console.log("Found HARP Engine Script Bundle", HERE_SCRIPT_HARP);
    
    //                 setExistingScripts([...existingScripts, srcAttribute!]);
    //             }
    //         });

    //         if (existingScripts.includes(HERE_SCRIPT_BUNDLE_URI) && existingScripts.includes(HERE_SCRIPT_HARP)) {
    //             setScriptsLoaded(true);
    //         }
    //     }
    // }, [existingScripts, scriptsLoaded, document]);

    // // The point of this script is to capture the session storage'd user 
    // // and silently renew the access token.
    // const [hasTriedSignin, setHasTriedSignin] = React.useState<boolean>(false);

    // React.useEffect(() => {
    //     if (!hasAuthParams() &&
    //         !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading &&
    //         !hasTriedSignin && scriptsLoaded
    //     ) {
    //         console.log("signinSilent: ATTEMPTED \nlocation.pathname: %s \nauth.isAuthenticated: %s \nauth.activeNavigator: %s \nauth.isLoading: %s \nauth.hasTriedSignin: %s \nauth.scriptsLoaded: %s", 
    //         location.pathname, auth.isAuthenticated, auth.activeNavigator, auth.isLoading, hasTriedSignin, scriptsLoaded);
    //         auth.signinSilent();
    //         setHasTriedSignin(true);
    //     }
    //     else {
    //         console.log("signinSilent: NOT ATTEMPTED \nlocation.pathname: %s \nauth.isAuthenticated: %s \nauth.activeNavigator: %s \nauth.isLoading: %s \nauth.hasTriedSignin: %s \nauth.scriptsLoaded: %s", 
    //         location.pathname, auth.isAuthenticated, auth.activeNavigator, auth.isLoading, hasTriedSignin, scriptsLoaded);
    //     }
    // }, [auth, hasTriedSignin, scriptsLoaded]);


    const detailModalContextInit: IDetailModalContext = useDetailModalContext();
    const videoboardModalContextInit: IVideoboardModalContext = useVideoboardModalContext();

    switch(auth.activeNavigator) {
        case "signinSilent":
            return <SigningInPage />;
        case "signoutRedirect":
            return <SigningOutPage />;
    }

    if (auth.isLoading) {
        return <AuthLoadingPage />
    }

    if (auth.error){
        return <div>Authentication Error.... {auth.error.message}</div>;
    }

    return (
        <ThemeProvider theme={themeObject}>
            <ThemeContext.Provider value={themeObject}>
                <SiteNavContext.Provider value={siteNavContextInit}>
                    <DetailModalContext.Provider value={detailModalContextInit}>
                        <VideoboardModalContext.Provider value={videoboardModalContextInit}>

                            <AppContent />

                        </VideoboardModalContext.Provider>
                    </DetailModalContext.Provider>
                </SiteNavContext.Provider>
            </ThemeContext.Provider>
        </ThemeProvider>
    );
    
}

export default App;
