// libraries
import * as React from "react";
// styles
import * as SC from "./Styled";
// components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import { ThemeContext } from "~/theme";
// context
// constants


export type IProps = {
    headStyleOverwrite?: string;
    bodyStyleOverwrite?: string;
    headStyleHideOverwrite?: string;
    title?: string | React.ReactNode;
    body?: any;
    children?: React.ReactNode;
    reverseChevron?: boolean;
};

export const Accordion: React.FC<IProps> = (props) => {
    
    const [hide, setHide] = React.useState<boolean>(false);
    const theme: any = React.useContext(ThemeContext);

    return (
        <SC.StyledAccordion>
            
            <SC.Head 
                styleOverwrite={props.headStyleOverwrite}
                hide={hide}
                styleHideOverwrite={props.headStyleHideOverwrite}
                onClick={ () => setHide(!hide)}
            >
                {props.title}

                {
                    <FontAwesomeIcon
                        className="fa-fw"
                        color={theme.colors["primary"].rgba}
                        icon={  hide
                            ? (props.reverseChevron) ? faChevronUp : faChevronDown 
                            : (props.reverseChevron) ? faChevronDown : faChevronUp 
                        }
                    />
                }

            </SC.Head>

            <SC.Body 
                styleOverwrite={props.bodyStyleOverwrite}
                hide={hide}
            >
                {props.children}
            </SC.Body>

        </SC.StyledAccordion>
    )
};

export default Accordion;

