// libraries
import { nuon } from '@caps-mobile/common-lib';
import { flexSmart } from '@caps-mobile/styled-lib';
import styled from 'styled-components';

export type IProps = {
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;

    styleOverwrite?: string;
};

export const StyledOverlaySection = styled.div<IProps>`
    ${props => `
        position: absolute;
        top: ${nuon(props.top) ? `${props.top}px` : "auto"};
        right: ${nuon(props.right) ? `${props.right}px` : "auto"};
        bottom: ${nuon(props.bottom) ? `${props.bottom}px` : "auto"};
        left: ${nuon(props.left) ? `${props.left}px` : "auto"};

        width: calc(100% - ${(props.right ? props.right : 0) + (props.left ? props.left : 0)}px);
        
        z-index: 1;

        ${props.styleOverwrite ? props.styleOverwrite : ``}
    `}
`;

export const ChildrenWrapper = styled.div<{styleOverwrite?: string}>`
    position: relative;
    height: 100%;
    width: 100%;

    ${flexSmart("space-between center")}
    flex-direction: column;

    ${props => props.styleOverwrite ? props.styleOverwrite : ``}
`;