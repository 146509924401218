import styled from "styled-components";
import { flexSmart } from "@caps-mobile/styled-lib";
import { BORDER_RADIUS, CARD_PADDING } from "~/constants";

export const StyledCard = styled.div<{styleOverwrite?: string}>`
    height: auto;
    width: auto;

    padding: ${CARD_PADDING}px;
    box-sizing: border-box;

    ${flexSmart("flex-start flex-start")}
    flex-direction: column;

    border-radius: ${BORDER_RADIUS}px;

    background-color: ${props => props.theme.colors["bg-primary"].rgba};

    ${props => props.styleOverwrite ? props.styleOverwrite : ""};
`;

export const CardPartial = `
    height: auto;
    width: auto;

    padding: ${CARD_PADDING}px;
    box-sizing: border-box;

    ${flexSmart("flex-start flex-start")}
    flex-direction: column;

    border-radius: ${BORDER_RADIUS}px;

    background-color: #fff;
`;