// libraries
import * as React from "react";
import { filterBySearch } from "~/store/algo-api/slices/alea-alerts";
import { nuonoe } from "@caps-mobile/common-lib";
// hooks & context
import { useDispatch, useSelector } from "react-redux";
import { useLoadAleaAlerts } from "./hooks";
// styles
import * as PageStyles from "~/components/views/page-template/Styled";
import * as SC from "~/components/views/data-search-overlay/data-type-sections/detail-section/section-types/Styled";
import * as SC2 from "~/components/views/api-object-details/api-object-details/Styled";
// components
import PageTemplate from "~/components/views/page-template/PageTemplate";
import { EAlgoApiObjectType } from "~/interfaces";
import ApiObjectView from "~/components/views/api-object-details/ApiObjectView";
import { useBreakpoint } from "~/library/useBreakpoint";
import { IATAleaAlertDto, ATAleaAlert } from "@algo/network-manager/models/v3";
import DateFormatter from "~/library/DateFormatter";
import NoResultsTemplate from "~/components/views/page-template/NoResultsTemplate";

export type IProps = {
    //
};

export const LawEnforcementAlertsPage: React.FC<IProps> = (props) => {

    const {
        //
    } = props;
    
    const { xs: isXSmallMax } = useBreakpoint();
    const { lg: isLargeMax } = useBreakpoint();
    const columnCount: number = isLargeMax ? 1 : 2;

    const dispatch = useDispatch();

    const [ searchText, setSearchText ] = React.useState<string>("");

    const aleaAlertStore = useSelector( (state: any) => state["alea-alert"]);
    const filteredAleaAlerts = aleaAlertStore.dataFilteredBySearch;

    const isLoadingContent: boolean = 
        aleaAlertStore.loading;

    // load travel times if necessary
    useLoadAleaAlerts();

    React.useEffect(
        () => { dispatch(filterBySearch(searchText) as any); }, [searchText, aleaAlertStore.data]
    );

    return (
        <PageTemplate 
            title={"Law Enforcement Alerts"}
            searchBar={{
                autoSubmit: true,
                submitCallback: (newText: string) => 
                    setSearchText(newText)
            }}
        >

            <PageStyles.FilteredContentSection twoColumns={true}>

                { nuonoe(filteredAleaAlerts) && filteredAleaAlerts.map(
                        (dataObject: any, mapIndex: number) => {
                            let aleaAlert: IATAleaAlertDto = new ATAleaAlert(dataObject);
                            let pubDate: Date = new Date(aleaAlert.publishDate);
                            let formattedDate: string = "";
                            let Formatter: DateFormatter = new DateFormatter(pubDate);
                            formattedDate += Formatter.defaultDateString(false);
                            return (
                                <SC.DetailsCard key={dataObject.id || mapIndex} columnSize={100/columnCount}>
                                    <SC2.Header key={mapIndex} lawEnforcement={!isXSmallMax} isSmall={isXSmallMax}>{aleaAlert.title}</SC2.Header>
                                    <ApiObjectView lawEnforcement={isXSmallMax}
                                        object={dataObject} type={EAlgoApiObjectType["alea-alert"]} showChevron={false}/>
                                    <SC2.Footer key={dataObject.id || mapIndex}>Published {formattedDate}</SC2.Footer>
                                </SC.DetailsCard>
                            )
                        }
                    )
                }

            </PageStyles.FilteredContentSection>

            { (!filteredAleaAlerts || !filteredAleaAlerts?.length || filteredAleaAlerts?.length <= 0) &&
                <NoResultsTemplate loading={isLoadingContent} pageType="Law Enforcement Alerts" />
            }

        </PageTemplate>
    );
};

export default LawEnforcementAlertsPage;