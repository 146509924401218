// libraries
import * as React from "react";
// styled
import * as SC from "./Styled";
import { EAlgoApiObjectType, EAlgoLayerType } from "~/interfaces";
// component
import DetailsSection from "./detail-section/DetailsSection";
import Accordion from "../../accordion/Accordion";
import { useSelector } from "react-redux";

export type IProps = {
    showTitle?: boolean;
    showAll?: boolean;
    isCollapseable?: boolean;
    dataType: EAlgoLayerType | EAlgoApiObjectType;
};

export const DataTypeSection: React.FC<IProps> = (props) => {

    const { isCollapseable, showTitle, dataType: layerType } = props;

    let dataType: EAlgoApiObjectType;

    dataType = EAlgoApiObjectType[layerType as keyof typeof EAlgoApiObjectType];

    // watch the store data for the current data type
    const dataStore: any = useSelector( (store: any) => store[dataType] );

    if (!dataStore) return null;

    const dataObjects: any[] = (dataType === EAlgoApiObjectType["camera-group"]) 
        ? dataStore.dataGroupedFilteredBySearch
        : ( dataStore.dataFilteredBySearch || [] );

    const sectionTitle: string = 
        EAlgoApiObjectType[dataType].toString().replaceAll("-", " ");

    if (isCollapseable)
        return (
            <SC.StyledDataTypeSection>
                <Accordion reverseChevron={true}
                    title={ showTitle &&
                        <SC.DataTypeHeader>
                            <SC.DetailTitle>
                                {`${sectionTitle}`}
                            </SC.DetailTitle>
                            <SC.CountText>
                                {dataObjects && `${dataObjects.length} item${dataObjects.length === 1 ? "" : "s"}`}
                            </SC.CountText>
                        </SC.DataTypeHeader>
                    }
                >
                    <DetailsSection {...props} dataType={dataType} dataObjects={dataObjects} />
                </Accordion>
        </SC.StyledDataTypeSection>
    );

    else return (
        <SC.StyledDataTypeSection>
            <DetailsSection {...props} dataType={dataType} dataObjects={dataObjects}/>
        </SC.StyledDataTypeSection>
    );

};

export default DataTypeSection;