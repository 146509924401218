// libraries
import { 
    commonGetAll_api, commonGetById_api, commonInit_api,
    commonFilterBySearch, createAlgoSlice, commonFilterGroupedBySearch 
} from "./common";
// types & models
import { CameraNetworkManager, IProcessedResponse } from "@algo/network-manager/managers/v3";
import { EAlgoApiObjectType, ICoord } from "~/interfaces";
// constants
import { CUR_API_VERSION, CUR_API_ENDPOINTS } from "../../api-endpoint-strings";
import { isTesting } from "~/constants"
import { getAccessToken } from "~/authentication/oidcConfig";
// test data
import T_DATA from "../test-data/cameras/all-cameras-10-6-22.json";
import T_DATA_GROUPED from "../test-data/cameras/all-cameras-grouped-10-6-22.json";
import { buildLastResponse } from "~/store/library";
import { ATCamera, ATLocation, IATCamera, IATLocationDto } from "@algo/network-manager/models/v3";
import { nuon } from "@caps-mobile/common-lib";

declare var __API_URL__: string;
const apiUrl: string = 
    `${__API_URL__}/${CUR_API_VERSION}/${CUR_API_ENDPOINTS(CUR_API_VERSION).cameras}`;

// create list slice
export const cameraSlice = 
    createAlgoSlice(EAlgoApiObjectType.camera);

// get handles for the slice's actions
const { 
    begin, success, failure, 
    beginInit, successInit, failureInit,
    beginGrouped, successGrouped, failureGrouped,
    filterDataBySearch, filterDataBySelections
} = cameraSlice.actions;

// handles dispatching a data get by id from either api or test source based on args
export const getById = (id: number, test: boolean = isTesting, testMode?: string) => {

    return (
        dispatch: any,
        getState: any
    ) => {
        if (test)
            dispatch(getById_test(id, testMode));
        else 
            dispatch(getById_api(id));
    }
}

// retrieves data from api for this data type
export const getById_api = (id: number) => {
    return (
        dispatch: any,
        getState: any
    ) => {

        let beginFunction = begin;
        let successFunction = success;
        let failureFunction = failure;

        dispatch(beginFunction());

        let manager: CameraNetworkManager = new CameraNetworkManager(apiUrl);

        getAccessToken().then(
            (token: string) => {
                manager.setAccessToken(token);

                manager.getById(buildLastResponse(getState()), {id}, false)
                .then(
                    (response: IProcessedResponse) => {
                        if (response.error)
                            dispatch(failureFunction({ errorMessage: response.error.message}))
                        else 
                            dispatch(successFunction(response.getReduxObject()))
                    }
                ).catch(
                    (error: Error) => dispatch(failureFunction({ errorMessage: error.message }))
                )
            }
        ).catch(
            (err: any) => {
                console.error("Error retrieving user access token.");

                manager.getById(buildLastResponse(getState()), {id}, false)
                .then(
                    (response: IProcessedResponse) => {
                        if (response.error)
                            dispatch(failureFunction({ errorMessage: response.error.message}))
                        else 
                            dispatch(successFunction(response.getReduxObject()))
                    }
                ).catch(
                    (error: Error) => dispatch(failureFunction({ errorMessage: error.message }))
                )
            }
        )
    }

};

// retrieves test data for this data type
export const getById_test = (id: number, mode?: string) => {
    
    alert("camera: getById_test is not yet implemented.")
};

// handles dispatching a data get all from either api or test source based on args
export const getAll = (mode?: string, test: boolean = isTesting): any => {

    return (
        dispatch: any,
        getState: any
    ) => {

        if (getState()[EAlgoApiObjectType["camera"]].loading) return;

        if (test)
            dispatch(getAll_test(mode));
        else 
            dispatch(getAll_api(mode));
    }
}

// retrieves all data from api for this data type
export const getAll_api = (mode?: string) => {
    return (
        dispatch: any,
        getState: any
    ) => {

        let beginFunction = begin;
        let successFunction = success;
        let failureFunction = failure;

        dispatch(beginFunction());

        let manager: CameraNetworkManager = new CameraNetworkManager(apiUrl);

        getAccessToken().then(
            (token: string) => {
                manager.setAccessToken(token);
                manager.getAll(buildLastResponse(getState()), {}, false)
                    .then(
                        (response: IProcessedResponse) => {
                            if (response.error)
                                dispatch(failureFunction({ errorMessage: response.error.message}))
                            else 
                                dispatch(successFunction(response.getReduxObject()))
                        }
                    ).catch(
                        (error: Error) => dispatch(failureFunction({ errorMessage: error.message }))
                    )
            }
        ).catch(
            (err: any) => {
                console.error("Error retrieving user access token.");
                manager.getAll(buildLastResponse(getState()), {}, false)
                .then(
                    (response: IProcessedResponse) => {
                        if (response.error)
                            dispatch(failureFunction({ errorMessage: response.error.message}))
                        else 
                            dispatch(successFunction(response.getReduxObject()))
                    }
                ).catch(
                    (error: Error) => dispatch(failureFunction({ errorMessage: error.message }))
                )
            }
        )
    }
};

// retrieves test data for this data type
export const getAll_test = (mode?: string) => {

    return (
        dispatch: any,
        getState: any
    ) => {

        let beginFunction = begin;
        let successFunction = success;
        if (mode === "init") {
            beginFunction = beginInit;
            successFunction = successInit;
        }

        dispatch(beginFunction());
        
        setTimeout(
            () => {
                dispatch(successFunction({data: T_DATA, errorMessage: null, status: 200}))
            }, 2500
        );
    }
    
};


// handles dispatching a data get all from either api or test source based on args
export const getAllGrouped = (mode?: string, test: boolean = isTesting): any => {

    return (
        dispatch: any,
        getState: any
    ) => {

        if (getState()[EAlgoApiObjectType["camera"]].loadingGrouped) return;

        if (test)
            dispatch(getAllGrouped_test(mode));
        else 
            dispatch(getAllGrouped_api(mode));
    }
}

// retrieves all data from api for this data type
export const getAllGrouped_api = (mode?: string) => {

    return (
        dispatch: any,
        getState: any,
    ) => {

        let beginFunction = beginGrouped;
        let successFunction = successGrouped;
        let failureFunction = failureGrouped;
        if (mode === "init") {
            beginFunction = beginInit;
            successFunction = successInit;
            failureFunction = failureInit;
        }

        dispatch(beginFunction());

        let manager: CameraNetworkManager = new CameraNetworkManager(apiUrl);

        getAccessToken().then(
            (token: string) => {
                manager.setAccessToken(token);

                manager.getGrouped(buildLastResponse(getState()), {}, false)
                .then(
                    (response: IProcessedResponse) => {
                        if (response.error)
                            dispatch(failureFunction({ errorMessage: response.error.message}))
                        else 
                            dispatch(successFunction(response.getReduxObject()))
                    }
                ).catch(
                    (error: Error) => dispatch(failureFunction({ errorMessage: error.message }))
                );
            }
        ).catch(
            (err: any) => {
                manager.getGrouped(buildLastResponse(getState()), {}, false)
                .then(
                    (response: IProcessedResponse) => {
                        if (response.error)
                            dispatch(failureFunction({ errorMessage: response.error.message}))
                        else 
                            dispatch(successFunction(response.getReduxObject()))
                    }
                ).catch(
                    (error: Error) => dispatch(failureFunction({ errorMessage: error.message }))
                );

                console.error("Error retrieving user access token.");
            }
        )

       
    }
    
};

// retrieves test data for this data type
export const getAllGrouped_test = (mode?: string) => {

    return (
        dispatch: any,
        getState: any
    ) => {

        let beginFunction = beginGrouped;
        let successFunction = successGrouped;
        let failureFunction = failureGrouped;
        if (mode === "init") {
            beginFunction = beginInit;
            successFunction = successInit;
            failureFunction = failureInit;
        }

        dispatch(beginFunction());
        
        setTimeout(
            () => {
                dispatch(successFunction({data: T_DATA_GROUPED, errorMessage: null, status: 200}))
            }, 2500
        );
    }
    
};

// filters data list based on given search term
export const filterBySearch = (searchTerm: string = "") => {

    return commonFilterBySearch(cameraSlice, searchTerm, searchMatch);
    
};

// filters data list based on given search term
export const filterGroupedBySearch = (searchTerm: string = "") => {

    return commonFilterGroupedBySearch(cameraSlice, searchTerm, searchMatch);
    
};

// used to compare given city name with city values in origin and destination of given trafficEvent
export const cityMatch = (camera: IATCamera, cityName: string) => {

    // short-circuit
    if (!cityName) return true;

    let location: IATLocationDto = camera.location || new ATLocation();

    if (cityName === location.city) return true;

    return false;
};

// used to compare given county name with county values in origin and destination of given trafficEvent
export const countyMatch = (camera: IATCamera, countyName: string) => {

    // short-circuit
    if (!countyName) return true;

    let location: IATLocationDto = camera.location || new ATLocation();

    if (countyName === location.county) return true;

    return false;
};

// used to compare given region with the region of given trafficEvent
export const roadwayMatch = (camera: IATCamera, roadwayName: string): boolean => {

    // short-circuit
    if (!roadwayName) return true;

    let location: IATLocationDto = camera.location || new ATLocation();

    let sanitizedRoadwayName: string = roadwayName.replaceAll("-", "").replaceAll(" ", "");
    let sanitizedRouteDesignator: string = location.displayRouteDesignator?.replaceAll("-", "").replaceAll(" ", "") || "";
    let sanitizedCrossStreet: string = location.displayCrossStreet?.replaceAll("-", "").replaceAll(" ", "") || "";

    if (
        sanitizedRouteDesignator === sanitizedRoadwayName ||
        sanitizedCrossStreet === sanitizedRoadwayName
    ) return true;

    return false;
};

// used to compare given region with the region of given trafficEvent
export const regionMatch = (camera: IATCamera, region: string): boolean => {

    // short-circuit
    if (!region) return true;

    if (camera.responsibleRegion === region.replace(/\s/g, ""))   return true;

    return false;
};

const searchMatch = (camera: IATCamera, searchTerm: string = ""): boolean => {

    if (!searchTerm) return true;

    if (
        (camera.location) && 
            ( 
                camera.location.displayRouteDesignator?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                camera.location.displayCrossStreet?.toLowerCase().includes(searchTerm.toLowerCase())
            )
    ) return true;

    return false;
}

// this function is utilized by slices' 'filterData' function
// to determine the coordinate location of camera object for geo-filtering
export const getCoords = (
    item: IATCamera
): ICoord | null => {

    let location: IATLocationDto = item.location;
    let lat: number = location?.latitude;
    let lng: number = location?.longitude;

    if (nuon(lat) && nuon(lng))
        return {lat, lng}

    else 
        return null;

};

export const checkForStringMatch = (
    camera: IATCamera, 
    stringList: string[], 
    matchFunction: (object: any, str: string
) => boolean) => {

    // first, we check the strings for a match
    // first, assume the strings do not match
    let stringsMatch: boolean = false;
    // if there are no cities selected, then consider it a match
    if (stringList.length === 0) stringsMatch = true;
    else {  // if you have cities selected
        // then check if ANY of those selected cities are a match
        for (let i = 0; i < stringList.length; i++){
            if (matchFunction(camera, stringList[i])) {
                // if the city was a match, set the boolean and break the loop
                stringsMatch = true;
                break;
            }
        }
    }

    return stringsMatch;
};

export const filterBySelections = (
    cityNames: string[],
    countyNames: string[],
    roadwayNames: string[],
    regionNames: string[],
    searchText: string
) => {

    return (
        dispatch: any,
        getState: any
    ) => {

        let data: any[] = getState()[EAlgoApiObjectType["camera"]].data;

        if (!data) return;

        if (data.length === 0) {
            dispatch(filterDataBySelections({dataFilteredBySelection: data}));
            return;
        }

        let filteredData: any[] = [];

        // if there is data...
        if (data.length > 0){
            // iterate over each travel time...
            for (let i = 0; i < data.length; i++){
                
                let camera = data[i];

                let citiesMatch = checkForStringMatch(camera, cityNames, cityMatch);
                if (!citiesMatch) continue;

                let countiesMatch = checkForStringMatch(camera, countyNames, countyMatch);
                if (!countiesMatch) continue;

                let roadwaysMatch = checkForStringMatch(camera, roadwayNames, roadwayMatch);
                if (!roadwaysMatch) continue;

                let regionsMatch = checkForStringMatch(camera, regionNames, regionMatch);
                if (!regionsMatch) continue;

                // last, we check that search also matches
                // if all matches are true, add this travelTime to the filteredList
                if (
                    citiesMatch &&
                    countiesMatch &&
                    roadwaysMatch &&
                    regionsMatch &&
                    searchMatch(camera, searchText)
                ){
                    filteredData.push(camera);
                }
            }
        }

        dispatch(filterDataBySelections({dataFilteredBySelections: filteredData}));
    }
}

// exports the slice's reducer ( used in store file to build up master reducer )
export const cameraReducer = cameraSlice.reducer;