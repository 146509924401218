// libraries
import * as React from "react";
// styles
import * as SC from "./Styled";
// component
import { faExternalLink } from "@fortawesome/pro-regular-svg-icons";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { nuon } from "@caps-mobile/common-lib";

export enum EDefaultValue {
    "external-link",
    "chevron-right"
}

const mapDefaultValue = (defaultValue: EDefaultValue) => {

    switch(defaultValue){
        case EDefaultValue["external-link"]:
            return faExternalLink;
        default:
            return faChevronRight;
    }
}

export type IProps = {
    children?: React.ReactNode;
    icon?: React.ReactNode;
    label?: React.ReactNode;
    labelStyleOverwrite?: string;
    value?: React.ReactNode;
    valueStyleOverwrite?: string;
    defaultValue?: EDefaultValue;
    clickCallback?: () => any;
    styleOverwrite?: string;
};

export const BannerOption: React.FC<IProps> = (props) => {

    const { 
        icon, label, value, defaultValue, clickCallback,
        labelStyleOverwrite, valueStyleOverwrite, styleOverwrite
    } = props;

    return (
        <SC.StyledBannerOption onClick={clickCallback} styleOverwrite={styleOverwrite}>

            {   (icon || label) &&
                <SC.IconAndLabelWrap>

                    {   icon && 
                        <SC.OptionIcon>{icon}</SC.OptionIcon> }

                    {   label && 
                        <SC.OptionLabel styleOverwrite={labelStyleOverwrite}>{label}</SC.OptionLabel> }

                </SC.IconAndLabelWrap> }
            

            {   value && 
                <SC.OptionValue styleOverwrite={valueStyleOverwrite}>{value}</SC.OptionValue> }

            {   nuon(defaultValue) &&
                <SC.OptionValue>
                    <FontAwesomeIcon icon={mapDefaultValue(defaultValue!)} fontSize={14} />
                </SC.OptionValue>
            }

        </SC.StyledBannerOption>
    )
};

export default BannerOption;