// libraries
import * as React from "react";
import { useBreakpoint } from "~/library/useBreakpoint";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
// styles
import * as SC from "./Styled";
// components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RouteCamerasOverlayContext from "~/contexts/RouteCamerasOverlayContext";
import { useDispatch } from "react-redux";
// hooks & contexts
import { getCameras } from "~/store/algo-api/slices/routes";

export type IProps = {
    route: any;
    index: number;
    isSelected: boolean;
    setSelected: (index: number) => void
};

export const Route: React.FC<IProps> = (props) => {

    const { lg: isLargeMax } = useBreakpoint();
    const sections = props.route.properties?.data?.sections;
    const overlayContext: any = React.useContext(RouteCamerasOverlayContext);

    const firstDepartureTime = new Date(sections[0]?.departureTime).getTime();
    const lastArrivalTime = new Date(sections[sections.length -1]?.arrivalTime).getTime();
    const diffMs = (lastArrivalTime - firstDepartureTime); // milliseconds
    const diffDays = Math.floor(diffMs / 86400000); // days
    const diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    const lessThanMinute = diffDays <= 0 && diffHrs <= 0 && diffMins <= 0 && diffMs > 0;

    const selectRoute = () => {
        props.setSelected(props.index);
    };

    const dispatch = useDispatch();

    const viewCameras = () => {
        const routeId = props.route?.properties?.data?.id;
        dispatch(getCameras(routeId) as any);
        overlayContext.setShowOverlay(true);
    };

    return (
        <SC.StyledRoute isSelected={props.isSelected} isMediumMax={isLargeMax} onClick={() => {selectRoute()}}>

            <SC.Title isMediumMax={isLargeMax}>
                <SC.TitleText>Route {props.index}</SC.TitleText>
                <SC.CameraLink onClick={() => viewCameras()}>View Cameras</SC.CameraLink>
            </SC.Title>


            <SC.RouteItems isMediumMax={isLargeMax}>

                {props.route &&
                    <SC.StyledRouteItem>

                        <SC.Name>{props.route.properties?.data?.events?.length} Traffic events</SC.Name>

                        <SC.Line />

                        <SC.TravelTimeSection>
                            <SC.TravelIcon>
                                <FontAwesomeIcon 
                                    icon={solid("car-rear")} tabIndex={0} 
                                    color={ '#636366' }  />
                            </SC.TravelIcon>
                            <SC.Time> 
                                {diffDays > 0 && <span>{diffDays + "d"} &nbsp;</span>}
                                {diffHrs > 0 && <span>{diffHrs + "h"} &nbsp;</span>}
                                {diffMins > 0 && <span>{diffMins + "m"}</span>}
                                {diffMs <= 0 && <span>{"Not Available"}</span>}
                                {lessThanMinute && <span>{"< 1m"}</span>}
                            </SC.Time>
                        </SC.TravelTimeSection>

                    </SC.StyledRouteItem>
                }
                
               
            </SC.RouteItems>

        </SC.StyledRoute>
    );
};

export default Route;