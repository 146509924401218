// libraries
import * as React from "react";
// styles
import * as SC from "./Styled";
// resources
import {
    ReactComponent as LogoCircleSvg
} from "~/resources/icons/algo-traffic-icons/logo/algo-no-text.svg";
import {
    ReactComponent as LogoTextSvg
} from "~/resources/icons/algo-traffic-icons/logo/algo-traffic-logo-text.svg";

export type IProps = {
    logoWidth?: number;
    logoHeight?: number;
    textWidth?: number;
    textHeight?: number;
};

export const Logo: React.FC<IProps> = (props) => {

    const { 
        logoWidth = 36, logoHeight = 36, 
        textWidth = 64, textHeight = 64 
    } = props;

    return(
        <SC.StyledLogo>
            <LogoCircleSvg height={logoHeight} width={logoWidth} />
            <LogoTextSvg height={textHeight} width={textWidth} />
        </SC.StyledLogo>
    );
};

export default Logo;