// libraries
import styled from "styled-components";
import { flexSmart } from "@caps-mobile/styled-lib";
import { whenLargeMin, whenMediumMax, whenMediumMin, whenSmallMax, whenSmallMin } from "~/theme";

export const StyledStateFacility = styled.div`
    width: calc(100% - 20px);

    margin: 0 0 0 20px;

    ${flexSmart("space-around flex-start")}
    flex-direction: column;

    ${whenSmallMax(`
        width: 100%;
        margin: 0 0 0 0;
    `)}
`;

export const StyledStateFacilityClosed = styled.div`
    width: calc(100% - 20px);

    margin: 0 0 0 20px;

    ${flexSmart("flex-start flex-start")}
    flex-direction: column;

    ${whenSmallMax(`
        width: 100%;
        margin: 0 0 0 0;
    `)}
`;

export const StyledStateFacilityDetails = styled.div`
    width: calc(100% - 20px);

    margin: 0 0 0 20px;

    ${flexSmart("space-around flex-start")}
    flex-direction: column;

    ${whenSmallMax(`
        width: 100%;
        margin: 0 0 0 0;
    `)}
`;

export const ClosedPropertyRow = styled.div`
    
    ${flexSmart("flex-start start")}
    flex-direction: row;

    ${whenMediumMax(`
        flex-direction: column;
    `)}
    ${whenSmallMax(`
        flex-direction: row;
    `)}
`;

export const PropertyRow = styled.div`
    ${flexSmart("flex-start start")}
    flex-directtion: row;
`;

export const PropertyName = styled.span`
    font-size: 22px;
    font-weight: 600;

    color: rgba(0,0,0,0.75);

    ${whenSmallMax(`
        font-size: 17px;
    `)}
`;

export const PropertyValue = styled.span`
    font-size: 22px;
    font-weight: 400;

    color: rgba(0,0,0,0.755);

    ${whenSmallMax(`
        font-size: 17px;
    `)}
`;