// libraries
import styled from "styled-components";
import { flexSmart } from "@caps-mobile/styled-lib";
// constants
import { BORDER_RADIUS } from "~/constants";

export const StyledDropdown = styled.div<{ menuOpened?: boolean, items?: boolean }>`
${props => `
        ${flexSmart("space-between center")}

        position: relative;
        background-color: ${props.theme.colors["bg-primary"]["rgba"]};
        border: 1px solid ${props.theme.colors["border-primary"]["rgba"]};
        border-top-left-radius: ${BORDER_RADIUS}px;
        border-top-right-radius: ${BORDER_RADIUS}px;
        padding: 8px 4px 8px 4px;
        width: 100%;

        ${(props.menuOpened && props.items)
            ? `
                box-shadow: 0 0 0 1px ${props.theme.colors["border-primary"]["rgba"]};`
            : `
                border-bottom-left-radius: ${BORDER_RADIUS}px;
                border-bottom-right-radius: ${BORDER_RADIUS}px;
            `
        }

        :focus{
            box-shadow: 0 0 0 1px ${props.theme.colors["border-primary"]["rgba"]};
        }

        :focus-within{
            box-shadow: 0 0 0 1px ${props.theme.colors["border-primary"]["rgba"]};
        }
    `}
`;

export const StyledSelector = styled.div`
    ${flexSmart("space-between center")}
    width: 100%;
`;

export const StyledSelected = styled.div<{ isHidden?: boolean }>`
${props => `
        ${(props.isHidden)
                ? ` 
                    display:none;`
                : `
                    width: 75%;
                    color: #48484A;
                    flex-wrap: wrap;
                    display: inline-flex;
                    gap: 10px;
                    margin-left: 10px;`
        } 
    `}
`;

export const StyledSelectedValue = styled.div`
    background: #C7C7CC;
    border-radius: 6px;
    padding: 4px 4px 4px 4px;
    cursor: pointer;
    display: inline-flex;
    max-width: 75%;
    overflow: hidden;
`;
export const StyledSelectedValueText = styled.span`
    font-weight: 500;
    font-size: 12px;
    color: #48484A;
    margin-right: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const StyledSelectedXIcon = styled.span`
    font-weight: 900;
    font-size: 12px;
    color: #48484A;
    text-align: right;
    align-self: center;
`;

export const StyledSelectionInput = styled.input<{ isHidden?: boolean }>`
${props => `
    ${(props.isHidden)
        ? ` 
            display: none;`
        : `
            width: 75%;
            margin-left: 10px;
            border: none;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 3px 0px 3px 0px;
            font-size: 16px;
            outline: none;`
        } 
    `}
`;

export const StyledList = styled.div<{ menuOpened?: boolean, items?: boolean, scrollThreshold: number; }>`
    ${props => `
        box-sizing: border-box;
        z-index: 999;
        width: calc(100% + 2px);
        border: 1px solid ${props.theme.colors["border-primary"]["rgba"]};
        box-shadow: 0 0 0 1px ${props.theme.colors["border-primary"]["rgba"]};
        background-color: ${props.theme.colors["bg-primary"]["hex-string"]};
        border-bottom-left-radius: ${BORDER_RADIUS}px;
        border-bottom-right-radius: ${BORDER_RADIUS}px;
        position: absolute;
        box-sizing: border-box;
        overflow: auto;
        top: 100%;
        left: -1px;
        max-height: ${41 * props.scrollThreshold}px;

        ${(props.menuOpened && props.items)
            ? `${themedScrollbar(props.theme)}`
            : `visibility: hidden;`
        }
    `}
`;

export const StyledListOption = styled.div<{ isSelected?: boolean, isHighlighted?: boolean }>`
${props => `
        padding: 10px;
        font-size: 16px;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        color: ${ props.isSelected 
            ? props.theme.colors["text-secondary"]["rgba"]
            : props.theme.colors["text-primary"]["rgba"]
        };
        background-color: ${ props.isSelected 
            ? props.theme.getColorAtAlpha(props.theme.colors["border-primary-old"], 1)
            : props.theme.colors["bg-primary"]["rgba"]
        };
        ${props.isHighlighted 
            ? `
                color: ${props.theme.colors["text-primary"]["rgba"]};
                background-color: ${props.theme.getColorAtAlpha(props.theme.colors["border-primary-old"], .20)};
            ` 
            : ``
        }
    `}
`;

export const StyledDiv = styled.div`
    ${flexSmart("space-between center")}
`;

export const StyledXIcon = styled.span<{ isHidden?: boolean }>`
${props => `
    ${(props.isHidden)
        ? ` 
            display: none;`
        : `
            color: #C7C7CC;
            cursor: pointer;
            display:inline;
            margin: 0px;
            padding-right: 20px;
            padding-left: 5px;
            font-size: 14px;`
        } 
    `}
`;

export const StyledDropIcon = styled.span`
    color: #007556;
    cursor: pointer;
    display:inline;
    margin: 0px;
    padding-right: 10px;
    font-size: 16px;
`;

export const themedScrollbar = (theme: any) => {
    return `
        ::-webkit-scrollbar {
            width: 0px;
        }

        ::-webkit-scrollbar-track {
            width: 0px;
        }

        ::-webkit-scrollbar-thumb {
            width: 0px;
        }

        ::-webkit-scrollbar-thumb:hover {
            width: 0px;
        }

        ::-webkit-scrollbar-button{
            height: 0px;
            width: 0px;
        }
    `
};