// libraries
import * as React from "react";
// hooks & context
import { useBreakpoint } from "~/library/useBreakpoint";
// components
import OverlayButton from "../overlay-button/OverlayButton";
import OverlaySection from "../../../../views/map-overlay-views/OverlaySection";
import { faArrowRotateRight, faCrosshairs, faLayerGroup
} from "@fortawesome/pro-regular-svg-icons";

export type IProps = {
    refreshLayersCallback: () => any,
    selectLayersCallback: () => any,
    zoomToCallback: () => any,
};

export const ButtonsSection: React.FC<IProps> = (props) => {

    const { refreshLayersCallback, selectLayersCallback, zoomToCallback } = props;
    const { sm: isSmallMax } = useBreakpoint();

    return (
        /* Overlay Views */
        <OverlaySection 
            bottom={isSmallMax ? 20 : 42} right={isSmallMax ? 20 : 42} 
            styleOverwrite={`height: 190px; width: auto;`}
            childWrapperStyleOverwrite={`align-items: flex-end;`}
        >
            <OverlayButton callback={refreshLayersCallback}
                label={"Refresh Layers"} iconProp={faArrowRotateRight} />

            <OverlayButton callback={selectLayersCallback}
                label={"Select Layers"} iconProp={faLayerGroup} />

            <OverlayButton callback={zoomToCallback}
                label={"Zoom To"} iconProp={faCrosshairs} />

        </OverlaySection>
    );
};

export default ButtonsSection;