// libraries
import { useEffect } from "react";
import { IZoomToValue } from "~/interfaces";

// causes the map view to resize after the nav panel animation
export const useResizeMapOnNavToggle = (map: H.Map | undefined, panelOpen: boolean) => {

    useEffect( 
        () => { 
            if (map)
                setTimeout( () => map.getViewPort().resize(), 500)
        }, [panelOpen]
    );

};

// causes the map view to resize and sets zoom after window resize
export const useWindowResize = (map: H.Map | undefined, width: number, height: number, zoomTarget: IZoomToValue, isMediumMax?: boolean) => {
    useEffect( 
        () => { 
            if (map){
                setTimeout( () => {map.getViewPort().resize()
                if(zoomTarget.bounds){
                    var bbox = new H.geo.Rect(
                        isMediumMax ? zoomTarget.bounds?.top + 1 : zoomTarget.bounds?.top, 
                        zoomTarget.bounds?.left, 
                        zoomTarget.bounds?.bottom, 
                        zoomTarget.bounds?.right);
                    map.getViewModel().setLookAtData({
                        bounds: bbox
                    });
                }
                else{
                    map.setZoom(zoomTarget.zoom);
                    map.setCenter(zoomTarget.center);
                }}, 0)
            }
        }, [width, height]
    );

}

// adds listener to the map that changes cursor style to pointer when
// hovering over a marker

function mapCursorListener(event: any, map: H.Map) {
    if (event.target instanceof H.map.Marker || event.target instanceof H.map.Polygon) {
        (map.getViewPort().element as any).style.cursor = 'pointer';
    } else {
        (map.getViewPort().element as any).style.cursor = 'auto';
    }
}

export const useCursorOnFeature = (map: H.Map | undefined) => {

    useEffect(
        () => {

            if (map){

                map.addEventListener('pointermove', (evt) => mapCursorListener(evt, map), false);
    
                return () => {
                    map.removeEventListener('pointermove', (evt) => mapCursorListener(evt, map), false);
                }
            }

        }, [map]
    );
};