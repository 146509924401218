// libraries
import * as React from "react";
// styles
import * as BannerStyles from "~/components/views/banner-option/Styled";
import { Header } from "./Styled";
// types & models
import { IDocObject } from "~/interfaces";
// components
import PageTemplate from "../../views/page-template/PageTemplate";
import { BannerOptionSection } from "~/components/views/banner-option/Styled";
import BannerOption, { EDefaultValue } from "~/components/views/banner-option/BannerOption";
// constants
import { algo_green_text } from "~/constants";

const subtitleStyle: string = "font-size: 14px; line-height: 140%; font-weight: 400;";

const DOC_STRINGS: IDocObject[] = [
    {
        title: "Statewide",
        subtitle: "@AlgoTraffic",
        url: `https://twitter.com/AlgoTraffic`
    },
    {
        title: "North Region",
        subtitle: "@algo_hvl",
        url: `https://twitter.com/algo_hvl`
    },
    {
        title: "West Central Region",
        subtitle: "@algo_tus",
        url: `https://twitter.com/algo_tus`
    },
    {
        title: "East Central Region",
        subtitle: "@algo_bhm",
        url: `https://twitter.com/algo_bhm`
    },
    {
        title: "Southwest Region",
        subtitle: "@algo_mob",
        url: `https://twitter.com/algo_mob`
    },
    {
        title: "Southeast Region",
        subtitle: "@algo_mgm",
        url: `https://twitter.com/algo_mgm`
    }
];

export type IProps = {
    //
};

export const SocialMediaPage: React.FC<IProps> = (props) => {

    const {
        //
    } = props;

    return (
        <PageTemplate title={"Social Media"}>
            <Header> { `X formerly Twitter` } </Header>
            <BannerOptionSection>

                {DOC_STRINGS.map(
                    (docObject: IDocObject) => {
                        return (
                            <BannerOption key={docObject.title}
                                clickCallback={() => { window.open(docObject.url)}}
                                label={
                                    <React.Fragment>
                                        <BannerStyles.OptionLabel color={algo_green_text}>{docObject.title}</BannerStyles.OptionLabel>
                                        <BannerStyles.OptionLabel styleOverwrite={subtitleStyle}>{docObject.subtitle}</BannerStyles.OptionLabel>
                                    </React.Fragment>
                                } 
                                defaultValue={EDefaultValue["external-link"]}
                            />
                        );
                    }
                )}
                
            </BannerOptionSection>

        </PageTemplate>
    );
};

export default SocialMediaPage;