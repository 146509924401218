// libraries
import { useEffect } from "react";
// hooks & context
import { useFeatureToggle } from "./useFeatureToggle";
// types & models
import { EAlgoLayerType, IMarkerTapObject } from "~/interfaces";
import { useMarkerGroup } from "./useMarkerGroup";

export const useAllMarkerGroups = (
    map: H.Map | undefined, 
    markerTapCallback?: (markerData: IMarkerTapObject) => void
) => {

    // event-type markers
    const crashGroup = useMarkerGroup(map, EAlgoLayerType.crash, markerTapCallback);
    const incidentGroup = useMarkerGroup(map, EAlgoLayerType.incident, markerTapCallback);
    const regionalEventGroup = useMarkerGroup(map, EAlgoLayerType["regional-event"], markerTapCallback);
    const roadConditionGroup = useMarkerGroup(map, EAlgoLayerType["road-condition"], markerTapCallback);
    const roadworkGroup = useMarkerGroup(map, EAlgoLayerType.roadwork, markerTapCallback);

    useFeatureToggle(EAlgoLayerType.crash, crashGroup);
    useFeatureToggle(EAlgoLayerType.incident, incidentGroup);
    useFeatureToggle(EAlgoLayerType["regional-event"], regionalEventGroup);
    useFeatureToggle(EAlgoLayerType["road-condition"], roadConditionGroup);
    useFeatureToggle(EAlgoLayerType.roadwork, roadworkGroup);

    // non-event-type markers
    const cameraGroupMarkerGroup = useMarkerGroup(map, EAlgoLayerType["camera-group"], markerTapCallback);
    const ferryMarkerGroup = useMarkerGroup(map, EAlgoLayerType.ferry, markerTapCallback);
    const messageSignGroup = useMarkerGroup(map, EAlgoLayerType["message-sign"], markerTapCallback);
    const other511Group = useMarkerGroup(map, EAlgoLayerType.other511, markerTapCallback);
    const stateFacilityGroup = useMarkerGroup(map, EAlgoLayerType["state-facility"], markerTapCallback);

    useFeatureToggle(EAlgoLayerType["camera-group"], cameraGroupMarkerGroup);
    useFeatureToggle(EAlgoLayerType.ferry, ferryMarkerGroup);
    useFeatureToggle(EAlgoLayerType["message-sign"], messageSignGroup);
    useFeatureToggle(EAlgoLayerType.other511, other511Group);
    useFeatureToggle(EAlgoLayerType["state-facility"], stateFacilityGroup);

    useEffect(
        () => {

            if (map){
                // event-type markers
                incidentGroup && map.addObject(incidentGroup);
                crashGroup && map.addObject(crashGroup);
                roadConditionGroup && map.addObject(roadConditionGroup);
                regionalEventGroup && map.addObject(regionalEventGroup);
                roadworkGroup && map.addObject(roadworkGroup);

                // non-event-type markers
                cameraGroupMarkerGroup && map.addObject(cameraGroupMarkerGroup);
                ferryMarkerGroup && map.addObject(ferryMarkerGroup);
                messageSignGroup && map.addObject(messageSignGroup);
                other511Group && map.addObject(other511Group);
                stateFacilityGroup && map.addObject(stateFacilityGroup);
            }

            return () => {
                //
            }

        }, [
            map, crashGroup, incidentGroup, roadConditionGroup, regionalEventGroup,
            roadworkGroup, cameraGroupMarkerGroup, ferryMarkerGroup, messageSignGroup,
            other511Group, stateFacilityGroup
        ]
    );
};