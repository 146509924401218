// libraries
import { IATCameraGroupDto, IATVideoBoardDto } from "@algo/network-manager/models/v3";
import * as React from "react";
// styles
import * as SC from "./Styled";
// components
import { Videoboard } from "~/components/views/videoboard/Videoboard";

export type IProps = {
    videoboard: IATVideoBoardDto;
    doneCallback: () => void;
};

export const VideoboardModal: React.FC<IProps> = (props) => {

    const {
        videoboard, doneCallback
    } = props;

    return (
        <SC.StyledVideoboardModal>

            <Videoboard videoboard={videoboard} closeCallback={doneCallback}/>

        </SC.StyledVideoboardModal>
    )
}

export default VideoboardModal;