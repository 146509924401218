// libraries
import * as React from "react";
import { useBreakpoint } from "~/library/useBreakpoint";
// styles
import OverlaySection from "../../../../views/map-overlay-views/OverlaySection";
// components
import WeatherLegend from "./legends/WeatherLegend";

export type IProps = {
    //
};

export const LegendSection: React.FC<IProps> = (props) => {

    const { md: isMediumMax } = useBreakpoint();

    return (
        /* Overlay Views */
        <OverlaySection 
            bottom={isMediumMax ? 20 : 42} left={isMediumMax ? 20 : 42} 
            styleOverwrite={`width: auto;`}
            childWrapperStyleOverwrite={`align-items: flex-start;`}
        >
            <WeatherLegend />

        </OverlaySection>
    );

};

export default LegendSection;