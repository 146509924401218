// libraries
import * as React from "react";
// types & models
import { ISVGProps } from "~/resources/interfaces";

export const CrashGlyph: React.FC<ISVGProps> = (props) => {

    const {
        width, height, viewBox, color
    } = props;

    return (
        <svg 
            width={width || "151"} height={height || "125"} 
            viewBox={ viewBox || "0 0 151 125" } fill="none" 
        >
            <path fill={color || "black"}
                d="M43.1566 0C44.6566 0 46.1566 1 46.6566 2.75L54.1566 21.25L73.1566 15.25C74.6566 15 76.4066 15.5 77.4066 16.75C77.6566 16.75 77.6566 17 77.6566 17.25C73.4066 19.25 69.6566 22.25 66.9066 26.5L56.6566 41.25C49.9066 43.5 44.1566 49 42.1566 56.5L37.1566 75L28.4066 83C27.1566 84.25 25.4066 84.5 23.9066 83.75C22.4066 83 21.6566 81.5 21.6566 79.75L23.1566 60L3.40662 57C1.90662 56.75 0.406616 55.5 0.156616 54C-0.343384 52.25 0.406615 50.75 1.65662 49.75L18.1566 38.5L8.40662 21.25C7.40662 19.75 7.65662 18 8.65662 16.75C9.65662 15.5 11.4066 15 12.9066 15.25L31.9066 21.25L39.4066 2.75C39.9066 1 41.4066 0 43.1566 0ZM95.1566 23L128.907 32C137.157 34.25 143.157 41.5 143.657 49.75L145.407 70.75C149.657 74.25 151.907 79.75 150.407 85.5L141.657 118.25C140.407 122.5 135.907 125 131.657 124L127.907 123C123.657 121.75 121.157 117.5 122.157 113.25L124.407 105.5L62.4066 88.75L60.4066 96.5C59.1566 100.75 54.9066 103.25 50.6566 102.25L46.6566 101.25C42.4066 100 39.9066 95.75 41.1566 91.5L49.9066 58.5C51.4066 53 56.1566 49 61.4066 48.25L73.4066 31C78.1566 24 86.9066 20.75 95.1566 23ZM90.9066 38.5C89.4066 38 87.6566 38.75 86.6566 40L78.6566 51.75L128.907 65.25L127.907 51C127.657 49.5 126.407 48 124.907 47.5L90.9066 38.5ZM66.1566 75.25C69.4066 76.25 72.6566 74.25 73.4066 71C74.4066 67.75 72.4066 64.5 69.1566 63.75C65.9066 62.75 62.6566 64.75 61.9066 68C60.9066 71.25 62.9066 74.5 66.1566 75.25ZM131.157 80.25C127.907 79.5 124.657 81.25 123.657 84.5C122.907 87.75 124.657 91 127.907 92C131.157 92.75 134.407 90.75 135.407 87.75C136.157 84.5 134.157 81.25 131.157 80.25Z" />
        </svg>
    );
};

export default CrashGlyph;