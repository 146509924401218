// libraries
import { createSlice } from "@reduxjs/toolkit";
// types & models
import { EAlgoLayerType, EHereMapScheme, EHereMapType } from "~/interfaces";
// constants
import { DEFAULT_MAP_LAYERS, DEFAULT_MAP_SCHEME, DEFAULT_MAP_TYPE } from "~/constants";

export type IMapLayers = {
    [key in EAlgoLayerType]: boolean;
}

export type IMapLayersStore = {
    mapLayers: IMapLayers,
    mapScheme: EHereMapScheme;
};

export type IUpdateMapLayersPayload = {
    mapLayers?: IMapLayers,
    mapScheme?: EHereMapScheme,
}

export const SETTINGS_INIT: IMapLayersStore = {
    mapLayers: DEFAULT_MAP_LAYERS,
    mapScheme: DEFAULT_MAP_SCHEME,
};

export const mapLayersSlice = createSlice(
    {
        name: "map-layers",
        initialState: SETTINGS_INIT,
        reducers: {
            updateMapLayers: (state: any, action: any) => {
                state.mapLayers = action.payload.mapLayers;
            },
            updateMapScheme: (state: any, action: any) => {
                state.mapScheme = action.payload.mapScheme;
            }
        }
    }
);

const { updateMapLayers, updateMapScheme } = mapLayersSlice.actions;

export const initializeMapLayers = () => {

    return (
        dispatch: any,
        getState: any
    ) => {

        // get the settings values from the local storage if they exist
        let mapScheme: string | null = localStorage.getItem("mapScheme");
        if (!mapScheme) mapScheme = DEFAULT_MAP_SCHEME;

        let mapLayersString: string | null = localStorage.getItem("mapLayers");
        let mapLayers: IMapLayers | undefined = undefined;

        try {
            mapLayers = mapLayersString ? JSON.parse(mapLayersString) : DEFAULT_MAP_LAYERS;
        } catch(err: any){
            console.error("Error parsing mapLayers selections from local storage.", err);
        } finally { if(!mapLayers) mapLayers = DEFAULT_MAP_LAYERS;}

        // update store
        if (mapLayers) dispatch(updateMapLayers({mapLayers}));
        if (mapScheme) dispatch(updateMapScheme({mapScheme}));
    }
    
};

export const updateMapLayersObject = (mapLayers: IMapLayers) => {
    
    return (
        dispatch: any,
        getState: any
    ) => {
        dispatch(updateMapLayers({mapLayers}));
        localStorage.setItem("mapLayers", JSON.stringify(mapLayers));
    }
};

export const selectAllMapLayers = () => {
    return (
        dispatch: any,
        getState: any
    ) => {

        let curLayers: IMapLayers = getState()["map-layers"].mapLayers;
        
        if ( curLayers["all"] === true ) {
            dispatch(deselectAllMapLayers())
        }
        else {
            let newLayers: IMapLayers =  { ...curLayers };
            Object.keys(curLayers).forEach(
                (layerKey: string) => { newLayers[layerKey as keyof typeof EAlgoLayerType] = true; }
            )
            dispatch(updateMapLayersObject(newLayers));
        }
    }
};

export const deselectAllMapLayers = () => {
    return (
        dispatch: any,
        getState: any
    ) => {

        let curLayers: IMapLayers = getState()["map-layers"].mapLayers;
        let newLayers: IMapLayers =  { ...curLayers };

        Object.keys(curLayers).forEach(
            (layerKey: string) => { newLayers[layerKey as keyof typeof EAlgoLayerType] = false; }
        )

        dispatch(updateMapLayersObject(newLayers));
    }
};

export const updateMapSchemeString = (newScheme: string) => {

    return (
        dispatch: any,
        getState: any
    ) => {
        dispatch(updateMapScheme({ mapScheme: newScheme }));
        localStorage.setItem("mapScheme", newScheme);
    }
}

export const mapLayersReducer = mapLayersSlice.reducer;