// libraries
import * as React from "react";
// styles
import * as SC from "./Styled";
import { BannerOptionSection } from "~/components/views/banner-option/Styled";
// components
import PageTemplate from "~/components/views/page-template/PageTemplate";
// constants
import BannerOption from "~/components/views/banner-option/BannerOption";
import { INavSection, moreNavBarMap } from "~/navigation/navigation-map";
// context
import SiteNavContext from "~/navigation/SiteNavContext";

export type IProps = {
    //
};

export const MorePage: React.FC<IProps> = (props) => {

    // CONTEXT 
    const navContext: any = React.useContext(SiteNavContext);

    return (
        <PageTemplate title={"More"}>

            {/* Render each of the nav sections, if any exist */}
            {moreNavBarMap && moreNavBarMap.navSections && moreNavBarMap.navSections.map(
                (navSection: INavSection, mapIndex: number) => {
                    return (
                        <React.Fragment key={`${navSection.title || "anonymous-section"}${mapIndex}`}>
                            <SC.Header> {navSection.title} </SC.Header>
                            <BannerOptionSection>
                                {navSection.links && navSection.links.filter(x => x.name !== "Legend").map(
                                    (link: any) => {
                                        return (
                                            <BannerOption 
                                                key={link.name + link.id} 
                                                label={link.name}
                                                labelStyleOverwrite={`color: green;`}
                                                clickCallback={() => {
                                                    if(link.route){
                                                        navContext.setSelectedSiteLink([navSection.id, link.id]);
                                                        navContext.setSelectedRoute(link.route);
                                                    }
                                                    else if(link.link){
                                                        window.open(link.link);
                                                    }
                                                }}
                                                value={
                                                    link.iconComp
                                                }
                                            />)
                                    })}
                            </BannerOptionSection>
                        </React.Fragment>
                    );
                }
            )}

        </PageTemplate>
    );
};

export default MorePage;